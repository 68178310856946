import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import axios from "axios";
import gsap from "gsap/gsap-core";

import { Container } from "@material-ui/core";
import { StylesProvider } from "@material-ui/styles";
import styled, { ThemeProvider } from "styled-components";
import GlobalStyle from "./styles/GlobalStyle";
import { theme } from "./styles/theme";

import { API, redirect } from "./API";
import ProtectedRoute from "./components/auth/ProtectedRoute";

import Navigation from "./components/Navigation";
import AdvertisementPage from "./pages/AdvertisementPage";
import Header from "./components/Header";
import AuctionsPage from "./pages/AuctionsPage";
import BailiffPage from "./pages/BailiffPage";
import Cooperatives from "./pages/CooperativesPage";
import TenderPage from "./pages/TenderPage";
import Footer from "./components/Footer";
import AddPagesForm from "./components/adminForm/AddPagesForm";
import Indicator from "./components/atom/Indicator";
import LogOutPage from "./pages/LogOutPage";
import AnalysisPage from "./pages/AnalysisPage";
import RentPage from "./pages/RentPage";

const Wrapper = styled.div`
  margin-top: 25px;

  .sidebar-closed > .sidebar-wrapper {
    left: -1000px !important;
  }
`;

const App = () => {
  axios.defaults.withCredentials = true;

  const [activePackages, setActivePackages] = useState([]);

  gsap.config({
    nullTargetWarn: false,
  });

  useEffect(() => {
    axios
      .get(`${API}api/roles`)
      .then((resp) => {
        setActivePackages(resp.data.map((el) => el.authority));
      })
      .catch((err) => {
        if (
          window.location.pathname !== "/log-out" &&
          err?.response?.status === 401
        ) {
          window.location = redirect;
        }
        console.log(err);
      });
  }, []);

  return (
    <StylesProvider injectFirst>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <div
          className="main-container"
          style={{ minHeight: "auto" }}
          id="container"
        >
          <div className="overlay"></div>
          <div className="search-overlay"></div>
          <ReactNotification />
          <BrowserRouter>
            {window.location.pathname !== "/log-out" && (
              <>
                <Navigation activePackages={activePackages} />
                <Header />
              </>
            )}
            <div
              id="content"
              className="main-content"
              style={{ minHeight: "auto" }}
            >
              <Wrapper className="layout-px-spacing">
                <Container
                  maxWidth={"lg"}
                  style={{ position: "relative", padding: "5px" }}
                >
                  {activePackages.length > 0 ||
                    window.location.pathname === '/log-out' ? (
                      <>
                        <Switch>
                          <Route
                            exact
                            path='/'
                            component={AdvertisementPage}
                            activePackage={activePackages}
                          />
                          <ProtectedRoute
                            exact
                            path='/auctions'
                            component={AuctionsPage}
                            activePackage={activePackages.includes(
                              'ROLE_AUCTION'
                            )}
                          />
                          <ProtectedRoute
                            exact
                            path='/tender'
                            component={TenderPage}
                            activePackage={activePackages.includes(
                              'ROLE_AUCTION'
                            )}
                          />
                          <ProtectedRoute
                            exact
                            path='/bailiff-notices'
                            component={BailiffPage}
                            activePackage={activePackages.includes(
                              'ROLE_BAILIFFNOTICE'
                            )}
                          />
                          <ProtectedRoute
                            exact
                            path='/cooperatives'
                            component={Cooperatives}
                            activePackage={activePackages.includes(
                              'ROLE_COOPERATIVE'
                            )}
                          />
                          <ProtectedRoute
                            exact
                            path='/admin-form'
                            component={AddPagesForm}
                            activePackage={activePackages.includes('ROLE_ADMIN')}
                          />
                          <ProtectedRoute
                            exact
                            path='/analysis'
                            component={AnalysisPage}
                            activePackage={activePackages.includes('ROLE_ANALYSIS')}
                          />
                          <ProtectedRoute
                            exact
                            path='/rent'
                            component={RentPage}
                            activePackage={activePackages.includes('ROLE_RENT')}
                          />
                          <Route exact path='/log-out' component={LogOutPage} />
                          <ProtectedRoute
                            component={AdvertisementPage}
                            path='*'
                          />
                        </Switch>
                      </>
                    ) : (
                      <Indicator />
                    )}
                </Container>
              </Wrapper>
            </div>
          </BrowserRouter>
        </div>
        <Footer />
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
