import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';

import DeleteIcon from '@material-ui/icons/Delete';

import { activeNotification } from '../functions/activeNotification';
import { API, redirect } from '../../API';

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 5vw;
  border-top: 1px solid ${({ theme }) => theme.light};
  color: '#585A6B';

  @media (max-width: 960px) {
    padding: 20px 0;
  }

  textarea {
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
      0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
    padding: 20px 20px 20px;
    border: none;
    width: 100%;
    font-size: ${({ theme }) => theme.font.sm};
  }

  textarea[readonly] {
    background-color: ${({ theme }) => theme.secondary} !important;
    color: rgb(111, 116, 125) !important;
  }

  button {
    position: relative;
    bottom: 0;
    left: calc(50% - 50px);
  }
`;

const Notes = ({ id, type, setNoteToState, note }) => {
  let [noteValue, changeNote] = useState(note);
  let [reactionId, setReactionId] = useState(null);
  let [likeStatus, setLikeStatus] = useState(false);



  useEffect(() => {

  }, []);

  const setNote = e => {
    changeNote(e.target.value);
  };

  const saveNote = e => {
    e.preventDefault();

    axios({
      method: 'PUT',
      url: `${API}${type}/reaction/${id}`,
      data: {
        note: noteValue,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(resp => {
        activeNotification('Udało się!', 'Notatka została dodana', 'success');
        setNoteToState(id, noteValue);
      })
      .catch(err => {
        console.log(err)
        if (err?.response?.status === 401) {
          window.location = redirect;
        }
        activeNotification(
          'Coś poszło nie tak',
          'spróbuj jeszcze raz!',
          'danger'
        );
      });
  };

  const deleteNote = () => {
    axios({
      method: 'PUT',
      url: `${API}${type}/reaction/${id}`,
      data: {
        note: noteValue,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(resp => {
        activeNotification('Udało się!', 'Notatka została usunięta', 'warning');
        setNoteToState(id, null);
        changeNote('');
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          window.location = redirect;
        }
        activeNotification(
          'Coś poszło nie tak',
          'spróbuj jeszcze raz!',
          'danger'
        );
      });
  };

  return (
    <Wrapper>
      <div class='form-group mb-4' style={{ position: 'relative' }}>
        <label
          htmlFor='exampleFormControlTextarea1'
          style={{
            color: '#585A6B',
            width: '100%',
            position: 'relative',
          }}
        >
          {noteValue && (
            <DeleteIcon
              style={{ color: '#777' }}
              fontSize='large'
              onClick={() => deleteNote()}
              style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                fontSize: '1.2re',
                cursor: 'pointer',
              }}
            />
          )}
        </label>
        <textarea
          onChange={setNote}
          className='infobox-1'
          id='exampleFormControlTextarea1'
          rows='3'
          placeholder='Wpisz treść notatki'
          value={noteValue}
          maxlength='999'
        ></textarea>
      </div>

      <button className='btn btn-primary' onClick={saveNote}>
        Zapisz notatkę
      </button>
    </Wrapper>
  );
};

export default Notes;
