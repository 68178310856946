import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import axios from 'axios';
import { activeNotification } from '../../functions/activeNotification';

import Indicator from '../../atom/Indicator';
import { API, redirect } from '../../../API';
import { FieldArray } from 'react-final-form-arrays';

import Input from '../atmos/Input';
import Paragraph from '../atmos/Paragraph';

import { ValueContext } from '../Wizard';

const Wrapper = styled.div`
  border-bottom: 2px solid black;
  margin: 60px 0;
`;

const SingleElement = ({ name, required, Error, text }) => {
  const context = useContext(ValueContext);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleTest = () => {
    setIsLoading(true);

    const token = Cookies.get('MonitorApiToken');
    let testField = name;
    const data = JSON.stringify({ testField, ...context });

    axios
      .post(`${API}api/admin/domain`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        data,
      })
      .then(resp => {
        setSuccessMessage(resp.data);
        activeNotification('test OK', 'Udalo się! ', 'success');
        setIsLoading(false);
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          window.location = redirect;
        }
        if (err?.response?.status === 400 || err?.response?.status === 500) {
          activeNotification('test błędny', 'spróbuj jeszcze raz', 'danger');
          setIsLoading(false);
        }
      });
  };

  return (
    <Wrapper>
      {isLoading && <Indicator />}
      <Paragraph>{text}</Paragraph>
      <hr style={{ backgroundColor: '#edecf2', color: '#edecf2', height: 2 }} />
      <FieldArray name={`offersFieldsSearch.${name}`}>
        {({ fields, ...rest }) => (
          <div>
            {fields.map((name, index) => (
              <>
                <Paragraph>Tag</Paragraph>
                <div style={{ marginBottom: '20px' }}>
                  <Input
                    name={`${name}.tag`}
                    component='input'
                    type='text'
                    placeholder='np. div'
                    variant='outlined'
                    validate={required}
                  />
                  <Error name={`${name}.tag`} />
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <Paragraph>Element wyróżniający</Paragraph>
                  <Input
                    name={`${name}.element`}
                    component='input'
                    type='text'
                    placeholder='np. class=offer-item-details'
                    variant='outlined'
                    validate={required}
                  />
                  <Error name={`${name}.element`} />
                </div>

                <button
                  style={{
                    padding: '10px',
                    borderRadius: '50%',
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                    margin: '0 0 0 auto',
                  }}
                  type='button'
                  onClick={() => fields.remove(index)}
                >
                  X
                </button>
              </>
            ))}
            <button
              type='button'
              className='btn btn-primary mb-2'
              style={{
                padding: '10px',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '18px',
                margin: '10px 0 0 auto',
              }}
              onClick={() => fields.push({})}
            >
              +
            </button>
          </div>
        )}
      </FieldArray>
      <p>{successMessage}</p>
      <button
        style={{
          padding: '10px',
          width: '150px',
          height: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '14px',
          margin: '0 0 0 auto',
          backgroundColor: 'green',
          color: 'white',
          border: 'none',
          margin: '10px auto',
        }}
        type='button'
        onClick={() => handleTest()}
      >
        test
      </button>
    </Wrapper>
  );
};

export default SingleElement;
