import React, { useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import ImgHome from '../assets/icons/home.png';

import ImgAdv from '../assets/icons/healthcare-and-medical.png';
import ImgAuc from '../assets/icons/security.png';
import ImgAnl from '../assets/icons/analysis.png';
import ImgBailiff from '../assets/icons/business-and-finance.png';

import { closeNav } from './functions/CloseNav';

const Wrapper = styled.div`
  position: relative;
  top: 0;
  /* left: ${({ fromLeft }) => fromLeft - 300}px; */

  @media (max-width: 990px) {
    left: 0px;
    top: -45px;
  }
`;

const ImgIcon = styled.img`
  margin-right: 15px;
`;

const Navitem = styled(NavLink)`
  &.active {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
   
  }
  /* margin-right: -10px; */
 
`;

const Navigation = ({ activePackages }) => {
  const isClient = typeof window === 'object';

  const getValueFromLeft = () => document.querySelector('#filters')?.getBoundingClientRect().left;

  let [fromLeft, setFromLeft] = useState(getValueFromLeft());

  useLayoutEffect(() => {
    if (!isClient) return false;
    setTimeout(() => setFromLeft(getValueFromLeft(), 1000));
    window.onload = setFromLeft(getValueFromLeft());
    const handleResize = () => {
      const left = getValueFromLeft();
      setFromLeft(left);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className='sidebar-wrapper sidebar-theme'
      style={{ backgroundColor: '#F1F2F3' }}
    >
      <Wrapper fromLeft={fromLeft}>
        <nav id='sidebar'>
          <ul className='list-unstyled menu-categories' id='accordionExample'>
            <li className='menu'>
              <Navitem
                to='/'
                exact
                aria-expanded='false'
                className='dropdown-toggle'
                activeClassName='active'
                onClick={() => closeNav()}
              >
                <div className=''>
                  <ImgIcon src={ImgAdv} alt='ogłoszenia' />
                  <span>Ogłoszenia sprzedaż</span>
                </div>
              </Navitem>
            </li>
            {activePackages.includes('ROLE_RENT') && (
              <li className='menu'>
                <Navitem
                  to='/rent'
                  exact
                  aria-expanded='false'
                  className='dropdown-toggle'
                  activeClassName='active'
                  onClick={() => closeNav()}
                >
                  <div className=''>
                    <ImgIcon src={ImgAdv} alt='wynajem' />
                    <span>Ogłoszenia wynajem</span>
                  </div>
                </Navitem>
              </li>
            )}
            {activePackages.includes('ROLE_AUCTION') && (
              <li className='menu'>
                <Navitem
                  to='/tender'
                  exact
                  aria-expanded='false'
                  className='dropdown-toggle'
                  activeClassName='active'
                  onClick={() => closeNav()}
                >
                  <div className=''>
                    <ImgIcon src={ImgAuc} alt='aukcje' />
                    <span>Przetargi</span>
                  </div>
                </Navitem>
              </li>
            )}
            {activePackages.includes('ROLE_AUCTION') && (
              <li className='menu'>
                <Navitem
                  to='/auctions'
                  exact
                  aria-expanded='false'
                  className='dropdown-toggle'
                  activeClassName='active'
                  onClick={() => closeNav()}
                >
                  <div className=''>
                    <ImgIcon src={ImgAuc} alt='aukcje' />
                    <span>Licytacje komornicze</span>
                  </div>
                </Navitem>
              </li>
            )}
            {activePackages.includes('ROLE_BAILIFFNOTICE') && (
              <li className='menu'>
                <Navitem
                  to='/cooperatives'
                  exact
                  className='dropdown-toggle'
                  aria-expanded='false'
                  activeClassName='active'
                  onClick={() => closeNav()}
                >
                  <div className=''>
                    <ImgIcon src={ImgHome} alt='Spółdzielnie' />
                    <span>Przetargi lokalne</span>
                  
                  </div>
                </Navitem>
              </li>
            )}

            {activePackages.includes('ROLE_COOPERATIVE') && (
              <li className='menu'>
                <Navitem
                  to='/bailiff-notices'
                  exact
                  className='dropdown-toggle'
                  aria-expanded='false'
                  activeClassName='active'
                  onClick={() => closeNav()}
                >
                  <div class='d-flex flex-row'>
                    <div>
                      <ImgIcon src={ImgBailiff} alt='obwieszczenia komornicze' />
                    </div>
                    <span>
                      Informacje o zadłużonych
                      <br /> nieruchomościach
                    </span>
                  </div>
                </Navitem>
              </li>
            )}

            {activePackages.includes('ROLE_ANALYSIS') && (
              <li className='menu'>
                <Navitem
                  to='/analysis'
                  exact
                  aria-expanded='false'
                  className='dropdown-toggle'
                  activeClassName='active'
                  onClick={() => closeNav()}
                >
                  <div className=''>
                    <ImgIcon src={ImgAnl} alt='analizy' />
                    <span>Analizy sprzedaż</span>
                  </div>
                </Navitem>
              </li>
            )}
            
          </ul>
        </nav>
      </Wrapper>
    </div>
  );
};

export default Navigation;
