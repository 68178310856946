import React from 'react';
import styled from 'styled-components';
import { FieldArray } from 'react-final-form-arrays';

import Input from './atmos/Input';
import Paragraph from './atmos/Paragraph';

const Wrapper = styled.div`
  background: white;
  padding: 20px;
  margin-bottom: 20px;
`;

const FormSecondStep = ({ required, Error }) => {
  return (
    <>
      <Paragraph>Znaczki HTML odpowiedzialne za wyciąganie całej listy z ogłoszeniami</Paragraph>
      <hr style={{ backgroundColor: '#edecf2', color: '#edecf2', height: 2 }} />
      <form>
        <FieldArray name='listTags'>
          {({ fields }) => (
            <div>
              {fields.map((name, index) => (
                <Wrapper>
                  <Paragraph>Tag</Paragraph>
                  <div style={{ marginBottom: '20px' }}>
                    <Input
                      name={`${name}.tag`}
                      component='input'
                      type='text'
                      placeholder='np. div'
                      variant='outlined'
                    />
                    <Error name={`${name}.tag`} />
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <Paragraph>Element wyróżniający</Paragraph>
                    <Input
                      name={`${name}.element`}
                      component='input'
                      type='text'
                      placeholder='np. class=offer-item-details'
                      variant='outlined'
                    />
                    <Error name={`${name}.element`} />
                  </div>
                  <button
                    style={{
                      padding: '10px',
                      borderRadius: '50%',
                      width: '50px',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '20px',
                      margin: '0 0 0 auto',
                    }}
                    type='button'
                    onClick={() => fields.remove(index)}
                  >
                    X
                  </button>
                </Wrapper>
              ))}
              <button
                type='button'
                className='btn btn-primary mb-2'
                style={{
                  padding: '10px',
                  borderRadius: '50%',
                  width: '50px',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '20px',
                  margin: '0 0 0 auto',
                }}
                onClick={() => fields.push({ tag: '', element: '' })}
              >
                +
              </button>
            </div>
          )}
        </FieldArray>
        <Paragraph>Znaczki HTML odpowiedzialne za wyciąganie linków do oferty</Paragraph>
        <hr style={{ backgroundColor: '#edecf2', color: '#edecf2', height: 2 }} />
        <FieldArray name='linksTags'>
          {({ fields }) => (
            <div>
              {fields.map((name, index) => (
                <Wrapper>
                  <Paragraph>Tag</Paragraph>
                  <div style={{ marginBottom: '20px' }}>
                    <Input
                      name={`${name}.tag`}
                      component='input'
                      type='text'
                      placeholder='np. div'
                      variant='outlined'
                    />
                    <Error name={`${name}.tag`} />
                  </div>

                  <div style={{ marginBottom: '20px' }}>
                    <Paragraph>Element wyróżniający</Paragraph>
                    <Input
                      name={`${name}.element`}
                      component='input'
                      type='text'
                      placeholder='np. class=offer-item-details'
                      variant='outlined'
                    />
                    <Error name={`${name}.element`} />
                  </div>
                  <button
                    style={{
                      padding: '10px',
                      borderRadius: '50%',
                      width: '50px',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '20px',
                      margin: '0 0 0 auto',
                    }}
                    type='button'
                    onClick={() => fields.remove(index)}
                  >
                    X
                  </button>
                </Wrapper>
              ))}
              <button
                type='button'
                className='btn btn-primary mb-2'
                style={{
                  padding: '10px',
                  borderRadius: '50%',
                  width: '50px',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '20px',
                  margin: '0 0 0 auto',
                }}
                onClick={() => fields.push({ tag: '', element: '' })}
              >
                +
              </button>
            </div>
          )}
        </FieldArray>
      </form>
    </>
  );
};

export default FormSecondStep;
