import { dateFormat, dateFromFunc, dateToFunc } from '../FilterFunctions';

const RoomOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
const FloorOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
const AeraOptions = ['25', '40', '50', '60', '70', '80'];
const PricePerSquareMeterOptions = [
  '1500',
  '2000',
  '2500',
  '3000',
  '3500',
  '4000',
  '4500',
  '5000',
  '6000',
  '7000',
  '8000',
];
const PriceOptions = [
  '50000',
  '100000',
  '150000',
  '200000',
  '250000',
  '300000',
  '350000',
  '400000',
  '500000',
  '600000',
  '700000',
  '800000',
];
const BuildYearOptions = [
  '1920',
  '1950',
  '1980',
  '1990',
  '2000',
  '2004',
  '2006',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
];

export const setStateValues = ({
  filterId,
  name,
  numberOfRoomsFrom,
  numberOfRoomsTo,
  floorFrom,
  floorTo,
  offerFrom,
  unwantedKeywords,
  wantedKeywords,
  city,
  areaFrom,
  areaTo,
  dateFrom,
  dateTo,
  buildingType,
  rentPriceFrom,
  rentPriceTo,
  depositPriceFrom,
  depositPriceTo,
  builtYearFrom,
  builtYearTo,
  type,
  includeZeroArea,
  alertSms,
  alertEmail,
  manyLocations,
  dateRangeFilter,
  daysAmount,
  pushAlert,
}) => {
  return {
    filterId,
    name,
    numberOfRooms: {
      from: numberOfRoomsFrom,
      to: numberOfRoomsTo,
      fromOptions: RoomOptions,
      toOptions: RoomOptions,
    },
    floor: {
      from: floorFrom,
      to: floorTo,
      fromOptions: FloorOptions,
      toOptions: FloorOptions,
      errorTo: false,
      errorFrom: false,
    },
    offerFrom,
    wantedKeywords,
    unwantedKeywords,
    city,
    cityError: false,
    area: {
      from: areaFrom,
      to: areaTo,
      fromOptions: AeraOptions,
      toOptions: AeraOptions,
      errorTo: false,
      errorFrom: false,
    },
    date: {
      from: dateFrom,
      to: dateTo,
      errorFrom: false,
      errorTo: false,
    },
    depositPrice: {
      from: depositPriceFrom,
      to: depositPriceTo,
      fromOptions: PricePerSquareMeterOptions,
      toOptions: PricePerSquareMeterOptions,
      errorTo: false,
      errorFrom: false,
    },
    buildingType: buildingType != '' ? buildingType : [],
    rentPrice: {
      from: rentPriceFrom,
      to: rentPriceTo,
      fromOptions: PriceOptions,
      toOptions: PriceOptions,
      errorTo: false,
      errorFrom: false,
    },
    builtYear: {
      from: builtYearFrom,
      to: builtYearTo,
      fromOptions: BuildYearOptions,
      toOptions: BuildYearOptions,
      errorTo: false,
      errorFrom: false,
    },
    type: type != '' ? type : [],
    includeZeroArea,
    alertSms,
    alertEmail,
    manyLocations,
    dateRangeFilter,
    daysAmount,
    pushAlert,
  };
};

export const getValueFromState = ({
  date,
  area,
  rentPrice,
  depositPrice,
  builtYear,
  buildingType,
  offerFrom,
  floor,
  numberOfRooms,
  type,
  includeZeroArea,
  wantedKeywords,
  unwantedKeywords,
  alertSms,
  alertEmail,
  manyLocations,
  name,
  dateRangeFilter,
  daysAmount,
  pushAlert,
}) => {
  return {
    dateTo: date.to ? dateFormat(dateFromFunc(date.to)) : null,
    dateFrom: date.from ? dateFormat(dateToFunc(date.from)) : null,
    areaFrom: area.from,
    areaTo: area.to,
    rentPriceFrom: rentPrice.from,
    rentPriceTo: rentPrice.to,
    depositPriceFrom: depositPrice.from,
    depositPriceTo: depositPrice.to,
    numberOfRoomsFrom: numberOfRooms.from,
    numberOfRoomsTo: numberOfRooms.to,
    builtYearFrom: builtYear.from,
    builtYearTo: builtYear.to,
    type: type?.length > 0 && type.length !== 3 ? type : null,
    buildingType:
      buildingType?.length > 0 && buildingType.length !== 3
        ? buildingType
        : null,
    offerFrom:
      offerFrom?.length > 0 && offerFrom !== 'Wszyscy' ? offerFrom : null,
    floorFrom: floor.from,
    floorTo: floor.to,
    
    includeZeroArea,
    wantedKeywords: wantedKeywords
      ? wantedKeywords
          .replace(/\s\s+/g, '')
          .replace(/\s*,\s*/g, ',')
          .replace('; ', ',')
          .replace(';', ',')
          .split(' ')
          .join(' ')
      : null,
    unwantedKeywords: unwantedKeywords
      ? unwantedKeywords
          .replace(/\s\s+/g, '')
          .replace(/\s*,\s*/g, ',')
          .replace('; ', ',')
          .replace(';', ',')
          .split(' ')
          .join(' ')
      : null,
    alertSms,
    alertEmail,
    manyLocations,
    name,
    dateRangeFilter,
    daysAmount,
    pushAlert,
  };
};
