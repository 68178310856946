import React from 'react';

const Investoro = () => {
  
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-3 footer-left'>
          <span className='footer_image_wrap '>
            <img
              src='https://investoro.pl/wp-content/uploads/2021/11/Investoro_2021-300x89.png'
              alt=''
              title='logo-investoro'
              srcset='https://investoro.pl/wp-content/uploads/2021/11/Investoro_2021-300x89.png 400w, https://investoro.pl/wp-content/uploads/2020/09/logo-investoro-1-300x104.png 300w'
              sizes='(max-width: 400px) 100vw, 400px'
            />
          </span>
        </div>

        <div className='col-md-6 footer-center'>
          <div className='footer_text_inner footer_text_inner_left'>
          <p>
            {/* <a href="https://investoro.pl/funkcje/" target="_blank">Funkcje</a>
            <br/>
            <a href="https://investoro.pl/cennik/" target="_blank">Cennik</a>
            <br/>
            <a href="https://investoro.pl/kontakt/" target="_blank">Kontakt</a> */}
            <a href='https://investoro.pl/regulamin/' target='_blank'>
              Regulamin
            </a>
            <br />
            <a
              href='https://investoro.pl/polityka-prywatnosci/'
              target='_blank'
            >
              Polityka prywatności
            </a>
          </p>
          </div>

          <div className='footer_text_inner'>
            <p>
              {/* <a href='https://investoro.pl/regulamin/' target='_blank'>
                Regulamin
              </a>
              <br />
              <a
                href='https://investoro.pl/polityka-prywatnosci/'
                target='_blank'
              >
                Polityka prywatności
              </a> 
              <br />*/}
              <strong>{`${new Date().getFullYear()}`} Investoro</strong> Wszelkie Prawa Zastrzeżone
            </p>
          </div>
        </div>
        <div className='col-md-3 footer-right'>
          <ul>
            <li className='footer-right-icon'>
              <a
                href='https://www.facebook.com/investoropl/'
                title='Śledź na Facebook'
                target='_blank'
              >
                <span>
                  <img
                    src='https://investoro.pl/wp-content/uploads/2020/09/ico-fb.png'
                    alt=''
                    title='Obserwuj'
                  />
                </span>
              </a>
            </li>
            <li className='footer-right-icon'>
              <a
                href='https://www.instagram.com/investoropl/'
                title='Śledź na Instagram'
                target='_blank'
              >
                <span>
                  <img
                    src='https://investoro.pl/wp-content/uploads/2020/09/ico-in.png'
                    alt=''
                    title='Obserwuj'
                  />
                </span>
              </a>
            </li>
            <li className='footer-right-icon'>
              <a
                href='https://www.youtube.com/channel/UCG8yVo8ExY1QX2SDbpin0Gw'
                title='Śledź na Youtube'
                target='_blank'
              >
                <span>
                  <img
                    src='https://investoro.pl/wp-content/uploads/2020/09/ico-yt.png'
                    alt=''
                    title='Obserwuj'
                  />
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Investoro;
