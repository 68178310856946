/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import arrayMutators from 'final-form-arrays';

import Wizard from './Wizard';
import { Card } from '@material-ui/core';

import FormFirstStep from './FormFirstStep';
import FormSecondStep from './FormSecondStep';
import FormThirdStep from './FormThirdStep/FormThirdStep';
import FormFourthStep from './FormFourthStep/FormFourthStep';
import FormFifthStep from './FormFifthStep';
import FormZeroStep from './FormZeroStep';

const Paragraph = styled.p`
  font-size: ${({ theme, red }) => (red ? theme.font.md : theme.font.lg)};
  color: ${({ red }) => red && 'red'};
`;

const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const Error = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? (
        <span style={{ color: 'red', fontSize: '14px' }}>{error}</span>
      ) : null
    }
  />
);

const required = value => (value ? undefined : 'Required');

const AddPagesForm = () => {
  const [initialValues, setInitialValues] = useState({});
  const [isActiveChooseForm, setIsActiveChooseForm] = useState(true);

  return (
    <Wrapper>
      {isActiveChooseForm && (
        <FormZeroStep
          setIsActiveChooseForm={setIsActiveChooseForm}
          setInitialValues={setInitialValues}
        />
      )}
      <Card style={{ fontSize: '15px', padding: '10px', marginBottom: '20px' }}>
        <Paragraph style={{ color: 'red', fontWeight: 'bold' }}>
          Wazne!
        </Paragraph>
        <Paragraph>
          Wskazówki dotyczące tego jak prawidłowo wypełnić formularz znajdziesz
          w instrukcji:{' '}
          <b>
            <a
              href='https://mdrive.mayeryn.com/s/tEgTojS5cMzYpNo'
              target='_blank'
            ></a>
          </b>
        </Paragraph>
      </Card>
      {!isActiveChooseForm && (
        <Wizard
          initialValues={initialValues}
          mutators={{
            ...arrayMutators,
          }}
        >
          <Wizard.Page>
            <FormFirstStep required={required} Error={Error} />
          </Wizard.Page>
          <Wizard.Page>
            <FormSecondStep required={required} Error={Error} />
          </Wizard.Page>
          <Wizard.Page>
            <FormThirdStep required={required} Error={Error} t />
          </Wizard.Page>
          <Wizard.Page>
            <FormFourthStep required={required} Error={Error} />
          </Wizard.Page>
          <Wizard.Page>
            <FormFifthStep required={required} Error={Error} />
          </Wizard.Page>
        </Wizard>
      )}
    </Wrapper>
  );
};

export default AddPagesForm;
