import React, { Component, memo, Suspense } from 'react';
import axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie';

import FiltrButton from '../components/FilterButton';
import MainList from '../components/MainList';
import HeaderSection from '../components/HeaderSection';
import { API, redirect } from '../API';
import { activeNotification } from '../components/functions/activeNotification';

import {
  comparePriceAscending,
  comparePriceDescending,
  compareDateAscending,
  compareDateDescending,
  comparePricePerSquareMeterAscending,
  comparePricePerSquareMeterDescending,
  compareByModifyDate,
} from '../components/Sort';
import Indicator from '../components/atom/Indicator';
import { like } from '../components/Reactions/Like';

const Content = memo(
  ({
    advertisemntList,
    handleChangeSort,
    handleChangeNotes,
    handleChangeFavorite,
    checkedFavorite,
    checkedNotes,
    sortType,
    setFavourite,
    setNote,
    sortFromOptions,
    sortPlaceholder,
  }) => {
    return (
      <>
        <HeaderSection
          list={advertisemntList}
          handleChangeSort={handleChangeSort}
          handleChangeNotes={handleChangeNotes}
          handleChangeFavorite={handleChangeFavorite}
          checkedFavorite={checkedFavorite}
          checkedNotes={checkedNotes}
          sortType={sortType}
          sortOptions={sortFromOptions}
          sortPlaceholder={sortPlaceholder}
        />
        <MainList
          list={advertisemntList}
          type='advertisements'
          setFavourite={setFavourite}
          setNote={setNote}
        />
      </>
    );
  }
);

class AdvertisementPage extends Component {
  state = {
    defaultAdvertisemntList: [],
    advertisemntList: [],
    sortType: '',
    checkedFavorite: false,
    checkedNotes: false,
    isLoading: false,
    filters: React.createRef(),
    tooltipDataList: [],
  };

  componentDidMount() {
    axios({
      method: 'get',
      url: `${API}notice/directory`,
    })
      .then(resp => {
        const tooltipData = resp.data;

        this.setState({
          tooltipDataList: tooltipData,
        });
      })
  }

  getData = (data, city) => {
    this.setState({
      isLoading: true,
      sortType: '',
      defaultAdvertisemntList: [],
      advertisemntList: [],
    });

    for (const key of Object.keys(data)) {
      if (data[key] === null || data[key] === '') {
        delete data[key];
      }
    }

    const token = Cookies.get('MonitorApiToken');

    axios({
      method: 'get',
      url: `${API}notice/findByFilter/${city}/`,
      params: data,
      headers: {
        Authorization: 'Bearer ' + token,
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    })
      .then(resp => {
        if (resp.data) {
          if (resp.data.length === 0) {
            this.setState({ isLoading: false });
            activeNotification(
              'Nie znaleziono wyników',
              'Zmień wartości wyszukiwania',
              'warning'
            );
            this.setState({
              defaultAdvertisemntList: [],
              advertisemntList: [],
            });
          } else {
            const results = resp.data;
            const advertisemntList = results.sort(compareDateDescending);
            this.setState({
              defaultAdvertisemntList: advertisemntList,
              advertisemntList,
              isLoading: false,
            });

            window.scrollTo(0, this.state.filters.current.offsetTop + 20);
          }
        } else {
          activeNotification(
            'Nie znaleziono wyników',
            'Zmień wartości wyszukiwania',
            'warning'
          );
        }

        this.setState({
          isLoading: false,
        });
      })

      .catch(err => {
        if (err?.response?.status === 401) {
          window.location = redirect;
        } else {
          this.setState({ isLoading: false });
          activeNotification(
            'Coś poszło nie tak!',
            'Spróbuj ponownie',
            'danger'
          );
        }
      });
  };

  sort = sortType => {
    let { advertisemntList } = this.state;

    switch (sortType) {
      case 'Po cenie rosnąco':
        advertisemntList.sort(comparePriceAscending);
        break;

      case 'Po cenie malejąco':
        advertisemntList.sort(comparePriceDescending);
        break;

      case 'Po dacie rosnąco':
        advertisemntList.sort(compareDateAscending);
        break;

      case 'Po dacie malejąco':
        advertisemntList.sort(compareDateDescending);
        break;

      case 'Po cenie za m2 rosnąco':
        advertisemntList.sort(comparePricePerSquareMeterAscending);
        break;

      case 'Po cenie za m2 malejąco':
        advertisemntList.sort(comparePricePerSquareMeterDescending);
        break;

      case 'Od największej różnicy dat modyfikacji i dodania':
        advertisemntList.sort(compareByModifyDate);

      default:
        break;
    }
    this.setState({ advertisemntList });
  };

  handleChangeSort = (fieldName, value) => {
    this.setState({
      [fieldName]: value,
    });
    this.sort(value);
  };

  handleChangeFavorite = event => {
    let advertisemntList = this.state.defaultAdvertisemntList;
    if (event.target.checked) {
      if (this.state.checkedNotes)
        advertisemntList = advertisemntList.filter(
          el => el.reaction && el.reaction.note
        );
      else
        advertisemntList = advertisemntList.filter(
          el => el.reaction && el.reaction.likeStatus
        );
    } else {
      if (this.state.checkedNotes)
        advertisemntList = this.state.defaultAdvertisemntList.filter(
          el => el.reaction.note
        );
      else advertisemntList = this.state.defaultAdvertisemntList;
    }

    this.setState({
      checkedFavorite: event.target.checked,
      advertisemntList,
    });
  };

  handleChangeNotes = event => {
    let advertisemntList = this.state.defaultAdvertisemntList;
    if (event.target.checked) {
      if (this.state.checkedFavorite)
        advertisemntList = advertisemntList.filter(
          el => el.reaction.likeStatus && el.reaction.note
        );
      else
        advertisemntList = advertisemntList.filter(el => el.reaction && el.reaction.note);
    } else {
      if (this.state.checkedFavorite)
        advertisemntList = this.state.defaultAdvertisemntList.filter(
          el => el.reaction.likeStatus
        );
      else advertisemntList = this.state.defaultAdvertisemntList;
    }

    this.setState({
      checkedNotes: event.target.checked,
      advertisemntList,
    });
  };

 
  setFavourite = id => {
    this.setState({ isLoading: true });

    const list = this.state.advertisemntList;
    const likeAdded = () => this.setState({ isLoading: false });

    const newList = list.map(el => {
      const reactions = el.reaction;
      const newStatus = reactions ? !reactions.likeStatus : true
      const newReactions = { ...reactions, likeStatus: newStatus };

      if (el.advertisementId === id) {
        like(id, 'notice', likeAdded, newStatus);
        if (el.reaction) {
          el.reaction.likeStatus = newStatus;
        } else {
          el.reaction.push({ newReactions });
        }
      }
      return el;
    });
    this.setState({ advertisemntList: newList });
  };

  

  setNote = (id, note) => {
    let list = this.state.advertisemntList;

    list = list.map(el => {
      if (el.advertisementId === id) {
        if (el.reaction) {
          el.reaction.note = note;
        } else {
          el.reaction.push({ note });
        }
      }
      return el;
    });
    this.setState({ advertisemntList: list });
  };

  render() {
    const sortFromOptions =
      [
        'Po dacie malejąco',
        'Po dacie rosnąco',
        'Po cenie rosnąco',
        'Po cenie malejąco',
        'Po cenie za m2 rosnąco',
        'Po cenie za m2 malejąco',
        'Od największej różnicy dat modyfikacji i dodania',
      ];
    const sortPlaceholder = 'Po dacie malejąco'
    const {
      advertisemntList,
      sortType,
      checkedFavorite,
      checkedNotes,
      isLoading,
      tooltipDataList,
    } = this.state;

    return (
      <div>
        {isLoading && <Indicator />}
        <FiltrButton handleSubmit={this.getData} type='advertisement' tooltipDataList={tooltipDataList} />
        <hr
          style={{ backgroundColor: '#edecf2', color: '#edecf2', height: 2 }}
          ref={this.state.filters}
        />

        {(advertisemntList.length > 0 || checkedFavorite || checkedNotes) && (
          <Content
            advertisemntList={advertisemntList}
            handleChangeSort={this.handleChangeSort}
            handleChangeNotes={this.handleChangeNotes}
            handleChangeFavorite={this.handleChangeFavorite}
            checkedFavorite={checkedFavorite}
            checkedNotes={checkedNotes}
            sortType={sortType}
            setFavourite={this.setFavourite}
            setNote={this.setNote}
            sortFromOptions={sortFromOptions}
            sortPlaceholder={sortPlaceholder}
          />
        )}
      </div>
    );
  }
}

export default AdvertisementPage;
