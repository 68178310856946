import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import qs from 'qs';
import { activeNotification } from '../functions/activeNotification';

import SaveFilterButtons from '../SaveFilterButtons';
import {
  FormSelect,
  FormRadio,
  FormCheckboxSelect,
  FormAutoCompleteFreeSolo,
  FormTextField,
  SavedFilter,
  FormSelectRoom,
  FormCheckBoxManyCities,
  FormCheckBoxDateRange,
  FormSelectDate,
} from '../InputFilters';
import {
  Area,
  PricePerSquareMeter,
  Price,
  SectionStyle,
  SectionStyleCity,
  FilterButtons,
} from '../FilterSections';
import {
  setStateValues,
  getValueFromState,
} from '../functions/advertisementsFuncs';
import { API, redirect } from '../../API';
import OfertDate from '../OfertDate';
import { CityOptions } from '../functions/cities';
import styled from 'styled-components';

const DateOptions = [1, 2, 3, 4, 5, 6, 7, 14, 30, 60, 180, 360, -1];
const DateOptionsLabels = {
  1: 'dzień',
  2: 'dni',
  3: 'dni',
  4: 'dni',
  5: 'dni',
  6: 'dni',
  7: 'dni',
  14: 'dni',
  30: 'dni',
  60: 'dni',
  180: 'dni',
  360: 'dni',
  '-1': 'Wszystkie',
};

const RoomOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

const FloorOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

const OfferFromOptions = ['Wszyscy', 'Agencja', 'Osoba prywatna'];

const AeraOptions = ['25', '40', '50', '60', '70', '80'];

const TypeOfMarketOptions = ['Pierwotny', 'Wtórny', 'Pierwotny i wtórny'];

const PricePerSquareMeterOptions = [
  '1500',
  '2000',
  '2500',
  '3000',
  '3500',
  '4000',
  '4500',
  '5000',
  '6000',
  '7000',
  '8000',
];

const PropertyTypeOptions = ['Domy', 'Mieszkania', 'Działki'];

const PriceOptions = [
  '50000',
  '100000',
  '150000',
  '200000',
  '250000',
  '300000',
  '350000',
  '400000',
  '500000',
  '600000',
  '700000',
  '800000',
];

const BuildYearOptions = [
  '1920',
  '1950',
  '1980',
  '1990',
  '2000',
  '2004',
  '2006',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
];

const Title = styled.p`
  font-size: 1.4rem;
  margin-bottom: 0;
`;

const styles = (theme) => ({
  tooltipIcon: {
    margin: theme.spacing(0.5),
  },
  tooltipGridBox: {
    marginBottom: '0.6rem',
  },
});

const TypeOptions = ['Blok', 'Kamienica', 'Apartamentowiec', 'Pozostałe'];

function initialState() {
  const data = {
    filterId: null,
    name: null,
    numberOfRoomsFrom: null,
    numberOfRoomsTo: null,
    floorFrom: null,
    floorTo: null,
    offerFrom: null,
    unwantedKeywords: null,
    wantedKeywords: null,
    city: null,
    areaFrom: null,
    areaTo: null,
    typeOfMarket: null,
    dateFrom: null,
    dateTo: null,
    buildingType: [],
    groupDuplicates: true,
    priceFrom: null,
    priceTo: null,
    pricePerSquareMeterFrom: null,
    pricePerSquareMeterTo: null,
    builtYearFrom: null,
    builtYearTo: null,
    type: [],
    includeZeroArea: true,
    alertSms: false,
    alertEmail: false,
    pushAlert: false,
    manyLocations: false,
    dateRangeFilter: false,
    daysAmount: 3,
    tossedUp: true,
    notificationDelay: null,
  };

  return setStateValues(data);
}

class FiltersAdvertisement extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState(), hideFilters: false };
    this.wantedKeywords = React.createRef();
    this.unwantedKeywords = React.createRef();
    this.name = React.createRef();
  }

  initialDate = () => {
    const dateOferts = this.state.date;
    // dateOferts.to = new Date();
    const now = new Date();
    dateOferts.from = now.setDate(now.getDate() - 3);
    return dateOferts;
  };

  onUnload = () => {
    localStorage.setItem(
      'advertisement',
      JSON.stringify({ city: this.state.city })
    );
  };

  componentDidMount = () => {
    //1280? 991
    window.addEventListener('beforeunload', this.onUnload);
    const state = this.initialDate();
    const retrievedObject = localStorage.getItem('advertisement');
    if (retrievedObject) {
      const data = JSON.parse(retrievedObject);
      state.city = data.city;
    }
    this.setState(state);
    this.getSavedFilters().then((filters) => {
      this.savedFilters = filters;
      this.setState({ savedFilter: filters });
    });

    if (window.screen.width < 991) {
      this.setState({ hideFilters: true });
    }
  };

  componentDidUpdate = () => {
    if (this.wantedKeywords.current)
      this.wantedKeywords.current.value = this.state.wantedKeywords;
    if (this.unwantedKeywords.current)
      this.unwantedKeywords.current.value = this.state.unwantedKeywords;
  };

  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', this.onUnload);
    this.onUnload();
  };

  clearFilters = (e) => {
    e && e.preventDefault();
    if (this.unwantedKeywords.current) this.unwantedKeywords.current.value = '';
    if (this.wantedKeywords.current) this.wantedKeywords.current.value = '';
    if (this.name.current) this.name.current.value = '';
    const oldState = initialState();
    // oldState.date = this.initialDate();
    oldState.nameFiltr = this.name.current.value;
    this.setState(oldState);
  };

  hideFilters = () => {
    const newVal = !this.state.hideFilters;
    const newState = { ...this.state, hideFilters: newVal };
    this.setState(newState);
  };

  validateFrom = (fieldName, value, options) => {
    const codeRegex = /^-?\d+(?:[.,]\d*?)?$/;
    const field = this.state[fieldName];
    field.from = value;
    const wrongSize = field.to * 1 < field.from * 1;
    if (value && field) {
      field.errorFrom =
        !codeRegex.test(value) ||
        value * 1 < 0 ||
        (codeRegex.test(field.to) && wrongSize);
      field.errorTo =
        ((!codeRegex.test(field.to) || field.to * 1 < 0) &&
          field.to?.length > 0) ||
        (codeRegex.test(field.to) && wrongSize);
    } else {
      field.errorFrom = false;
      field.errorTo =
        (!codeRegex.test(field.to) || field.to * 1 < 0) && field.to?.length > 0;
      field.toOptions = options;
    }
    if (!field.errorFrom && value) {
      this.handleChangeFrom(fieldName, value, options);
      return;
    }
    this.setState({
      [fieldName]: field,
    });
  };

  validateTo = (fieldName, value, options) => {
    const codeRegex = /^-?\d+(?:[.,]\d*?)?$/;
    const field = this.state[fieldName];
    field.to = value;
    const wrongSize = field.to * 1 < field.from * 1;
    if (value && field) {
      field.errorTo =
        !codeRegex.test(value) ||
        value * 1 < 0 ||
        (codeRegex.test(field.from) && wrongSize);
      field.errorFrom =
        ((!codeRegex.test(field.from) || field.from * 1 < 0) &&
          field.from?.length > 0) ||
        (codeRegex.test(field.from) && wrongSize);
    } else {
      field.errorTo = false;
      field.errorFrom =
        (!codeRegex.test(field.from) || field.from * 1 < 0) &&
        field.from?.length > 0;
      field.fromOptions = options;
    }
    if (!field.errorTo && value) {
      this.handleChangeTo(fieldName, value, options);
      return;
    }
    this.setState({
      [fieldName]: field,
    });
  };

  handleChange = (fieldName, value) => {
    this.setState({
      [fieldName]: value,
    });
  };

  selectAll = (fieldName, optionList, check) => {
    this.setState({
      [fieldName]: check ? [] : optionList,
    });
  };

  handleChangeCity = (fieldName, value) => {
    this.setState({
      city: value,
      cityError: false,
    });
  };
  handleChangeManyLocations = (fieldName, checked) => {
    this.setState({
      manyLocations: checked,
    });
  };

  handleChangeDateRange = (fieldName, checked) => {
    this.setState({
      dateRangeFilter: checked,
    });
  };

  handleChangeDateList = (fieldName, value) => {
    const date = this.state.date;
    const dt = new Date();
    dt.setDate(dt.getDate() - value);
    date.from = value === -1 ? null : dt;
    date.to = null;
    this.setState({ date, [fieldName]: value });
  };

  handleChangeFrom = (fieldName, value, options) => {
    let sectionField = this.state[fieldName];
    let optionList = [];
    sectionField.from = value;
    if (value) {
      for (let i = 0; i < options.length; i++) {
        if (options[i] * 1 >= value * 1) {
          optionList.push(options[i]);
        }
      }
    } else {
      optionList = options;
    }
    sectionField.toOptions = optionList;
    this.setState({
      [fieldName]: sectionField,
    });
  };

  handleChangeTo = (fieldName, value, options) => {
    let sectionField = this.state[fieldName];
    let optionList = [];
    sectionField.to = value;
    if (value) {
      for (let i = 0; i < options.length; i++) {
        if (options[i] * 1 <= value * 1) {
          optionList.push(options[i]);
        }
      }
    } else {
      optionList = options;
    }
    sectionField.fromOptions = optionList;
    this.setState({
      [fieldName]: sectionField,
    });
  };

  changeDate = (offertDate) => {
    const date = this.state.date;
    date.errorFrom = offertDate.errorFrom;
    date.errorTo = offertDate.errorTo;
    date.from = offertDate.from;
    date.to = offertDate.to;
    this.setState({ date });
  };

  handleSetFiltersValues = (name, value) => {
    this.setState({
      filterId: value,
    });

    axios
      .get(`${API}notice/filter/${value}/`)
      .then((resp) => {
        const filters = resp.data;

        const data = {
          filterId: filters.filterId,
          // name: filters.name,
          numberOfRoomsFrom: filters.numberOfRoomsFrom,
          numberOfRoomsTo: filters.numberOfRoomsTo,
          floorFrom: filters.floorFrom,
          floorTo: filters.floorTo,
          offerFrom: filters.offerFrom,
          unwantedKeywords: filters.unwantedKeywords,
          wantedKeywords: filters.wantedKeywords,
          city: filters.city,
          areaFrom: filters.areaFrom,
          areaTo: filters.areaTo,
          typeOfMarket: filters.typeOfMarket,
          dateFrom: filters.dateFrom,
          dateTo: filters.dateTo,
          buildingType: filters.buildingType
            ? filters.buildingType.split(',')
            : [],
          groupDuplicates: this.state.groupDuplicates,
          priceFrom: filters.priceFrom,
          priceTo: filters.priceTo,
          pricePerSquareMeterFrom: filters.pricePerSquareMeterFrom,
          pricePerSquareMeterTo: filters.pricePerSquareMeterTo,
          builtYearFrom: filters.builtYearFrom,
          builtYearTo: filters.builtYearTo,
          type: filters.type ? filters.type.split(',') : [],
          includeZeroArea: filters.includeZeroArea,
          alertSms: filters.alertSms,
          alertEmail: filters.alertEmail,
          pushAlert: filters.pushAlert,
          manyLocations: filters.manyLocations,
          dateRangeFilter: filters.daysAmount ? false : true,
          daysAmount: filters.daysAmount,
          tossedUp: filters.tossedUp,
          notificationDelay: filters.notificationsDelay,
        };
        if (this.wantedKeywords.current)
          this.wantedKeywords.current.value = resp.data.wantedKeywords;
        if (this.unwantedKeywords.current)
          this.unwantedKeywords.current.value = resp.data.unwantedKeywords;
        if (this.name.current) this.name.current.value = resp.data.name;
        const state = setStateValues(data);
        this.setState({ ...state });
        if (filters.daysAmount)
          this.handleChangeDateList('daysAmount', filters.daysAmount);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          window.parent.location.reload();
        }
      });
  };

  isError = () => {
    let error = false;

    Object.values(this.state).forEach((value) => {
      if (value) {
        Object.keys(value).forEach((field) => {
          if (field) {
            if (
              (field === 'errorTo' || field === 'errorFrom') &&
              value[field] === true
            ) {
              error = true;
            }
          }
        });
      }
    });
    return error;
  };

  getSavedFilters = () => {
    return axios
      .get(`${API}notice/filter/all`)
      .then((resp) => {
        if (resp.data) {
          const filters = resp?.data?.map((el) => {
            return {
              name: el.name,
              filterId: el.filterId,
              alertSms: el.alertSms,
              alertEmail: el.alertEmail,
              pushAlert: el.pushAlert,
              manyLocations: el.manyLocations,
            };
          });
          return filters;
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          window.parent.location.reload();
        }
      });
  };

  removeSavedFilter = (e) => {
    axios
      .delete(`${API}notice/filter/delete/${this.state.filterId}/`)
      .then((resp) => {
        for (let i = 0; i < this.savedFilters.length; i++) {
          if (this.savedFilters[i].filterId === this.state.filterId) {
            this.savedFilters.splice(i, 1);
          }
        }
        this.setState({ savedFilter: this.savedFilters, filterId: '' });
        this.clearFilters();
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          window.parent.location.reload();
        } else {
          activeNotification(
            'Coś poszło nie tak!',
            'Spróbuj ponownie',
            'danger'
          );
        }
      });
    e.preventDefault();
  };

  addFilter = (params) => {
    return axios({
      method: 'post',
      url: `${API}notice/filter/add`,

      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    })
      .then((resp) => {
        this.getSavedFilters().then((filters) => {
          for (let i = 0; i < filters.length; i++) {
            const existingFilter = this.savedFilters?.find(
              (el) => el.filterId === filters[i].filterId
            );
            if (!existingFilter) {
              this.savedFilters = filters;
              this.setState({
                name: filters[i].name,
                filterId: filters[i].filterId,
                savedFilter: filters,
              });
              this.name.current.value = filters[i].name;
              break;
            }
          }
        });
        return resp;
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          window.parent.location.reload();
        } else if (err?.response?.status === 400) {
          activeNotification(
            'Wykorzystano dostępną ilość filtrów dla Twojego pakietu!',
            'Zmień pakiet, lub usuń jeden z zapisanych filtrów.',
            'danger'
          );
          return Promise.reject(err);
        } else {
          activeNotification(
            'Coś poszło nie tak!',
            'Spróbuj ponownie',
            'danger'
          );
          return Promise.reject(err);
        }
      });
  };

  updateFilter = (id, params) => {
    return axios({
      method: 'put',
      url: `${API}notice/filter/update/${id}/`,

      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    })
      .then((resp) => {
        this.handleSetFiltersValues('', id);
        return resp;
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          window.parent.location.reload();
        }
        return Promise.reject(err);
      });
  };

  saveFilter = (e) => {
    e.preventDefault();
    const promise = [];
    if (
      document.activeElement.id == 'name' ||
      document.activeElement.id == ''
    ) {
      document.activeElement.blur();
      if (!this.state.city || !this.state.city.replace(/\s/g, '').length) {
        this.setState({
          cityError: !this.state.city,
        });
      } else if (!this.isError()) {
        const state = this.state;
        state.name = this.name.current.value;
        const data = getValueFromState(state);
        const { city } = this.state;
        for (const key of Object.keys(data)) {
          if (data[key] === null || data[key] === '') {
            delete data[key];
          }
        }
        const saveName = this.state.savedFilter?.find(
          (filter) => filter.name == this.state.name
        );
        let id;
        saveName && (id = saveName.filterId);
        const daysAmount = state.dateRangeFilter ? null : state.daysAmount;
        const rest = saveName
          ? this.updateFilter(id, { ...data, city, daysAmount })
          : this.addFilter({ ...data, city, daysAmount });
        promise.push(rest);
      }
    } else {
      document.activeElement.blur();
      this.handleSubmit(e);
    }
    return promise;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.city || !this.state.city.replace(/\s/g, '').length) {
      this.setState({
        cityError: true,
      });
    } else if (!this.isError()) {
      const data = getValueFromState(this.state);
      this.props.handleSubmit(data, this.state.city);
    }
  };

  findById(source, id) {
    for (let i = 0; i < source.length; i++) {
      if (source[i].id === id) {
        return source[i];
      }
    }
  }

  render() {
    const { state } = this;
    const { classes } = this.props;
    const tooltipList = this.props.tooltipDataList;

    let toolTipPrice = tooltipList.filter((item) => item.field === 'PRICE')[0]
      ?.content;
    let toolTipArea = tooltipList.filter((item) => item.field === 'AREA')[0]
      ?.content;
    let toolTipDate = tooltipList.filter((item) => item.field === 'DATE')[0]
      ?.content;
    let toolTipCity = tooltipList.filter((item) => item.field === 'CITY')[0]
      ?.content;
    let toolTipNrRoom = tooltipList.filter(
      (item) => item.field === 'NUMBER_OF_ROOM'
    )[0]?.content;
    let toolTipPricePerSq = tooltipList.filter(
      (item) => item.field === 'PRICE_PER_SQUARE_METER'
    )[0]?.content;
    let toolTipFloor = tooltipList.filter((item) => item.field === 'FLOOR')[0]
      ?.content;
    let toolTipYear = tooltipList.filter(
      (item) => item.field === 'BUILT_YEAR'
    )[0]?.content;
    let toolTipMarket = tooltipList.filter(
      (item) => item.field === 'TYPE_OF_MARKET'
    )[0]?.content;
    let toolTipDuplicate = tooltipList.filter(
      (item) => item.field === 'GROUP_DUPLICATE'
    )[0]?.content;
    let toolTipZero = tooltipList.filter(
      (item) => item.field === 'INCLUDE_ZERO'
    )[0]?.content;
    let toolTipWanted = tooltipList.filter(
      (item) => item.field === 'WANTEDWORDS'
    )[0]?.content;
    let toolTipUnwanted = tooltipList.filter(
      (item) => item.field === 'UNWANTEDWORDS'
    )[0]?.content;
    let toolTipBuildingType = tooltipList.filter(
      (item) => item.field === 'BUILDING_TYPE'
    )[0]?.content;
    let toolTipFilter = tooltipList.filter(
      (item) => item.field === 'SAVED_FILTERS'
    )[0]?.content;
    let toolTipOfferType = tooltipList.filter(
      (item) => item.field === 'TYPE_0F_OFFERS'
    )[0]?.content;
    let toolTipOfferFrom = tooltipList.filter(
      (item) => item.field === 'OFFERS_FROM'
    )[0]?.content;
    let tooltipTossedUp = tooltipList.filter(
      (item) => item.field === 'TOSSED_UP'
    )[0]?.content;
    let tooltipNotificationDelay = tooltipList.filter(
      (item) => item.field === 'NOTIFICATION_DELAY'
    )[0]?.content;

    return (
      <form onSubmit>
        <Grid container spacing={0}>
          {this.state.hideFilters && (
            <>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Zapisane filtry</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipFilter}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <SavedFilter
                    name={state.name}
                    options={this.savedFilters}
                    onChange={this.handleSetFiltersValues}
                    removeSavedFilter={this.removeSavedFilter}
                    placeholder="Wybierz filtr"
                    value={state.filterId}
                  />
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyleCity>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Miasto</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipCity}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <FormAutoCompleteFreeSolo
                    id="cities"
                    name="city"
                    value={state.city}
                    optionList={CityOptions}
                    onBlur={this.handleChangeCity}
                    onChange={this.handleChangeCity}
                    error={state.cityError}
                    placeholder="Miasto,dzielnica,ulica"
                  />
                  <Grid item container xs={12} md={6} lg={8}>
                    <FormCheckBoxManyCities
                      id="manyLocations"
                      name="manyLocations"
                      checked={state.manyLocations}
                      onChange={this.handleChangeManyLocations}
                      label={
                        <Box component="div" fontSize={12}>
                          Wiele lokacji
                        </Box>
                      }
                    />
                  </Grid>
                </SectionStyleCity>
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Cena</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipPrice}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>

                  <Price
                    price={state.price}
                    handleChangeFrom={this.handleChangeFrom}
                    handleChangeTo={this.handleChangeTo}
                    validateFrom={this.validateFrom}
                    validateTo={this.validateTo}
                    options={PriceOptions}
                  />
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Cena za metr</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipPricePerSq}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <PricePerSquareMeter
                    handleChangeFrom={this.handleChangeFrom}
                    handleChangeTo={this.handleChangeTo}
                    pricePerSquareMeter={state.pricePerSquareMeter}
                    validateFrom={this.validateFrom}
                    validateTo={this.validateTo}
                    options={PricePerSquareMeterOptions}
                  />
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Liczba pokoi</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipNrRoom}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <Grid container spacing={3} style={{ flexGrow: 1 }}>
                    <Grid item xs={6}>
                      <FormSelectRoom
                        id="numberOfRoomsFrom"
                        name="numberOfRooms"
                        value={state.numberOfRooms.from}
                        onChange={(fieldName, value) =>
                          this.handleChangeFrom(fieldName, value, RoomOptions)
                        }
                        optionList={state.numberOfRooms.fromOptions}
                        placeholder="Od"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormSelectRoom
                        id="numberOfRoomsTo"
                        name="numberOfRooms"
                        value={state.numberOfRooms.to}
                        onChange={(fieldName, value) =>
                          this.handleChangeTo(fieldName, value, RoomOptions)
                        }
                        optionList={state.numberOfRooms.toOptions}
                        placeholder="Do"
                      />
                    </Grid>
                  </Grid>
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Metraż</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipArea}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <Area
                    aera={state.area}
                    validateTo={this.validateTo}
                    validateFrom={this.validateFrom}
                    handleChangeFrom={this.handleChangeFrom}
                    handleChangeTo={this.handleChangeTo}
                    options={AeraOptions}
                  />
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Typ nieruchomości</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipOfferType}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <FormCheckboxSelect
                    id="type"
                    name="type"
                    value={state.type}
                    onChange={this.handleChange}
                    optionList={PropertyTypeOptions}
                    placeholder="Wybierz typ budynku"
                    selectAll={this.selectAll}
                  />
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Słowa kluczowe pożądane</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipWanted}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <FormTextField
                    id="wantedKeywords"
                    name="wantedKeywords"
                    placeholder="Wpisz słowa kluczowe"
                    onBlur={this.handleChange}
                    ref={this.wantedKeywords}
                  />
                </SectionStyle>
              </Grid>
            </>
          )}
          {!this.state.hideFilters && (
            <>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Zapisane filtry</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipFilter}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <SavedFilter
                    name={state.name}
                    options={this.savedFilters}
                    onChange={this.handleSetFiltersValues}
                    removeSavedFilter={this.removeSavedFilter}
                    placeholder="Wybierz filtr"
                    value={state.filterId}
                  />
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyleCity>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Miasto</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipCity}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <FormAutoCompleteFreeSolo
                    id="cities"
                    name="city"
                    value={state.city}
                    optionList={CityOptions}
                    onBlur={this.handleChangeCity}
                    onChange={this.handleChangeCity}
                    error={state.cityError}
                    placeholder="Miasto,dzielnica,ulica"
                  />
                  <Grid item container xs={12} md={6} lg={8}>
                    <FormCheckBoxManyCities
                      id="manyLocations"
                      name="manyLocations"
                      checked={state.manyLocations}
                      onChange={this.handleChangeManyLocations}
                      label={
                        <Box component="div" fontSize={12}>
                          Wiele lokacji
                        </Box>
                      }
                    />
                  </Grid>
                </SectionStyleCity>
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <SectionStyleCity>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <p style={{ fontSize: '1.4rem', marginBottom: '0' }}>
                      Data dodania oferty
                    </p>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipDate}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  {state.dateRangeFilter ? (
                    <OfertDate
                      changeDate={this.changeDate}
                      to={state.date.to}
                      from={state.date.from}
                      errorTo={state.date.errorTo}
                      errorFrom={state.date.errorFrom}
                    />
                  ) : (
                    <FormSelectDate
                      id="daysAmount"
                      name="daysAmount"
                      value={state.daysAmount}
                      onChange={this.handleChangeDateList}
                      optionList={DateOptions}
                      dateOptionsLabels={DateOptionsLabels}
                      placeholder="Wybierz ilość ostatnich dni"
                    />
                  )}
                  <FormCheckBoxDateRange
                    id="dateRangeFilter"
                    name="dateRangeFilter"
                    checked={state.dateRangeFilter}
                    onChange={this.handleChangeDateRange}
                    label={
                      <Box component="div" fontSize={12}>
                        Zakres dat
                      </Box>
                    }
                  />
                </SectionStyleCity>
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Cena</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipPrice}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>

                  <Price
                    price={state.price}
                    handleChangeFrom={this.handleChangeFrom}
                    handleChangeTo={this.handleChangeTo}
                    validateFrom={this.validateFrom}
                    validateTo={this.validateTo}
                    options={PriceOptions}
                  />
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Cena za metr</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipPricePerSq}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <PricePerSquareMeter
                    handleChangeFrom={this.handleChangeFrom}
                    handleChangeTo={this.handleChangeTo}
                    pricePerSquareMeter={state.pricePerSquareMeter}
                    validateFrom={this.validateFrom}
                    validateTo={this.validateTo}
                    options={PricePerSquareMeterOptions}
                  />
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Liczba pokoi</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipNrRoom}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <Grid container spacing={3} style={{ flexGrow: 1 }}>
                    <Grid item xs={6}>
                      <FormSelectRoom
                        id="numberOfRoomsFrom"
                        name="numberOfRooms"
                        value={state.numberOfRooms.from}
                        onChange={(fieldName, value) =>
                          this.handleChangeFrom(fieldName, value, RoomOptions)
                        }
                        optionList={state.numberOfRooms.fromOptions}
                        placeholder="Od"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormSelectRoom
                        id="numberOfRoomsTo"
                        name="numberOfRooms"
                        value={state.numberOfRooms.to}
                        onChange={(fieldName, value) =>
                          this.handleChangeTo(fieldName, value, RoomOptions)
                        }
                        optionList={state.numberOfRooms.toOptions}
                        placeholder="Do"
                      />
                    </Grid>
                  </Grid>
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Metraż</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipArea}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <Area
                    aera={state.area}
                    validateTo={this.validateTo}
                    validateFrom={this.validateFrom}
                    handleChangeFrom={this.handleChangeFrom}
                    handleChangeTo={this.handleChangeTo}
                    options={AeraOptions}
                  />
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Piętro</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipFloor}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <Grid container spacing={3} style={{ flexGrow: 1 }}>
                    <Grid item xs={6}>
                      <FormAutoCompleteFreeSolo
                        id="floorFrom"
                        name="floor"
                        maxLength={2}
                        value={state.floor.from && state.floor.from.toString()}
                        onChange={(fieldName, value) =>
                          this.handleChangeFrom(fieldName, value, FloorOptions)
                        }
                        optionList={state.floor.fromOptions}
                        onBlur={(fieldName, value) =>
                          this.validateFrom(fieldName, value, FloorOptions)
                        }
                        error={state.floor.errorFrom}
                        placeholder="Od"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormAutoCompleteFreeSolo
                        id="floorTo"
                        name="floor"
                        maxLength={2}
                        value={state.floor.to && state.floor.to.toString()}
                        onChange={(fieldName, value) =>
                          this.handleChangeTo(fieldName, value, FloorOptions)
                        }
                        optionList={state.floor.toOptions}
                        onBlur={(fieldName, value) =>
                          this.validateTo(fieldName, value, FloorOptions)
                        }
                        error={state.floor.errorTo}
                        placeholder="Do"
                      />
                    </Grid>
                  </Grid>
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Typ nieruchomości</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipOfferType}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <FormCheckboxSelect
                    id="type"
                    name="type"
                    value={state.type}
                    onChange={this.handleChange}
                    optionList={PropertyTypeOptions}
                    placeholder="Wybierz typ budynku"
                    selectAll={this.selectAll}
                  />
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Oferta od</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipOfferFrom}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <FormSelect
                    id="offerFrom"
                    name="offerFrom"
                    value={state.offerFrom}
                    onChange={this.handleChange}
                    optionList={OfferFromOptions}
                    placeholder="Wybierz od kogo oferta"
                  />
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Słowa kluczowe pożądane</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipWanted}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <FormTextField
                    id="wantedKeywords"
                    name="wantedKeywords"
                    placeholder="Wpisz słowa kluczowe"
                    onBlur={this.handleChange}
                    ref={this.wantedKeywords}
                  />
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Słowa kluczowe niepożądane</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipUnwanted}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <FormTextField
                    id="unwantedKeywords"
                    name="unwantedKeywords"
                    placeholder="Wpisz słowa kluczowe"
                    onBlur={this.handleChange}
                    ref={this.unwantedKeywords}
                  />
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Rok budowy</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipYear}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <Grid container spacing={3} style={{ flexGrow: 1 }}>
                    <Grid item xs={6}>
                      <FormAutoCompleteFreeSolo
                        id="builtYearFrom"
                        name="builtYear"
                        maxLength={5}
                        value={
                          state.builtYear.from &&
                          state.builtYear.from.toString()
                        }
                        onChange={(fieldName, value) =>
                          this.handleChangeFrom(
                            fieldName,
                            value,
                            BuildYearOptions
                          )
                        }
                        optionList={state.builtYear.fromOptions}
                        placeholder="Od"
                        onBlur={(fieldName, value) =>
                          this.validateFrom(fieldName, value, BuildYearOptions)
                        }
                        error={state.builtYear.errorFrom}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormAutoCompleteFreeSolo
                        id="builtYearTo"
                        name="builtYear"
                        maxLength={5}
                        value={
                          state.builtYear.to && state.builtYear.to.toString()
                        }
                        optionList={state.builtYear.toOptions}
                        onChange={(fieldName, value) =>
                          this.handleChangeTo(
                            fieldName,
                            value,
                            BuildYearOptions
                          )
                        }
                        placeholder="Do"
                        onBlur={(fieldName, value) =>
                          this.validateTo(fieldName, value, BuildYearOptions)
                        }
                        error={state.builtYear.errorTo}
                      />
                    </Grid>
                  </Grid>
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Rodzaj budynku</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipBuildingType}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <FormCheckboxSelect
                    id="buildingType"
                    name="buildingType"
                    value={state.buildingType}
                    onChange={this.handleChange}
                    optionList={TypeOptions}
                    placeholder="Wybierz rodzaj budynku"
                    selectAll={this.selectAll}
                  />
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Rynek</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipMarket}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <FormSelect
                    id="typeOfMarket"
                    name="typeOfMarket"
                    value={state.typeOfMarket}
                    onChange={this.handleChange}
                    optionList={TypeOfMarketOptions}
                    placeholder="Wybierz rynek"
                  />
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Grupowanie duplikatów</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipDuplicate}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <FormRadio
                    onChange={this.handleChange}
                    value={state.groupDuplicates}
                    name="groupDuplicates"
                  />
                </SectionStyle>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <SectionStyle>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.tooltipGridBox}
                  >
                    <Title>Zerowe lub puste wartości</Title>
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          {toolTipZero}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  </Grid>
                  <FormRadio
                    onChange={this.handleChange}
                    value={state.includeZeroArea}
                    name="includeZeroArea"
                  />
                </SectionStyle>
              </Grid>
            </>
          )}
        </Grid>
        <FilterButtons
          clearFilters={this.clearFilters}
          handleSubmit={this.handleSubmit}
          hideFilters={this.hideFilters}
          isHideFilters={this.state.hideFilters}
        />
        <br />
        <SaveFilterButtons
          saveFilter={this.saveFilter}
          name={this.name}
          handleChange={this.handleChange}
          alertSms={state.alertSms}
          alertEmail={state.alertEmail}
          pushAlert={state.pushAlert}
          filterId={state.filterId}
          tossedUp={state.tossedUp}
          notificationDelay={state.notificationDelay}
          tooltipNotificationDelay={tooltipNotificationDelay}
          tooltipTossedUp={tooltipTossedUp}
          classes={classes}
          showOptions
        />
      </form>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FiltersAdvertisement);
