import React, { useContext } from 'react';
import { FieldArray } from 'react-final-form-arrays';

import Input from './atmos/Input';
import Paragraph from './atmos/Paragraph';

import { ValueContext } from './Wizard';

const FormFirstStep = ({ required, Error }) => {
  const context = useContext(ValueContext);

  return (
    <>
      <div style={{ marginBottom: '30px' }}>
        <Paragraph>Nazwa domeny</Paragraph>
        <Input
          name='domainName'
          component='input'
          type='text'
          placeholder='np. Moja nazwa'
          validate={required}
          variant='outlined'
        />
        <Error name='domainName' />
      </div>
      <div>
        <Paragraph>Typ domeny</Paragraph>
        <Input name='domainType' component='select' validate={required}>
          <option />
          <option value='ogłoszenia'> Ogłoszenia</option>
          <option value='aukcje'>Licytacje</option>
          <option value='spoldzielnie'>Przetargi spółdzielcze</option>
          <option value='komornicze'>Informacje o zadłużonych nieruchomościach</option>
        </Input>
        <Error name='domainType' />
      </div>

      {context.domainType === 'ogłoszenia' && ( 
      <>
        <div>
          <Paragraph>Typ nieruchomości</Paragraph>
          <Input name='propertyType' component='select'>
            <option />
            <option value='Mieszkania'> Mieszkania</option>
            <option value='Działki'>Działki</option>
            <option value='Domy'>Domy</option>
          </Input>
          <Error name='propertyType' />
        </div>
      </>
      )}

      <div style={{ marginBottom: '30px' }}>
        <Paragraph>Domena strony</Paragraph>
        <Input
          name='domain'
          component='input'
          type='text'
          placeholder='np. otodom.pl'
          validate={required}
          variant='outlined'
        />
        <Error name='domain' />
      </div>

      <div style={{ marginBottom: '30px' }}>
        <Paragraph>Link do strony z ogłoszeniami</Paragraph>

        <FieldArray name='offersUrl'>
          {({ fields, ...rest }) => (
            <div>
              {fields.map((name, index) => (
                <div style={{ position: 'relative' }}>
                  <Input
                    name={`${name}.url`}
                    component='input'
                    type='text'
                    placeholder='np. otodom/sprzedaz'
                    validate={required}
                    variant='outlined'
                  />
                  <Error name={`${name}.url`} />
                  <button
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      width: '40px',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '20px',
                      margin: '0 0 0 auto',
                      border: 'none',
                      backgroundColor: 'transparent',
                    }}
                    type='button'
                    onClick={() => fields.remove(index)}
                  >
                    X
                  </button>
                </div>
              ))}
              <button
                type='button'
                className='btn btn-primary mb-2'
                style={{
                  padding: '10px',
                  borderRadius: '50%',
                  width: '50px',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '20px',
                  margin: '10px 0 0 auto',
                }}
                onClick={() => {
                    fields.push({ url: '' })
                }}
              >
                +
              </button>
            </div>
          )}
        </FieldArray>

        <Error name='link' />
      </div>

      <div style={{ marginBottom: '30px' }}>
        <Paragraph>Argument url odpowiadający za przejście na kolejną stronę</Paragraph>
        <Input
          name='urlArgument'
          component='input'
          type='text'
          placeholder='np. ?page=*.*'
          validate={required}
          variant='outlined'
        />
        <Error name='urlArgument' />
        <Paragraph red={true}>
          Pamiętaj, aby zmienić cyfrę odpowiadająca za numer strony na *.*
        </Paragraph>
        <Paragraph red={true}>
          Przykład: www.otodom.pl/sprzedaz/?page=2 na www.otodom.pl/sprzedaz/?page=*.*
        </Paragraph>
      </div>

      <div style={{ marginBottom: '30px' }}>
        <Paragraph>Iterator</Paragraph>
        <Input
          name='iterator'
          component='input'
          type='number'
          placeholder='np. 1'
          validate={required}
          variant='outlined'
          label='Iterator'
        />
        <Error name='iterator' />
      </div>
    </>
  );
};

export default FormFirstStep;
