export const comparePriceAscending = (a, b) => {
  const bandA = a.price;
  const bandB = b.price;

  let comparison = 0;
  if (bandA > bandB) comparison = 1;
  else if (bandA < bandB) comparison = -1;
  return comparison;
};

export const comparePriceDescending = (a, b) => {
  const bandA = a.price;
  const bandB = b.price;

  let comparison = 0;
  if (bandA > bandB) comparison = -1;
  else if (bandA < bandB) comparison = 1;
  return comparison;
};

export const compareDateAscending = (a, b) => {
  const bandA = a.dateAdded;
  const bandB = b.dateAdded;

  let comparison = 0;
  if (bandA > bandB) comparison = 1;
  else if (bandA < bandB) comparison = -1;
  return comparison;
};

export const compareDateDescending = (a, b) => {
  const bandA = a.dateAdded;
  const bandB = b.dateAdded;

  let comparison = 0;
  if (bandA > bandB) comparison = -1;
  else if (bandA < bandB) comparison = 1;
  return comparison;
};
export const compareAuctionDateAscending = (a, b) => {
  const bandA = a.auctionDate;
  const bandB = b.auctionDate;

  let comparison = 0;
  if (bandA > bandB) comparison = 1;
  else if (bandA < bandB) comparison = -1;
  return comparison;
};
export const compareAuctionDateDescending = (a, b) => {
  const bandA = a.auctionDate;
  const bandB = b.auctionDate;

  let comparison = 0;
  if (bandA > bandB) comparison = -1;
  else if (bandA < bandB) comparison = 1;
  return comparison;
};

export const comparePricePerSquareMeterAscending = (a, b) => {
  const bandA = a.pricePerSquareMeter;
  const bandB = b.pricePerSquareMeter;

  let comparison = 0;
  if (bandA > bandB) comparison = 1;
  else if (bandA < bandB) comparison = -1;
  return comparison;
};

export const comparePricePerSquareMeterDescending = (a, b) => {
  const bandA = a.pricePerSquareMeter;
  const bandB = b.pricePerSquareMeter;

  let comparison = 0;
  if (bandA > bandB) comparison = -1;
  else if (bandA < bandB) comparison = 1;
  return comparison;
};

export const compareBailiff = (a, b) => {
  var dateA = a.dateAndSignature.slice(0, 10).split(".");
  var dateB = b.dateAndSignature.slice(0, 10).split(".");
  var bandA = Date.parse(dateA[2] + "." + dateA[1] + "." + dateA[0]);
  var bandB = Date.parse(dateB[2] + "." + dateB[1] + "." + dateB[0]);
  let comparison = 0
  if (bandA > bandB) comparison = -1;
  else if (bandA < bandB) comparison = 1;
  return comparison;
};

export const compareByModifyDate = (a, b) => {
  const modifyA = a.advertisementModifications?.[0]?.date;
  const modifyB = b.advertisementModifications?.[0]?.date;
  // console.log(modifyA);
  // console.log(modifyB);
  if (!modifyA) return 1;
  if (!modifyB) return -1;
  const diffA = Math.abs(new Date(a.dateAdded) - new Date(modifyA));
  const diffB = Math.abs(new Date(b.dateAdded) - new Date(modifyB));

  if (diffA > diffB) return -1;
  if (diffB > diffA) return 1;

  return 0;
}