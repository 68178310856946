import styled, { css } from 'styled-components';

const Button = styled.button`
  display: inline-block;
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 0.4375rem 1.25rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.25rem;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  background-color: #2550aa;
  border: 1px solid #2550aa;
  transition: 0.3s;
  text-transform: capitalize;
  min-height: ${({ height }) => (height === 'big' ? '40px' : 'auto')};
  width: ${({ width }) => (width === 'small' ? 'auto' : '100%')} !important;

  ${({ notActive }) =>
    notActive &&
    css`
      background: white;
      color: #0e2e60;
    `}

  &.disabled,
  &.btn[disabled],
  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }

  :hover {
    transform: translateY(-5%);
  }
`;

export default Button;
