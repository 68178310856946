import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import { withStyles } from "@material-ui/core/styles";
import { FormAutoCompleteFreeSolo } from '../InputFilters';
import { CityOptions } from '../functions/cities';
import addAlertToDB from '../functions/addAlert';
import { API, redirect } from '../../API';
import Button from '../atom/Button';

const appear = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Wrapper = styled(Grid)`
  width: 20vw;
  animation: ${appear} 0.1s;
`;

const SectionStyle = styled.div`
  margin: 5px 5px;
  padding: 10px 10px 15px;
  overflow: hidden;
  box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 5%);
  background-color: white;
  border-radius: 5px;
  border: #e0e0e0 1px solid;
`;


const FilterCityContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DeleteCity = styled(Grid)`
  margin: 0 10px;
  cursor: pointer;
`;


const styles = theme => ({
  tooltipIcon: {
    margin: theme.spacing(0.5),
  },
  tooltipGridBox: {
    marginBottom: '0.6rem',
  },
});
class FiltersBailiff extends Component {
  state = {
    savedCities: [],
    cityOptions: [],
    citiesAsFilter: [],
    activeFilter: {
      alertEmail: null,
      alertSms: null,
      pushAlert: null,
      city: '',
      filterId: null,
      cityError: false,
    },
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
    const retrievedObject = localStorage.getItem('bailiff');
    const state = this.state;
    if (retrievedObject) {
      const data = JSON.parse(retrievedObject);
      state.activeFilter.city = data.city;
      this.setState(state);
    }
    this.getFilter(state.activeFilter.city);
  }

  onUnload = () => {
    localStorage.setItem(
      'bailiff',
      JSON.stringify({ city: this.state.activeFilter.city })
    );
  };

  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', this.onUnload);
    this.onUnload();
  };


  deleteCityFilter = (activeFilter) => {
    const { city, filterId } = activeFilter;
    const newSavedCities = this.state.savedCities.filter((item) => item.filterId !== filterId);
    const newCitiesAsFilter = this.state.citiesAsFilter.filter((item) => item !== city);

    axios.delete(`${API}bailiffNotice/filter/delete/${filterId}/`);

    this.setState({
      savedCities: newSavedCities,
      citiesAsFilter: newCitiesAsFilter,
      activeFilter: {
        alertEmail: null,
        alertSms: null,
        pushAlert: null,
        city: '',
        filterId: null,
        cityError: false,
      },
    })
  }

  handleChange = (fieldName, value) => {
    const state = this.state;
    state.activeFilter.city = value;
    state.activeFilter.cityError = false;
    this.setState(state);
    this.getFilter(value);
  };

  getFilter = city => {
    axios({
      method: 'get',
      url: `${API}bailiffNotice/filter/all`,
    })
      .then(resp => {
        const cityOptions = new Set([
          ...resp.data.map(el => el.city),
          ...CityOptions,
        ]);
        const details = resp?.data?.find(el => el.city === city);
        if (details) {
          this.setState({
            citiesAsFilter: resp.data.map(({ city }) => city),
            savedCities: resp.data,
            cityOptions: [...cityOptions],
            activeFilter: details,
          });
        } else {
          this.setState({
            citiesAsFilter: resp.data.map(({ city }) => city),
            savedCities: resp.data,
            cityOptions: [...cityOptions],
            activeFilter: {
              alertEmail: false,
              alertSms: false,
              pushAlert: false,
              city: city,
              filterId: null,
              cityError: false,
            },
          });
        }
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          window.location = redirect;
        } else {
          const cityOptions = new Set([...CityOptions]);
          this.setState({ cityOptions: [...cityOptions] });
        }
      });
  };

  alertSms = e => {
    e.preventDefault();
    const { city, alertSms, filterId } = this.state.activeFilter;
    const method = filterId ? 'PUT' : 'POST';
    const value = alertSms === null || alertSms === false ? true : false;
    addAlertToDB('sms', city, value, method, 'bailiffNotice', this.getFilter);
  };

  alertEmail = e => {
    e.preventDefault();
    const { city, alertEmail, filterId } = this.state.activeFilter;
    const method = filterId ? 'PUT' : 'POST';
    const value = alertEmail === null || alertEmail === false ? true : false;
    addAlertToDB('email', city, value, method, 'bailiffNotice', this.getFilter);
  };

  alertPush = e => {
    e.preventDefault();
    const { city, pushAlert, filterId } = this.state.activeFilter;
    const method = filterId ? 'PUT' : 'POST';
    const value = pushAlert === null || pushAlert === false ? true : false;
    addAlertToDB('push', city, value, method, 'bailiffNotice', this.getFilter);
  };

  submit = e => {
    document.activeElement.blur();
    const { city } = this.state.activeFilter;
    if (!city || !city.replace(/\s/g, '').length) {
      const state = this.state;
      state.activeFilter.cityError = true;
      this.setState(state);
    } else {
      this.props.handleSubmit(e, this.state.activeFilter.city);
    }
    e.preventDefault();
  };

  render() {
    const { activeFilter, savedCities, citiesAsFilter  } = this.state;
    const { city, cityError } = this.state.activeFilter;
    const { classes } = this.props;

    const tooltipDataListBaliff = this.props.tooltipDataListBaliff;
    let toolTipCity = tooltipDataListBaliff.filter(item => item.field === 'CITY')[0]?.content;
    return (
      <form onSubmit={this.submit}>
        <Grid container alignItems='center' spacing={3}>
          <Wrapper item xs={12} sm={6} lg={4}>
            <SectionStyle>
              <Grid item container direction="row" alignItems="center" className={classes.tooltipGridBox}>
                <p style={{ fontSize: '1.4rem', marginBottom: '0' }}>Miasto</p>
                <Tooltip classes={{ tooltip: classes.customWidth }} enterTouchDelay={0} title={<span style={{ fontSize: '1.2rem', lineHeight: '1.2', fontFamily: 'Inter', }}>{toolTipCity}</span>} arrow placement="top">
                  <InfoSharpIcon className={classes.tooltipIcon} />
                </Tooltip>
              </Grid>
              <FilterCityContainer>
                <FormAutoCompleteFreeSolo
                  id='cities'
                  name='city'
                  value={city}
                  optionList={citiesAsFilter}
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  error={cityError}
                  placeholder={"Wybierz miasto"}
                  savedCities={savedCities}
                />
                <DeleteCity onClick={() => this.deleteCityFilter(activeFilter)}>Usuń</DeleteCity>
              </FilterCityContainer>

            </SectionStyle>
          </Wrapper>
          <Grid
            container
            spacing={1}
            style={{
              flexGrow: 1,
              marginTop: 3,
              // marginLeft: '20px',
            }}
            item
            xs={12}
            sm={6}
            lg={8}
          >
            <Grid container item xs={6} sm={3}>
              <Button height='big' type='submit' onClick={this.submit}>
                Filtruj
              </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                type='button'
                height='big'
                notActive={!activeFilter.alertSms ? true : false}
                onClick={this.alertSms}
                disabled={
                  !city || !city.replace(/\s/g, '').length ? true : false
                }
              >
                {activeFilter.alertSms
                  ? 'Alert SMS aktywny'
                  : 'Aktywuj alert SMS'}
              </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                type='button'
                height='big'
                notActive={!activeFilter.alertEmail ? true : false}
                onClick={this.alertEmail}
                disabled={
                  !city || !city.replace(/\s/g, '').length ? true : false
                }
              >
                {activeFilter.alertEmail
                  ? 'Alert email aktywny'
                  : 'Aktywuj alert email'}
              </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                type='button'
                height='big'
                notActive={!activeFilter.pushAlert ? true : false}
                onClick={this.alertPush}
                disabled={
                  !city || !city.replace(/\s/g, '').length ? true : false
                }
              >
                {activeFilter.pushAlert
                  ? 'Alert push aktywny'
                  : 'Aktywuj alert push'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}


export default withStyles(styles, { withTheme: true })(FiltersBailiff);

