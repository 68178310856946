export const closeNav = () => {
  if (window.innerWidth < 991) {
    document.querySelector('.overlay').classList.remove('show');
    document.querySelector('#container').classList.remove('sbar-open');
    document.querySelector('#container').classList.add('sidebar-closed');
    document.querySelector('html').classList.remove('sidebar-noneoverflow');
    document.querySelector('body').classList.remove('sidebar-noneoverflow');
  }
  window.scrollTo(0, 0);
};

//classes from cork - app.js
