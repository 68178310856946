import React from 'react';
import styled from 'styled-components';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  color: ${({ theme }) => theme.text};
  margin: 50px auto;

  @media (min-width: 490px) {
    flex-direction: row;
  }
`;

const List = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
`;

const ListElement = styled.li`
  font-size: 14px;
  margin: 0 3px;
`;

const Button = styled.button`
  color: ${({ theme, active }) => (active ? theme.light : theme.text)};
  font-weight: ${({ active }) => (active ? 800 : 'normal')};
  border-radius: 5px;
  min-width: 30px;
  height: 30px;
  background: ${({ theme, active }) => (active ? theme.main : 'white')};
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  margin: 0 2px;

  position: relative;

  &:hover:after {
    display: ${({ hover }) => (hover ? 'block' : 'none')};
    position: absolute;
    top: 40px;
    right: 0;
    content: '${({ hover }) => hover}';
    min-height: 20px;
    font-size: 12px;
    min-width: 120px;
    background-color: #fff;
    border: 0.5px solid #333;
    opacity: 0.5;
  }
`;

const Select = styled(NativeSelect)`
  height: 30px;
  background-color: white;
  padding: 0 10px;
  font-size: 14px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;

  ::before,
  ::after {
    display: none;
  }

  .MuiInputBase-input:focus {
    background-color: transparent !important;
  }
`;

const ListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;

  @media (min-width: 490px) {
    margin-top: 0;
  }
`;

const Pagination = ({
  amountSites,
  currentPage,
  setCurrentPage,
  handleChangeRows,
  amountRows,
}) => {
  let numbers = [];

  for (let i = 0; i < amountSites; i++) {
    numbers.push(
      <ListElement key={i}>
        <Button active={i === currentPage} onClick={() => setCurrentPage(i)}>
          {i + 1}
        </Button>
      </ListElement>
    );
  }

  if (currentPage === 0) {
    numbers = numbers.slice(0, 7);
  } else if (currentPage === amountSites) {
    numbers = numbers.slice(amountSites - 7, amountSites);
  } else if (amountSites - currentPage === 1 && amountSites > 5) {
    numbers = numbers.slice(amountSites - 5, amountSites);
  } else if (amountSites - currentPage === 2 && amountSites > 3) {
    numbers = numbers.slice(amountSites - 4, amountSites + 2);
  } else {
    numbers = numbers.slice(currentPage - 1, currentPage + 5);
  }

  return (
    <Wrapper>
      <FormControl style={{marginLeft: 'auto'}}>
        <Select value={amountRows} onChange={handleChangeRows} name="rows">
          <option value={25}>25 wierszy</option>
          <option value={50}>50 wierszy</option>
          <option value={100}>100 wierszy</option>
          <option value={200}>200 wierszy</option>
          <option value={500}>500 wierszy</option>
        </Select>
      </FormControl>
      <ListContainer>
        {currentPage !== 0 && (
          <>
            <Button onClick={() => setCurrentPage(0)} hover="pierwsza strona">
              <FirstPage style={{ fontSize: '20px' }} />
            </Button>
            <Button
              onClick={() => setCurrentPage(currentPage - 1)}
              hover="poprzednia strona"
            >
              <ChevronLeft style={{ fontSize: '20px' }} />
            </Button>
          </>
        )}
        <List>{numbers}</List>
        {currentPage !== amountSites - 1 && (
          <>
            <Button
              onClick={() => setCurrentPage(currentPage + 1)}
              hover="następna strona"
            >
              <ChevronRight style={{ fontSize: '20px' }} />
            </Button>
            <Button
              onClick={() => setCurrentPage(amountSites - 1)}
              hover="ostatnia strona"
            >
              <LastPage style={{ fontSize: '20px' }} />
            </Button>
          </>
        )}
      </ListContainer>
    </Wrapper>
  );
};

export default Pagination;
