import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

import Notes from '../Reactions/Note';

import Adown from '@material-ui/icons/ArrowDropDown';
import styled, { keyframes } from 'styled-components';
import gsap from 'gsap';

import Note from '@material-ui/icons/SpeakerNotesOutlined';
import Heart from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Button from '../atom/Button';

const appear = keyframes`
  0% {
    top: -35px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
`;
const NotesWrapper = styled(Grid)`
  text-align: center;
  padding: 20px 3vw 0;
  width: 100%;
`;

const Circle = styled(Grid)`
  border-radius: 50%;
  height: 35px;
  width: 35px;
  padding: 5px;
  box-shadow: 0 3px 5px 4px #b7b2b22e;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  background-color: white;
  cursor: pointer;

  &:nth-child(1) {
    margin-right: 30px;
  }

  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 3px 3px 8px 3px #ccc;
  }

  @media (max-width: 1280px) {
    height: 30px;
    width: 30px;
  }
`;

const Arrow = styled(Adown)`
  font-size: 15px;
  transform: ${({ up }) => (up ? 'rotate(180deg)' : null)};
`;

const AdditionalDetails = styled(Grid)`
  animation: ${appear} 0.4s;
`;

const TypeSmall = styled.div`
  border: solid 1px lightgray;
  text-align: center;
  width: 40%;
  padding: 5px 0px;
  float: right;
  clear: both;
  margin-right: 1vw;
`;

const SingleCooperative = ({ data, windowWidth, setFavourite, setNote }) => {
  const [addNoteOpen, toggleaddNote] = useState(false);
  const [detailsOpen, toggleDetailsOpen] = useState(false);

  const likeStatus = data.reactions[0] && data.reactions[0].likeStatus;
  const noteText = data.reactions[0] && data.reactions[0].note;

  const isClient = typeof window === 'object';
  const getWidth = () => (isClient ? window.innerWidth : undefined);

  const [windowWith, setWindowWidth] = useState(getWidth);

  useEffect(() => {
    if (!isClient) return false;

    const handleResize = () => setWindowWidth(getWidth());

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let additionalDetailsContainer;
  let detaildContainer;

  const tl2 = gsap.timeline({
    defaults: {
      ease: 'power3.inOut',
    },
  });

  tl2.set(additionalDetailsContainer, { autoAlpha: 0 });
  const toggleContainerDetails = () => {
    if (!detailsOpen) {
      toggleDetailsOpen(true);
      tl2.fromTo(
        detaildContainer,
        {
          maxHeight: '300px',
        },
        {
          maxHeight: '1000px',
          duration: 0.3,
        }
      );
    } else {
      tl2
        .fromTo(
          additionalDetailsContainer,
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.2,
          }
        )
        .to(detaildContainer, {
          maxHeight: '500px',
          duration: 0.3,
        });
      setTimeout(() => toggleDetailsOpen(false), 400);
    }
  };

  const circles = (
    <Grid
      container
      item
      xs={12}
      lg={6}
      justify='flex-end'
      alignItems='center'
      style={{ marginBottom: `${windowWidth < 850 ? '10px' : ''}` }}
    >
      <Circle onClick={() => setFavourite(data.bailiffNoticeId)}>
        {likeStatus ? (
          <FavoriteIcon
            style={{
              color: '#FF0000',
            }}
          />
        ) : (
          <Heart
            style={{
              color: '#FF0000',
            }}
          />
        )}
      </Circle>
      <Circle
        onClick={() => {
          toggleaddNote(!addNoteOpen);
        }}
      >
        <Note fontSize={noteText?.length > 0 ? 'large' : 'small'} />
      </Circle>
    </Grid>
  );

  const GeneralTd = ({ children }) => (
    <td style={{ paddingTop: windowWidth < 850 && 0 }}>{children}</td>
  );
  const GeneralTdAdress = ({ children }) => (
    <td style={{ paddingTop: windowWidth < 850 && 0, maxWidth:'30em' }}>{children}</td>
  );
  return (
    <>
      {windowWidth < 850 && (
        <tr>
          <td colSpan={4} style={{ paddingBottom: 0 }}>
            <TypeSmall>{data.type || '-'}</TypeSmall>
          </td>
        </tr>
      )}
      <tr key={data.cooperativeId}>
        <GeneralTd>
          <div style={{ padding: '10px' }} class='td-content customer-name'>
            {data?.dateAndSignature?.slice(0, 11)}
          </div>
        </GeneralTd>
        <GeneralTdAdress>
          <div class='td-content customer-name'>
            {data.address || '-'}
          </div>
        </GeneralTdAdress>
        {windowWidth > 850 && (
          <GeneralTd>
            <div class='td-content product-brand'>
              {data.type || '-'}
            </div>
          </GeneralTd>
        )}
        <GeneralTd>
          <div style={{ padding: '10px' }} class='td-content customer-name'>
            {data.kwNumber}
          </div>
        </GeneralTd>
        <td>
          <div class='td-content'>
            <Grid
              container
              spacing={2}
              justify={`${windowWidth > 850 && 'flex-end'}`}
            >
              {windowWidth > 850 && circles}
              <Grid item xs={12} lg={6}>
                {windowWidth > 850 ? (
                  <Button
                    style={{ minWidth: '120px' }}
                    onClick={() => toggleContainerDetails()}
                    width='small'
                  >
                    Szczegóły
                    <Arrow up={detailsOpen ? true : false} />
                  </Button>
                ) : (
                  <Arrow
                    up={detailsOpen ? true : false}
                    style={{ fontSize: 25 }}
                    onClick={() => toggleContainerDetails()}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </td>
      </tr>
      <tr>
        <td
          colSpan={5}
          style={{
            padding: 0,
          }}
        >
          {detailsOpen && (
            <AdditionalDetails
              container
              xs={12}
              ref={(el) => (additionalDetailsContainer = el)}
            >
              <div
                container
                alignItems='center'
                style={{
                  width: '100%',
                  padding: `${windowWith < 850 ? '0 3vw' : '0 8vw'}`,
                }}
              >
                <Grid
                  container
                  justify={`${windowWidth < 850 ? 'space-around' : 'center'}`}
                  alignItems='center'
                >
                  <Grid item> Szczegóły </Grid>
                  <Grid item> {windowWidth < 850 && circles}</Grid>
                </Grid>
                <div
                  className='infobox-1'
                  style={{
                    width: '100%',
                  }}
                >
                  <p
                    className='info-text'
                    style={{
                      textAlign: 'left',
                      color: '#6F747D',
                    }}
                  >
                    {data.description}
                  </p>
                </div>
              </div>
            </AdditionalDetails>
          )}
          {addNoteOpen && (
            <NotesWrapper>
              <Notes
                note={noteText}
                id={data.bailiffNoticeId}
                setNoteText={noteText}
                type='bailiffNotice'
                setNoteToState={setNote}
              />
            </NotesWrapper>
          )}
        </td>
      </tr>
    </>
  );
};

export default SingleCooperative;
