import React, { Component, memo, Suspense } from 'react';
import axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie';


import FiltrButton from '../components/FilterButton';
import AnalysisList from '../components/analysis/AnalysisList';
import { API, redirect } from '../API';
import { activeNotification } from '../components/functions/activeNotification';



import Indicator from '../components/atom/Indicator';

const Content = memo(
  ({
    areaAnalysisList,
    timeAnalysisList,
    trendResult,
    filters
  }) => {
    return (
      <>
    
        <AnalysisList
          timeAnalysiList={timeAnalysisList}
          areaAnalysisList={areaAnalysisList}
          trendResult={trendResult}
          filters={filters}
        />
      </>
    );
  }
);

class AnalysisPage extends Component {
  state = {
    timeAnalysisList: [],
    areaAnalysisList: [],
    trendResult: null,
    isLoading: false,
    filters: React.createRef(),
    tooltipDataList: [],
  };

  componentDidMount() {
    axios({
      method: 'get',
      url: `${API}notice/analysis/filter/directory`,
    })
      .then(resp => {
        const tooltipData = resp.data;

        this.setState({
          tooltipDataList: tooltipData,
        });
      })
  }

  getData = (data, city) => {
    this.setState({
      isLoading: true,
      sortType: '',
      timeAnalysiList: [],
      areaAnalysisList: [],
      filters: null,
      trendResult: null,
    });

    for (const key of Object.keys(data)) {
      if (data[key] === null || data[key] === '') {
        delete data[key];
      }
    }

    const token = Cookies.get('MonitorApiToken');

    axios({
      method: 'get',
      url: `${API}notice/analysis/filter/findByFilter/${city}/`,
      params: data,
      headers: {
        Authorization: 'Bearer ' + token,
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    })
      .then(resp => {
        if (resp.data) {
          if (resp.data.length === 0) {
            this.setState({ isLoading: false });
            activeNotification(
              'Nie znaleziono wyników',
              'Zmień wartości wyszukiwania',
              'warning'
            );
            this.setState({
              timeAnalysisList: [],
              areaAnalysisList: [],
              trendResult: null
            });
          } else {
            const results = resp.data;
            const areaAnalysisList = results["analysisAreaRows"];
            const timeAnalysisList =  results["analysisTimeRows"];
            const trendResult = results["trendResult"];
            const filters = [data,city];
            this.setState({
              areaAnalysisList: areaAnalysisList,
              timeAnalysisList: timeAnalysisList,
              trendResult: trendResult,
              filters: filters,
              isLoading: false,
            });

            window.scrollTo(0, this.state.filters.current.offsetTop + 20);
          }
        } else {
          activeNotification(
            'Nie znaleziono wyników',
            'Zmień wartości wyszukiwania',
            'warning'
          );
        }

        this.setState({
          isLoading: false,
        });
      })

      .catch(err => {
        if (err?.response?.status === 401) {
          window.location = redirect;
        } else {
          console.info(err)
          this.setState({ isLoading: false });
          activeNotification(
            'Coś poszło nie tak!',
            'Spróbuj ponownie',
            'danger'
          );
        }
      });
  };


 
  render() {
    
    const {
      areaAnalysisList,
      timeAnalysisList,
      trendResult,
      isLoading,
      filters,
      tooltipDataList
    } = this.state;

    return (
      <div>
        {isLoading && <Indicator />}
        <FiltrButton handleSubmit={this.getData} type='analysis' tooltipDataList={tooltipDataList} />
        <hr
          style={{ backgroundColor: '#edecf2', color: '#edecf2', height: 2 }}
          ref={this.state.filters}
        />

        {(areaAnalysisList.length > 0 || timeAnalysisList.length > 0 || trendResult !== null ) && (
          <Content
          areaAnalysisList={areaAnalysisList}
          timeAnalysisList={timeAnalysisList}
          trendResult={trendResult}
          filters={filters}
           
          />
        )}
      </div>
    );
  }
}

export default AnalysisPage;
