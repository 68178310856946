import React, { useState, useLayoutEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  div {
    /* position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 9.5em 25px -50px 0;
    border-radius: 5px;
    height: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 50px;
    padding-left: ${({ fromLeft }) => fromLeft - 270}px;
    background-color: #f1f2f3; */
    /* position: fixed; */
    /* top: 0px; */
    /* left: 0px; */
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    /* margin: 9.5em 25px -50px 0px; */
    border-radius: 5px;
    height: 20px;
    padding: 30px 50px 30px 49px;
    background-color: rgb(241, 242, 243);

    a {
      padding: 30px;
    }

    @media (max-width: 990px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1029;
      box-shadow: rgb(85 85 85 / 8%) 0px 4px 6px 0px;
    }
  }
`;

const Header = () => {
  const isClient = typeof window === 'object';

  const getValueFromLeft = () =>
    document.querySelector('#filters')?.getBoundingClientRect().left;

  let [fromLeft, setFromLeft] = useState(getValueFromLeft());

  useLayoutEffect(() => {
    if (!isClient) return false;
    window.onload = setFromLeft(getValueFromLeft());
    setTimeout(() => setFromLeft(getValueFromLeft(), 1000));
    const handleResize = () => {
      const left = getValueFromLeft();
      setFromLeft(left);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className="sub-header-container">
        <Wrapper fromLeft={fromLeft}>
          <div className="header-container-my">
            <a
              href="javascript:void(0);"
              class="sidebarCollapse"
              data-placement="bottom"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
              <span style={{ fontSize: '16px', paddingLeft: '14px' }}>
                {' '}
                MODUŁY{' '}
              </span>
            </a>{' '}
          </div>
        </Wrapper>
      </div>
    </>
  );
};

export default Header;
