import React, { useContext } from 'react';

import SingleElement from './SingleElement';

import Paragraph from '../atmos/Paragraph';

import { ValueContext } from '../Wizard';

const FormFirstStep = ({ required, Error }) => {
  const context = useContext(ValueContext);

  return (
    <>
      <Paragraph>Dane wyciągane z szczegółów ogłoszenia</Paragraph>
      {context.domainType === 'ogłoszenia' && ( 
      <>
        <SingleElement name='title' text='Tytuł' required={required} Error={Error} />
        <SingleElement name='web-id' text='Id ogłoszenia na stronie' required={required} Error={Error} />
        <SingleElement name='price' text='Cena' required={required} Error={Error} />
        <SingleElement name='price/m2' text='Cena za m2' required={required} Error={Error} />
        <SingleElement name='map' text='Miasto' required={required} Error={Error} />
        <SingleElement name='area' text='Powierzchnia' required={required} Error={Error} />
        <SingleElement name='image' text='Zdjęcie' required={required} Error={Error} />
        <SingleElement name='other' text='Inne' required={required} Error={Error} />
        <SingleElement name='description' text='Opis' required={required} Error={Error} />
        <SingleElement name='rooms' text='Pokoje' required={required} Error={Error} />
        <SingleElement name='construction-year' text='Rok budowy' required={required} Error={Error} />
        <SingleElement name='type' text='Typ' required={required} Error={Error} />
        <SingleElement name='building-type' text='Typ budynku' required={required} Error={Error} />
        <SingleElement name='offerFrom' text='Oferta od' required={required} Error={Error} />
        <SingleElement name='floor' text='Piętra' required={required} Error={Error} />
        <SingleElement name='aftermarket' text='Rodzaj rynku' required={required} Error={Error} />
        <SingleElement name='features' text='Słowa kluczowe' required={required} Error={Error} />
        <SingleElement name='property-form' text='Rynek' required={required} Error={Error} />
      </>
      )}
      {context.domainType === 'aukcje' && (
        <>
          <SingleElement name='web-id' text='Id ogłoszenia na stronie' required={required} Error={Error} />
          <SingleElement name='title' text='Tytuł' required={required} Error={Error} />
          <SingleElement name='price' text='Cena' required={required} Error={Error} />
          <SingleElement name='price/m2' text='Cena za m2' required={required} Error={Error} />
          <SingleElement name='map' text='Miasto' required={required} Error={Error} />
          <SingleElement name='area' text='Powierzchnia' required={required} Error={Error} />
          <SingleElement name='auctionType' text='Typ aukcji' required={required} Error={Error} />
          <SingleElement name='description' text='Opis' required={required} Error={Error} />
          <SingleElement name='category' text='Kategoria' required={required} Error={Error} />
          <SingleElement
            name='registryNumber'
            text='Numer księgi wieczystej'
            required={required}
            Error={Error}
          />
          <SingleElement name='saleMode' text='Typ sprzedaży' required={required} Error={Error} />
          <SingleElement name='image' text='Zdjęcie' required={required} Error={Error} />
          <SingleElement name='other' text='Inne' required={required} Error={Error} />
          <SingleElement name='date' text='Data dodania' required={required} Error={Error} />
          <SingleElement name='auction-date' text='Data ukcji' required={required} Error={Error} />
        </>
      )}
      {context.domainType === 'spoldzielnie' && (
          <>
            <SingleElement name='title' text='Tytuł' required={required} Error={Error} />
            <SingleElement name='description' text='Opis' required={required} Error={Error} />
            <SingleElement name='map' text='Adres' required={required} Error={Error} />
            <SingleElement name='date' text='Data dodania' required={required} Error={Error} />
          </>
      )}
      {context.domainType === 'komornicze' && (
          <>
            <SingleElement name='name-chambers' text='Office name' required={required} Error={Error} />
            <SingleElement name='description' text='Opis' required={required} Error={Error} />
            <SingleElement name='date-signature' text='Data i sygnatura' required={required} Error={Error} />
            <SingleElement name='map' text='Adres' required={required} Error={Error} />
            <SingleElement name='sq-number' text='Numer księgi wiecznystej' required={required} Error={Error} />
            <SingleElement name='type' text='Typ' required={required} Error={Error} />
          </>
      )}
    </>
  );
};

export default FormFirstStep;
