import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import axios from 'axios';
import { activeNotification } from '../functions/activeNotification';
import Indicator from '../atom/Indicator';

import { API, redirect } from '../../API';

export const ValueContext = React.createContext();

export default class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues || {},
      isLoading: false,
      successMessage: null,
      checkPage: true,
    };
  }
  next = (values) => {
    this.setState((state) => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values,
    }));
  };

  previous = () =>
    this.setState((state) => ({
      page: Math.max(state.page - 1, 0),
    }));

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = (values) => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = async (values) => {
    if (this.state.page === 4) this.setState({ isLoading: true });

    const { children } = this.props;
    const { page, checkPage } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;

    if (isLastPage) {
      console.log(values);
      try {
        const resp = await axios({
          method: 'post',
          url: `${API}api/admin/domain`,
          data: { ...values, checkPage },
        });
        if (resp) {
          activeNotification('Success ', 'Udało się dodać ', 'success');
          this.setState({ isLoading: false });
        }
      } catch (e) {
        if (e?.response?.status === 401) {
          window.location = redirect;
        } else {
          if (e.response) {
            activeNotification('test błędny', 'spróbuj jeszcze raz', 'danger');
            this.setState({ isLoading: false });
          }
        }
      }
    } else {
      this.next(values);
    }
  };

  handleTest = (values) => {
    this.setState({ isLoading: true });
    console.log(values);

    let testField = null;

    switch (this.state.page) {
      case 0:
        testField = 'firstStep';
        break;
      case 1:
        testField = 'link';
        break;

      case 3:
        testField = 'date';
        break;

      default:
        break;
    }
    const data = JSON.stringify({ testField, ...values });

    axios
      .post(`${API}api/admin/domain`, {
        data,
      })
      .then((resp) => {
        console.log(resp);

        activeNotification('test OK', 'Test pozytywny', 'success');

        this.setState({ isLoading: false, successMessage: resp.data });
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          window.location = redirect;
        } else {
          if (err.response) {
            activeNotification('test błędny', 'spróbuj jeszcze raz', 'danger');
          }
          this.setState({ isLoading: false });
        }
      });
  };

  render() {
    const { children } = this.props;
    const { page, values, successMessage, isLoading, checkPage } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    return (
      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <ValueContext.Provider value={values}>
              {activePage}
            </ValueContext.Provider>
            {isLoading && <Indicator />}
            {successMessage && <p>{successMessage}</p>}
            <div className="buttons">
              {!isLastPage && (
                <button
                  className="btn btn-success mb-2"
                  type="button"
                  onClick={() => this.handleTest(values)}
                  style={{ display: 'block', margin: '20px', width: '150px' }}
                >
                  Test
                </button>
              )}
              {page > 0 && (
                <button
                  className="btn btn-primary mb-2"
                  type="button"
                  onClick={this.previous}
                  style={{ margin: '20px', width: '100px' }}
                >
                  « Previous
                </button>
              )}
              {!isLastPage && (
                <button
                  className="btn btn-primary mb-2"
                  style={{ margin: '20px', width: '100px' }}
                >
                  Next »
                </button>
              )}
              {isLastPage && (
                <button
                  className="btn btn-primary mb-2"
                  type="submit"
                  disabled={submitting}
                  style={{ margin: '20px', width: '200px' }}
                >
                  Submit
                </button>
              )}
              {isLastPage && (
                <label style={{ transform: 'translateY(50%)', color: '#515365' }}>
                  <input
                    type="checkbox"
                    checked={this.state.checkPage}
                    onClick={() =>
                      this.setState((prev) => ({
                        ...prev,
                        checkPage: !prev.checkPage,
                      }))
                    }
                  />{' '}
                  Sprawdź stronę
                </label>
              )}
            </div>

            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      </Form>
    );
  }
}
