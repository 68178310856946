import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { API } from '../../API';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9);

  display: flex;
  justify-content: content;
  align-items: center;
  z-index: 10000;
`;

const Chooses = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid black;
  min-height: 70vh;
  min-width: 50vw;
  background-color: white;
  max-height: 100vh;

  overflow-y: auto;
  overflow-x: hidden;
`;

const SinglePage = styled.li`
  padding: 5px 40px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
  border-bottom: 1px solid grey;
  color: black;

  :hover {
    transform: translateX(15px);
  }
`;

const FormZeroStep = ({ setInitialValues, setIsActiveChooseForm }) => {
  const [listOfPages, setListOfPages] = useState([]);
  console.log(setInitialValues);
  useEffect(() => {
    const getPages = async () => {
      const resp = await axios({
        method: 'get',
        url: `${API}api/admin/domain`,
      });
      setListOfPages(resp.data);
    };
    getPages();
  }, []);

  const getDetailsOfPage = async path => {
    const resp = await axios({
      method: 'get',
      url: `${API}api/admin/domain/${path}`,
    });

    console.log(resp);
    setInitialValues(resp.data);
    setIsActiveChooseForm(false);
  };

  const pages = listOfPages.map(el => (
    <SinglePage key={el.name} onClick={() => getDetailsOfPage(el.path)}>
      {el.name}
    </SinglePage>
  ));

  return (
    <Wrapper>
      <Chooses>
        <ul>
          <SinglePage
            onClick={() => setIsActiveChooseForm(false)}
            style={{ fontSize: '20px', fontWeight: '800' }}
          >
            NOWA STRONA
          </SinglePage>
          {pages}
        </ul>
      </Chooses>
    </Wrapper>
  );
};

export default FormZeroStep;
