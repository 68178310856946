import { FormDateInput } from './InputFilters';
import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

import { SectionStyle } from './FilterSections';



const OfertDate = ({ to, from, changeDate, errorFrom, errorTo, tooltipValueAdv }) => {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [dateFromError, setDateFromError] = useState(errorFrom && 'błędne dane');
  const [dateToError, setDateToError] = useState(errorTo && 'błędne dane');

  useEffect(() => {
    setDateFrom(from);
    setDateTo(to);
  }, [to, from]);

  useEffect(() => {
    setDateFromError(errorFrom && 'błędne dane');
    setDateToError(errorTo && 'błędne dane');
  }, [errorFrom, errorTo]);

  const isError = date => {
    const now = new Date();
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    return (
      (date == 'Invalid Date' || dateFrom > dateTo || date >= tomorrow) &&
      !(dateFrom == null || dateTo == null)
    );
  };

  const onBlur = () => {
    changeParams(dateFrom, dateTo);
  };

  const onAcceptFrom = d => {
    changeParams(d, dateTo);
  };

  const onAcceptTo = d => {
    changeParams(dateFrom, d);
  };

  const changeParams = (from, to) => {
    const offertDate = {
      from,
      to,
      errorFrom: isError(from),
      errorTo: isError(to),
    };
    changeDate(offertDate);
  };

  const onErrorFrom = error => {
    if (error != dateFromError && !errorFrom) {
      setDateFromError(error);
    }
  };

  const onErrorTo = error => {
    if (error != dateToError && !errorTo) {
      setDateToError(error);
    }
  };
  return (
    <Grid container spacing={1} style={{ flexGrow: 1 }}>
      <Grid item xs={6}>
        <FormDateInput
          id='dateFrom'
          name='date'
          value={dateFrom}
          onChange={date => setDateFrom(date)}
          maxDate={dateTo}
          onBlur={onBlur}
          onAccept={onAcceptFrom}
          error={dateFromError}
          onError={onErrorFrom}
        />
      </Grid>
      <Grid item xs={6}>
        <FormDateInput
          id='dateTo'
          name='date'
          value={dateTo}
          onChange={date => setDateTo(date)}
          // minDate={dateFrom}
          onBlur={onBlur}
          onAccept={onAcceptTo}
          error={dateToError}
          onError={onErrorTo}
        />
      </Grid>
    </Grid>

  );
};

export default OfertDate;
