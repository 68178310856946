import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Grid } from '@material-ui/core';
import axios from 'axios';
import { API, redirect } from '../../API';
import gsap from 'gsap';

import Notes from '../Reactions/Note';
import { activeNotification } from '../functions/activeNotification';

import DefaultImg from '../../assets/defPicture.jpg';

import Adown from '@material-ui/icons/ArrowDropDown';
import ImgLink from '../../assets/External_link_font_awesome.svg.png';
import Note from '@material-ui/icons/SpeakerNotesOutlined';
import Heart from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';

import Button from '../atom/Button';

const appear = keyframes`
  0% {
    top: -35px;
    opacity: 0;
  }

  30% {
    top: -35px;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
`;

const AdditionalDetails = styled(Grid)`
  animation: ${appear} 0.4s;
`;

const DetailsWrapper = styled(Grid)`
  position: relative;
  padding: 5px 20px 0px;
  width: 100%;
  background-color: white;
  margin-top: 60px;
  color: ${({ theme }) => theme.text};
  border-radius: 10px;
  box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
    0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
  margin: 60px auto 5px;

  &:nth-child(1) {
    margin-top: 20px;
  }
`;

const Arrow = styled(Adown)`
  font-size: 15px;
  margin-left: 5px;
  transform: ${({ up }) => (up ? 'rotate(180deg)' : null)};
`;

const NotesWrapper = styled(Grid)`
  padding: 20px 3vw 0;
  width: 100%;
`;

const ImgStyled = styled.div`
  height: 95%;
  width: 100%;
  background-image: url('${({ img }) => img}');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  border-radius: 10px;
  min-height: 130px;
`;

const SingleData = styled(Grid)`
  font-size: ${({ big, theme }) => (big ? theme.font.xxl : theme.font.md)};
  padding: 3px;

  span {
    color: ${({ theme }) => theme.textColor};
    font-weight: bold;
  }
`;

const MainData = styled(Grid)`
  margin: 5px 0;
`;

const Circle = styled(Grid)`
  border-radius: 50%;
  height: 50px;
  width: 50px;
  padding: 5px;
  box-shadow: 0 3px 5px 4px #b7b2b22e;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  background-color: white;
  cursor: pointer;

  &:nth-child(1) {
    margin: 20px 0;
  }

  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 3px 3px 8px 3px #ccc;
  }
`;

const LinkStyled = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.main};
  transition: 0.2s;
  font-weight: bold;

  &:hover {
    color: ${({ theme }) => theme.text};
  }
`;

const SingleAuction = ({ data, setFavourite, setNote }) => {
  const [addNoteOpen, toggleaddNote] = useState(false);
  const [detailsOpen, toggleDetailsOpen] = useState(false);

  const [alertEmail, setAlertEmail] = useState(
    data.reactions[0]?.alertEmail === true ? true : false
  );
  const [alertSms, setAlertSms] = useState(
    data.reactions[0]?.alertSms === true ? true : false
  );

  const likeStatus = data.reactions[0] && data.reactions[0].likeStatus;
  const noteText = data.reactions[0] && data.reactions[0].note;

  //animation details

  let detaildContainer;
  let additionalDetailsContainer;

  const tl2 = gsap.timeline({ defaults: { ease: 'power3.inOut' } });
  tl2.set(additionalDetailsContainer, { autoAlpha: 0 });
  const toggleContainerDetails = () => {
    if (!detailsOpen) {
      toggleDetailsOpen(true);
      tl2.fromTo(
        detaildContainer,
        { maxHeight: '1200px' },
        { maxHeight: '4000px', duration: 0.3 }
      );
    } else {
      tl2
        .fromTo(
          additionalDetailsContainer,
          { opacity: 1 },
          { opacity: 0, duration: 0.2 }
        )
        .to(detaildContainer, {
          maxHeight: '800px',
          duration: 0.3,
        });
      setTimeout(() => toggleDetailsOpen(false), 400);
    }
  };

  const addReminder = async type => {
    const auctionId = data.auctionId
      axios({
        method: 'PUT',
        url: `${API}auction/reaction/${auctionId}`,
        data: {
          alertSms: type === 'sms' ? !alertSms : alertSms,
          alertEmail: type === 'email' ? !alertEmail : alertEmail,
        },
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(resp => {
          if (type === 'sms') {
            if (alertSms) {
              openAlert('off');
              setAlertSms(prevState => !prevState);
            } else {
              openAlert(type);
              setAlertSms(prevState => !prevState);
            }
          } else if (type === 'email') {
            if (alertEmail) {
              openAlert('off');
              setAlertEmail(prevState => !prevState);
            } else {
              openAlert(type);
              setAlertEmail(prevState => !prevState);
            }
          }
        })
        .catch(err => {
          if (err?.response?.status === 401) {
            window.location = redirect;
          }
          openAlert('error');
        });
    } 
  

  //alert
  const openAlert = type => {
    switch (type) {
      case 'email':
        activeNotification(
          'Powiadomienie email zostało ustawione!',
          'Dzień przed licytacją/przetargiem otrzymasz wiadomość z przypomnieniem na wskazany adres w profilu',
          'success'
        );

        break;

      case 'sms':
        activeNotification(
          'Powiadomienie SMS zostało ustawione!',
          `Dzień przed licytacją/przetargiem otrzymasz wiadomość z przypomnieniem na wskazany numer w profilu`,
          'success'
        );

        break;

      case 'error':
        activeNotification('Coś poszło nie tak', 'Spróbuj ponownie!', 'danger');

        break;

      case 'off':
        activeNotification(
          'Udało się!',
          'Przypomnienie zostało wyłaczone',
          'warning'
        );

        break;

      default:
        break;
    }
  };

  const calendar = {
    title: 'Dziś licytacja komornicza - SkanerOkazji.pl',
    date: `${data.auctionDate
      .split('-')
      .join('')
      .slice(0, 8)}T050000Z/${data.auctionDate
        .split('-')
        .join('')
        .slice(0, 8)}T060000Z`,
    details: `Link do licytacji: <a href="${data.link}">przejdź do licytacji</a>`,
    city: data.city,
  };

  const linkCalender = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${calendar.title}&dates=${calendar.date}&details=${calendar.details}&location=${calendar.city}`;

  return (
    <div>
      <DetailsWrapper
        item
        container
        addNoteOpen={addNoteOpen}
        data-spy='scroll'
        data-target='#navSection'
        data-offset='100'
      >
        <Grid item xs={12} md={3}>
          <ImgStyled
            img={
              data.thumbnailPath
                ? data.thumbnailPath.replace(' ', '%20')
                : DefaultImg
            }
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={9}
          style={{ padding: '0 10px' }}
        >
          <Grid container item xs={12} md={9} direction='column'>
            <MainData item container justify='space-between'>
              <Grid item xs={10}>
                <Grid
                  item
                  container
                  xs={12}
                  justify='space-between'
                  style={{ padding: '0 10px 0 0' }}
                >
                  <SingleData item>
                    Data licytacji: <span>{data.auctionDate.slice(0, 10)}</span>
                  </SingleData>
                  {/* <SingleData item>
                    Metraż:{' '}
                    <span>
                      {data.area || 'brak danych'}
                      {data.area ? (
                        <>
                          {' '}
                          m<sup>2</sup>
                        </>
                      ) : null}
                    </span>
                  </SingleData> */}
                
                </Grid>
                <SingleData>
                  <span>Adres: {data.city || '-'}</span>
                  <LinkStyled
                    href={`http://maps.google.com/?q=${data.city}`}
                    style={{ marginLeft: '20px' }}
                    target='_blank'
                  >
                    Pokaż na mapie
                  </LinkStyled>
                </SingleData>
                <SingleData big='true' bold='true'>
                  <span>
                    Cena wywoławcza:{' '}
                    {data.price === 0 || !data.price
                      ? '-'
                      : data.price
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    {data.price ? ' zł' : null}
                  </span>
                </SingleData>
                {/* <SingleData bold='true'>
                  <span>
                    Cena za m<sup>2</sup>:{' '}
                    {data.pricePerSquareMeter === 0 || !data.pricePerSquareMeter
                      ? 'brak danych'
                      : data.pricePerSquareMeter
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                        .replace('.', ',')}
                    {data.pricePerSquareMeter ? ' zł' : null}
                  </span>
                </SingleData> */}
                  <SingleData item>
                    Kategoria: <span>{data.category || '-'}</span>
                  </SingleData>

                <SingleData item>
                  <LinkStyled href={data.link} target='_blank'>
                    Przejdź do ogłoszenia:
                    <img
                      src={ImgLink}
                      alt='link'
                      style={{ marginLeft: '10px' }}
                    />
                  </LinkStyled>
                </SingleData>
                <Grid container justify='space-between'>
                  <SingleData item>
                    Data dodania: <span>{data.dateAdded.slice(0, 10)}</span>
                  </SingleData>
                  {/* <SingleData item>
                    Typ licytacji:{' '}
                    <span>{data.auctionType || 'brak danych'}</span>
                  </SingleData> */}
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction='column'
                xs={2}
                alignItems='center'
              >
                <Circle onClick={() => setFavourite(data.auctionId)}>
                  {likeStatus ? (
                    <FavoriteIcon style={{ color: '#FF0000' }} />
                  ) : (
                      <Heart style={{ color: '#FF0000' }} />
                    )}
                </Circle>
                <Circle
                  onClick={() => {
                    toggleaddNote(!addNoteOpen);
                  }}
                >
                  <Note fontSize={noteText?.length > 0 ? 'large' : 'small'} />
                </Circle>
              </Grid>
            </MainData>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={3}
            direction='column'
            style={{ maxWidth: '300px', margin: '10px auto 0' }}
          >
            <Button type='button' className='mb-3 p-2 '>
              <a href={linkCalender} target='_blank' style={{ color: 'white' }}>
                Dodaj do kalendarza Google
              </a>
            </Button>
            <Button
              notActive={!alertEmail}
              type='button'
              className='mb-3 p-2'
              onClick={() => addReminder('email')}
            >
              Przypomnienie email
            </Button>
            <Button
              notActive={!alertSms}
              type='button'
              className='mb-3 p-2'
              onClick={() => addReminder('sms')}
            >
              Przypomnienie sms
            </Button>
            {data.description && (
              <Button
                className='mb-3 p-2'
                onClick={() => toggleContainerDetails()}
              >
                Szczegóły
                <Arrow up={detailsOpen ? true : false} />
              </Button>
            )}
          </Grid>
        </Grid>
        {detailsOpen && data.description && (
          <AdditionalDetails
            container
            xs={12}
            ref={el => (additionalDetailsContainer = el)}
          >
            <Grid
              container
              justify={'space-between'}
              item
              md={10}
              lg={10}
              style={{
                margin: '10px auto 20px',
                borderRadius: '5px',
                padding: '20px',
                boxShadow: `0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07),
              0px 1px 11px 0px rgba(0, 0, 0, 0.07)`,
              }}
            >
              <SingleData bold='true' style={{ marginTop: '20px', width: '100%', wordWrap: 'break-word'}}>
                {data.description}
              </SingleData>
            </Grid>
          </AdditionalDetails>
        )}
        {addNoteOpen && (
          <NotesWrapper>
            <Notes
              note={noteText}
              id={data.auctionId}
              setNoteText={noteText}
              toggleaddNote={toggleaddNote}
              type='auction'
              setNoteToState={setNote}
            />
          </NotesWrapper>
        )}
      </DetailsWrapper>
    </div>
  );
};

export default SingleAuction;
