import React, { useContext } from 'react';
import { FieldArray } from 'react-final-form-arrays';

import Input from './atmos/Input';
import Paragraph from './atmos/Paragraph';

import { ValueContext } from './Wizard';

const FormFourthStep = ({ required, Error, ...rest }) => {
  const context = useContext(ValueContext);

  return (
    <>
      <div style={{ marginBottom: '50px' }}>
        <Paragraph>1. Formaty daty na stronie</Paragraph>
        <FieldArray name='dateFormats'>
          {({ fields, ...rest }) => (
            <div>
              {fields.map((name, index) => (
                <>
                  <div>
                    <Paragraph>Typ formatu daty</Paragraph>
                    <Input name={`${name}.type`} component='select' validate={required}>
                      <option />
                      <option value='EXISTING-FORMAT'> Istniejący format</option>
                      <option value='EXISTING-FORMAT-WITH-ADDITION'>
                        Istniejący format z dodatkiem
                      </option>
                      <option value='EXISTING-FORMAT-CONNECTED'>Połączony istniejący format</option>
                      <option value='TODAY'>Dzisiaj</option>
                      <option value='YESTERDAY'>Wczoraj</option>
                      <option value='DAY-BEFORE-YESTERDAY'>Przedwczoraj</option>
                      <option value='BEFORE'>Jeszcze wcześniej</option>
                      <option value='BEFORE-CONNECTED'>Jeszcze wcześniej połaczone</option>
                    </Input>
                    <Error name='domainType' />
                  </div>

                  <div style={{ marginBottom: '30px' }}>
                    <Paragraph>Format daty</Paragraph>
                    <Input
                      name={`${name}.form`}
                      component='input'
                      type='text'
                      placeholder='np. dd-MM-uuuu'
                      validate={required}
                      variant='outlined'
                    />
                    <Error name='domainName' />
                  </div>

                  {(context.dateFormats[index].type === 'BEFORE' ||
                    context.dateFormats[index].type === 'BEFORE-CONNECTED') && (
                    <div>
                      <Paragraph>Jednostka czasu</Paragraph>
                      <Input name={`${name}.timeUnit`} component='select' validate={required}>
                        <option />
                        <option value='DAY'>DAY</option>
                        <option value='MONTH'>MONTH</option>
                        <option value='MINUTE'>MINUTE</option>
                        <option value='HOUR'>HOUR</option>
                      </Input>
                      <Error name='domainType' />
                    </div>
                  )}

                  {context.dateFormats[index].type === 'BEFORE-CONNECTED' && (
                    <div style={{ marginBottom: '30px' }}>
                      <Paragraph>Ciąg znaków dzielący</Paragraph>
                      <Input
                        name={`${name}.addWord`}
                        component='input'
                        type='text'
                        placeholder='np. ;'
                        validate={required}
                        variant='outlined'
                      />
                      <Error name='domainName' />
                    </div>
                  )}

                  {context.dateFormats[index].type === 'EXISTING-FORMAT-WITH-ADDITION' && (
                    <div style={{ marginBottom: '30px' }}>
                      <Paragraph>Słowo wyróżniające</Paragraph>
                      <Input
                        name={`${name}.addWord`}
                        component='input'
                        type='text'
                        placeholder='np. dodania'
                        validate={required}
                        variant='outlined'
                      />
                      <Error name='domainName' />
                    </div>
                  )}

                  {(context.dateFormats[index].type === 'BEFORE-CONNECTED' ||
                    context.dateFormats[index].type === 'EXISTING-FORMAT-CONNECTED') && (
                    <div style={{ marginBottom: '30px' }}>
                      <Paragraph>Ciąg znaków dzielący</Paragraph>
                      <Input
                        name={`${name}.addWord`}
                        component='input'
                        type='text'
                        placeholder='np. dodania'
                        validate={required}
                        variant='outlined'
                      />
                      <Paragraph>Pozycja odpowiedniego członu</Paragraph>
                      <Input
                        name={`${name}.number`}
                        component='input'
                        type='number'
                        placeholder='np. 1'
                        validate={required}
                        variant='outlined'
                      />
                      <Error name='domainName' />
                    </div>
                  )}

                  <button
                    style={{
                      padding: '10px',
                      borderRadius: '50%',
                      width: '30px',
                      height: '30px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '14px',
                      margin: '0 0 0 auto',
                    }}
                    type='button'
                    onClick={() => fields.remove(index)}
                  >
                    X
                  </button>
                </>
              ))}
              <button
                type='button'
                className='btn btn-primary mb-2'
                style={{
                  padding: '10px',
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '22px',
                  margin: '10px 0 0 auto',
                }}
                onClick={() => fields.push({})}
              >
                +
              </button>
            </div>
          )}
        </FieldArray>
      </div>
      <Paragraph>2. Ustawienia interwałów</Paragraph>
      <div style={{ marginBottom: '10px' }}>
        <Paragraph>Pierwsze scrappowanie strony</Paragraph>
        <Input
          name='firstInterval'
          component='input'
          type='number'
          placeholder='np. 90'
          validate={required}
          variant='outlined'
        />
        <Error name='first-interval' />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Paragraph>Drugie scrappowanie strony</Paragraph>
        <Input
          name='secondInterval'
          component='input'
          type='number'
          placeholder='np. 30'
          validate={required}
          variant='outlined'
        />
        <Error name='secondInterval' />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Paragraph>Interwał który będzie powtarzalny</Paragraph>
        <Input
          name='finalInterval'
          component='input'
          type='number'
          placeholder='np. 3'
          validate={required}
          variant='outlined'
        />
        <Error name='finalInterval' />
      </div>
      {context.domainType === 'spoldzielnie' && (
        <div style={{ marginBottom: '20px'}}>
          <Paragraph>3. Słowa kluczowe znajdujące się w tytule spółdzielni.</Paragraph>
          <Paragraph>(każde oddzielamy znakiem ; )</Paragraph>
          <Input
            name='keyWords'
            component='input'
            type='text'
            placeholder='np. mieszkanie;blok;spółdzielnia mieszkanie;'
            variant='outlined'
          />
        </div>
      )}

    </>
  );
};

export default FormFourthStep;
