import React, { useEffect } from 'react';

const LogOutPage = () => {
  // const [timer, setTimer] = useState(5);

  const reload = () => {
    if(window.parent?.location){
      window.parent.location.reload();
    }else{
      window.location.reload();
    }
  };

  useEffect(() => {
    // setInterval(() => {
    //   if (timer <= 0) reload();
    //   setTimer((prev) => prev - 1);
    // }, [1000]);
    reload();
  }, []);

  return (
    <div style={{ fontSize: '3rem', paddingTop: '100px' }}>
      <p>
        <b>Nastąpiło wylogowanie!</b>
      </p>
      <p>Przeładuj stronę i zaloguj się ponownie!</p>

      <p style={{ marginTop: '200px' }}>Pozdrawiamy!</p>
    </div>
  );
};

export default LogOutPage;
