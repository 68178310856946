import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Grid } from '@material-ui/core';



const appear = keyframes`
  0% {
    top: -35px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
`;

const Container = styled.div`
  margin: 30px 0 50px;



  ul{
    padding-left:1em;
    padding-top:1em;

    li{
      color: #515365;
    font-size: 13px;
    margin:2em 0;

    p{
      margin:0;
    }
    }
  }
`;

const Wrapper = styled(Grid)`
  animation: ${appear} 0.4s;


ul{
  li{
    margin-left:2em;
  }}
`;

const TitleWrapper = styled.div`
    background: #e0e6ed;
    border-radius:6px;
    padding: 10px 0 10px 5px;
    


p{
  color: #0e2e60;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    margin-bottom:0;
    margin-left:1em;

}


`;

const ListOfPages = ({ list }) => {
  console.log(list);




  const pagesList = list.map(el => (
    <li><p>{el}</p></li>
  ));

  return (
    <Container>
      <Wrapper>
        <div class='col-xl-12' style={{ paddingLeft: '5px' }}>
          <div class='widget widget-table-two'>
            <TitleWrapper>
              <p>Monitorowane strony w tym mieście:</p>
            </TitleWrapper>
            <ul>{pagesList}</ul>
          </div>
        </div>
      </Wrapper>

    </Container>
  );
};

export default ListOfPages;
