import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

import SkanerOkazji from './Footer/SkanerOkazji';
import Investoro from './Footer/Investoro';

const StyledFooter = styled.footer`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  background-color: #fff;
  letter-spacing: normal;

  position: relative;
  z-index: 1030;

  @media (max-width: 960px) {
    z-index: 1020;
  }
`;

const url = window.location.host;

const Footer = () => {
  return (
    <StyledFooter>
      {url.includes('skanerokazji') ? <SkanerOkazji /> : <Investoro />}
    </StyledFooter>
  );
};

export default Footer;
