import React, { Component } from 'react';
import { Box, Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import { withStyles } from '@material-ui/core/styles';
import SaveFilterButtons from '../SaveFilterButtons';
import axios from 'axios';
import {
  FormCheckboxSaleMode,
  FormAutoCompleteFreeSolo,
  SavedFilter,
  FormCheckBoxDateRange,
  FormSelectDate,
} from '../InputFilters';
import {
  Area,
  PricePerSquareMeter,
  Price,
  SectionStyle,
  FilterButtons,
  SectionStyleCity,
} from '../FilterSections';
import { dateFormat, dateFromFunc, dateToFunc } from './../FilterFunctions';
import { setStateValues, getValueFromState } from '../functions/tenderFuncs';
import OfertDate from '../OfertDate';
import { API, redirect } from '../../API';
import { activeNotification } from '../functions/activeNotification';
import { CityOptions } from '../functions/cities';
import styled from 'styled-components';

const AreaOptions = ['25', '40', '50', '60', '70', '80'];

const PricePerSquareMeterOptions = [
  '50000',
  '100000',
  '150000',
  '200000',
  '250000',
  '300000',
  '350000',
  '400000',
  '500000',
  '600000',
  '700000',
  '800000',
];

const PriceOptions = [
  '50000',
  '100000',
  '150000',
  '200000',
  '250000',
  '300000',
  '350000',
  '400000',
  '500000',
  '600000',
  '700000',
  '800000',
];

const SaleModeOptions = [
  { value: 'OD_SYNDYKA', name: 'Od syndyka' },
  { value: 'PRZETARGI', name: 'Przetargi' },
];

const DateOptions = [1, 2, 3, 4, 5, 6, 7, 14, 30, 60, 180, 360, -1];
const DateOptionsLabels = {
  1: 'dzień',
  2: 'dni',
  3: 'dni',
  4: 'dni',
  5: 'dni',
  6: 'dni',
  7: 'dni',
  14: 'dni',
  30: 'dni',
  60: 'dni',
  180: 'dni',
  360: 'dni',
  '-1': 'Wszystkie',
};

function initialState() {
  const data = {
    filterId: null,
    name: null,
    city: null,
    dateFrom: null,
    dateTo: null,
    areaFrom: null,
    areaTo: null,
    priceFrom: null,
    priceTo: null,
    pricePerSquareMeterFrom: null,
    pricePerSquareMeterTo: null,
    saleMode: [],
    alertSms: false,
    alertEmail: false,
    dateRangeFilter: false,
    daysAmount: 30,
    pushAlert: false,
  };

  return setStateValues(data);
}
const Title = styled.p`
  font-size: 1.4rem;
  margin-bottom: 0;
`;
const styles = (theme) => ({
  tooltipIcon: {
    margin: theme.spacing(0.5),
  },
  tooltipGridBox: {
    marginBottom: '0.6rem',
  },
});

class FiltersTender extends Component {
  constructor(props) {
    super(props);
    this.state = initialState();
    this.name = React.createRef();
  }

  initialDate = () => {
    const dateOferts = this.state.date;
    dateOferts.to = new Date();
    const now = new Date();
    dateOferts.from = now.setMonth(now.getMonth() - 1);
    return dateOferts;
  };

  onUnload = () => {
    localStorage.setItem('tender', JSON.stringify({ city: this.state.city }));
  };

  componentDidMount = () => {
    window.addEventListener('beforeunload', this.onUnload);
    const state = this.initialDate();
    const retrievedObject = localStorage.getItem('tender');
    if (retrievedObject) {
      const data = JSON.parse(retrievedObject);
      state.city = data.city;
    }
    this.setState(state);
    this.getSavedFilters().then((filters) => {
      this.savedFilters = filters;
      this.setState({ savedFilter: filters });
    });
  };

  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', this.onUnload);
    this.onUnload();
  };

  getSavedFilters = () => {
    return axios
      .get(`${API}auction/filter/all`)
      .then((resp) => {
        if (resp.data) {
          const filters = resp.data.map((el) => {
            return {
              name: el.name,
              filterId: el.filterId,
              alertSms: el.alertSms,
              alertEmail: el.alertEmail,
              pushAlert: el.pushAlert,
            };
          });
          return filters;
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          window.location = redirect;
        }
      });
  };

  clearFilters = (e) => {
    e && e.preventDefault();
    const oldState = initialState();
    // oldState.date = this.initialDate();
    this.name.current.value = '';
    this.setState(oldState);
  };

  handleSetFiltersValues = (name, value) => {
    if (value) {
      this.setState({
        filterId: value,
      });

      axios
        .get(`${API}auction/filter/${value}/`)
        .then((resp) => {
          const data = resp.data;
          data.dateRangeFilter = data.daysAmount ? false : true;
          const state = setStateValues(data);
          state.saleMode = resp.data.saleMode
            ? resp.data.saleMode.split(',')
            : [];
          this.setState({ ...state, dateRangeFilter: true });
          this.name.current.value = resp.data.name;

          if (data.daysAmount)
            this.handleChangeDateList('daysAmount', data.daysAmount);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            window.location = redirect;
          }
        });
    } else {
      this.state.filterId = null;
      this.clearFilters();
    }
  };

  validateFrom = (fieldName, value, options) => {
    const codeRegex = /^-?\d+(?:[.,]\d*?)?$/;
    const field = this.state[fieldName];
    field.from = value;
    const wrongSize = field.to * 1 < field.from * 1;
    if (value) {
      field.errorFrom =
        !codeRegex.test(value) ||
        value * 1 < 0 ||
        (codeRegex.test(field?.to) && wrongSize);
      field.errorTo =
        ((!codeRegex.test(field.to) || field.to * 1 < 0) &&
          field.to?.length > 0) ||
        (codeRegex.test(field.to) && wrongSize);
    } else {
      field.errorFrom = false;
      field.errorTo =
        (!codeRegex.test(field.to) || field.to * 1 < 0) && field.to?.length > 0;
      field.toOptions = options;
    }
    if (!field.errorFrom && value) {
      this.handleChangeFrom(fieldName, value, options);
      return;
    }
    this.setState({
      [fieldName]: field,
    });
  };

  validateTo = (fieldName, value, options) => {
    const codeRegex = /^-?\d+(?:[.,]\d*?)?$/;
    const field = this.state[fieldName];
    field.to = value;
    const wrongSize = field.to * 1 < field.from * 1;
    if (value) {
      field.errorTo =
        !codeRegex.test(value) ||
        value * 1 < 0 ||
        (codeRegex.test(field?.from) && wrongSize);
      field.errorFrom =
        ((!codeRegex.test(field.from) || field.from * 1 < 0) &&
          field.from?.length > 0) ||
        (codeRegex.test(field.from) && wrongSize);
    } else {
      field.errorTo = false;
      field.errorFrom =
        (!codeRegex.test(field.from) || field.from * 1 < 0) &&
        field.from?.length > 0;
      field.fromOptions = options;
    }
    if (!field.errorTo && value) {
      this.handleChangeTo(fieldName, value, options);
      return;
    }
    this.setState({
      [fieldName]: field,
    });
  };

  handleChange = (fieldName, value) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleChangeDateRange = (fieldName, checked) => {
    this.setState({
      dateRangeFilter: checked,
    });
  };

  handleChangeDateList = (fieldName, value) => {
    const date = this.state.date;
    const dt = new Date();
    dt.setDate(dt.getDate() - value);
    date.from = value === -1 ? null : dt;
    date.to = null;
    this.setState({ date, [fieldName]: value });
  };

  selectAll = (fieldName, optionList, check) => {
    this.setState({
      [fieldName]: check ? [] : ['OD_SYNDYKA', 'PRZETARGI'],
    });
  };

  handleChangeCity = (fieldName, value) => {
    this.setState({
      city: value,
      cityError: false,
    });
  };

  removeSavedFilter = (e) => {
    axios
      .delete(`${API}auction/filter/delete/${this.state.filterId}/`)
      .then((resp) => {
        for (let i = 0; i < this.savedFilters.length; i++) {
          if (this.savedFilters[i].filterId === this.state.filterId) {
            this.savedFilters.splice(i, 1);
          }
        }
        this.setState({ savedFilter: this.savedFilters, filterId: '' });
        this.clearFilters();
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          window.location = redirect;
        } else {
          activeNotification(
            'Coś poszło nie tak!',
            'Spróbuj ponownie',
            'danger'
          );
        }
      });
    e.preventDefault();
  };

  handleChangeFrom = (fieldName, value, options) => {
    const sectionField = this.state[fieldName];
    const optionList = [];
    sectionField.from = value;
    for (let i = 0; i < options.length; i++) {
      if (options[i] * 1 >= value * 1) {
        optionList.push(options[i]);
      }
    }
    sectionField.toOptions = optionList;
    this.setState({
      [fieldName]: sectionField,
    });
  };

  handleChangeTo = (fieldName, value, options) => {
    const sectionField = this.state[fieldName];
    const optionList = [];
    sectionField.to = value;
    for (let i = 0; i < options.length; i++) {
      if (options[i] * 1 <= value * 1) {
        optionList.push(options[i]);
      }
    }
    sectionField.fromOptions = optionList;
    this.setState({
      [fieldName]: sectionField,
    });
  };

  changeDate = (offertDate) => {
    const date = this.state.date;
    date.errorFrom = offertDate.errorFrom;
    date.errorTo = offertDate.errorTo;
    date.from = offertDate.from;
    date.to = offertDate.to;
    this.setState({ date });
  };

  isError = () => {
    let error = false;
    Object.values(this.state).forEach((value) => {
      if (value) {
        Object.keys(value).forEach((field) => {
          if (field) {
            if (
              (field == 'errorTo' || field == 'errorFrom') &&
              value[field] == true
            ) {
              error = true;
            }
          }
        });
      }
    });
    return error;
  };

  addFilter = (params) => {
    return axios({
      method: 'post',
      url: `${API}auction/filter/add/`,
      params,
    })
      .then((resp) => {
        this.getSavedFilters().then((filters) => {
          for (let i = 0; i < filters.length; i++) {
            const existingFilter = this.savedFilters?.find(
              (el) => el.filterId === filters[i].filterId
            );
            if (!existingFilter) {
              this.savedFilters = filters;
              this.setState({
                name: filters[i].name,
                filterId: filters[i].filterId,
                savedFilter: filters,
              });
              this.name.current.value = filters[i].name;
              break;
            }
          }
        });
        return resp;
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          window.location = redirect;
        } else if (err?.response?.status === 400) {
          activeNotification(
            'Wykorzystano dostępną ilość filtrów dla Twojego pakietu!',
            'Zmień pakiet, lub usuń jeden z zapisanych filtrów.',
            'danger'
          );
          return Promise.reject(err);
        } else {
          activeNotification(
            'Coś poszło nie tak!',
            'Spróbuj ponownie',
            'danger'
          );
          return Promise.reject(err);
        }
      });
  };

  updateFilter = (id, params) => {
    return axios({
      method: 'put',
      url: `${API}auction/filter/update/${id}/`,
      params,
    })
      .then((resp) => {
        this.handleSetFiltersValues('', id);
        return resp;
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          window.location = redirect;
        }
        return Promise.reject(err);
      });
  };

  saveFilter = (e) => {
    e.preventDefault();
    const promise = [];
    if (
      document.activeElement.id == 'name' ||
      document.activeElement.id == ''
    ) {
      document.activeElement.blur();
      if (!this.state.city || !this.state.city.replace(/\s/g, '').length) {
        this.setState({
          cityError: !this.state.city,
        });
      } else if (!this.isError()) {
        const state = this.state;
        const daysAmount = state.dateRangeFilter ? null : state.daysAmount;
        state.daysAmount = daysAmount;
        state.name = this.name.current.value;
        const params = getValueFromState(state);
        for (const key of Object.keys(params)) {
          if (params[key] === '') {
            delete params[key];
          }
        }
        const saveName = this.state.savedFilter?.find(
          (filter) => filter.name == params.name
        );
        let id;
        saveName && (id = saveName.filterId);
        const rest = saveName
          ? this.updateFilter(id, params)
          : this.addFilter(params);
        promise.push(rest);
      }
    } else {
      document.activeElement.blur();
      this.handleSubmit(e);
    }
    return promise;
  };

  handleSubmit = (e) => {
    if (!this.state.city || !this.state.city.replace(/\s/g, '').length) {
      this.setState({
        cityError: true,
      });
    } else if (!this.isError()) {
      const data = {
        city: this.state.city,
        params: {
          dateFrom: this.state.date.from
            ? dateFormat(dateFromFunc(this.state.date.from))
            : null,
          dateTo: this.state.date.to
            ? dateFormat(dateToFunc(this.state.date.to))
            : null,
          areaFrom: this.state.area.from,
          areaTo: this.state.area.to,
          priceFrom: this.state.price.from,
          priceTo: this.state.price.to,
          pricePerSquareMeterFrom: this.state.pricePerSquareMeter.from,
          pricePerSquareMeterTo: this.state.pricePerSquareMeter.to,
          saleMode:
            this.state.saleMode.length === SaleModeOptions.length
              ? null
              : this.state.saleMode[0],
        },
      };
      this.props.handleSubmit(e, data);
    }
    e.preventDefault();
  };

  render() {
    const { state } = this;
    const { classes } = this.props;
    const tooltipDataListTender = this.props.tooltipDataListTender;

    let toolTipPriceTender = tooltipDataListTender.filter(
      (item) => item.field === 'PRICE'
    )[0]?.content;
    let toolTipAreaTender = tooltipDataListTender.filter(
      (item) => item.field === 'AREA'
    )[0]?.content;
    let toolTipDateTender = tooltipDataListTender.filter(
      (item) => item.field === 'DATE'
    )[0]?.content;
    let toolTipCityTender = tooltipDataListTender.filter(
      (item) => item.field === 'CITY'
    )[0]?.content;
    let toolTipPricePerSqTender = tooltipDataListTender.filter(
      (item) => item.field === 'PRICE_PER_SQUARE_METER'
    )[0]?.content;
    let toolTipFilterTender = tooltipDataListTender.filter(
      (item) => item.field === 'SAVED_FILTERS'
    )[0]?.content;

    return (
      <form onSubmit>
        <Grid container spacing={0}>
          <Grid item xs={12} md={6} lg={3}>
            <SectionStyle>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                className={classes.tooltipGridBox}
              >
                <Title>Zapisane filtry</Title>
                <Tooltip
                  classes={{ tooltip: classes.customWidth }}
                  enterTouchDelay={0}
                  title={
                    <span
                      style={{
                        fontSize: '1.2rem',
                        lineHeight: '1.2',
                        fontFamily: 'Inter',
                      }}
                    >
                      {toolTipFilterTender}
                    </span>
                  }
                  arrow
                  placement="top"
                >
                  <InfoSharpIcon className={classes.tooltipIcon} />
                </Tooltip>
              </Grid>
              <SavedFilter
                name={state.name}
                options={this.savedFilters}
                onChange={this.handleSetFiltersValues}
                removeSavedFilter={this.removeSavedFilter}
                value={state.filterId}
                placeholder="Wybierz filtr"
              />
            </SectionStyle>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SectionStyle>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                className={classes.tooltipGridBox}
              >
                <Title>Miasto</Title>
                <Tooltip
                  classes={{ tooltip: classes.customWidth }}
                  enterTouchDelay={0}
                  title={
                    <span
                      style={{
                        fontSize: '1.2rem',
                        lineHeight: '1.2',
                        fontFamily: 'Inter',
                      }}
                    >
                      {toolTipCityTender}
                    </span>
                  }
                  arrow
                  placement="top"
                >
                  <InfoSharpIcon className={classes.tooltipIcon} />
                </Tooltip>
              </Grid>
              <FormAutoCompleteFreeSolo
                id="cities"
                name="city"
                value={state.city}
                optionList={CityOptions}
                onChange={this.handleChangeCity}
                onBlur={this.handleChangeCity}
                error={state.cityError}
              />
            </SectionStyle>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SectionStyleCity>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                className={classes.tooltipGridBox}
              >
                <p style={{ fontSize: '1.4rem', marginBottom: '0' }}>
                  Data dodania oferty
                </p>
                <Tooltip
                  classes={{ tooltip: classes.customWidth }}
                  enterTouchDelay={0}
                  title={
                    <span
                      style={{
                        fontSize: '1.2rem',
                        lineHeight: '1.2',
                        fontFamily: 'Inter',
                      }}
                    >
                      {toolTipDateTender}
                    </span>
                  }
                  arrow
                  placement="top"
                >
                  <InfoSharpIcon className={classes.tooltipIcon} />
                </Tooltip>
              </Grid>
              {state.dateRangeFilter ? (
                <OfertDate
                  changeDate={this.changeDate}
                  to={state.date.to}
                  from={state.date.from}
                  errorTo={state.date.errorTo}
                  errorFrom={state.date.errorFrom}
                />
              ) : (
                <FormSelectDate
                  id="daysAmount"
                  name="daysAmount"
                  value={state.daysAmount}
                  onChange={this.handleChangeDateList}
                  optionList={DateOptions}
                  dateOptionsLabels={DateOptionsLabels}
                  placeholder="Wybierz ilość ostatnich dni"
                />
              )}
              <FormCheckBoxDateRange
                id="dateRangeFilter"
                name="dateRangeFilter"
                checked={state.dateRangeFilter}
                onChange={this.handleChangeDateRange}
                label={
                  <Box component="div" fontSize={12}>
                    Zakres dat
                  </Box>
                }
              />
            </SectionStyleCity>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SectionStyle>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                className={classes.tooltipGridBox}
              >
                <Title>Cena</Title>
                <Tooltip
                  classes={{ tooltip: classes.customWidth }}
                  enterTouchDelay={0}
                  title={
                    <span
                      style={{
                        fontSize: '1.2rem',
                        lineHeight: '1.2',
                        fontFamily: 'Inter',
                      }}
                    >
                      {toolTipPriceTender}
                    </span>
                  }
                  arrow
                  placement="top"
                >
                  <InfoSharpIcon className={classes.tooltipIcon} />
                </Tooltip>
              </Grid>
              <Price
                price={state.price}
                handleChangeFrom={this.handleChangeFrom}
                handleChangeTo={this.handleChangeTo}
                validateFrom={this.validateFrom}
                validateTo={this.validateTo}
                options={PriceOptions}
              />
            </SectionStyle>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SectionStyle>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                className={classes.tooltipGridBox}
              >
                <Title>Metraż</Title>
                <Tooltip
                  classes={{ tooltip: classes.customWidth }}
                  enterTouchDelay={0}
                  title={
                    <span
                      style={{
                        fontSize: '1.2rem',
                        lineHeight: '1.2',
                        fontFamily: 'Inter',
                      }}
                    >
                      {toolTipAreaTender}
                    </span>
                  }
                  arrow
                  placement="top"
                >
                  <InfoSharpIcon className={classes.tooltipIcon} />
                </Tooltip>
              </Grid>
              <Area
                aera={state.area}
                validateTo={this.validateTo}
                validateFrom={this.validateFrom}
                handleChangeFrom={this.handleChangeFrom}
                handleChangeTo={this.handleChangeTo}
                options={AreaOptions}
              />
            </SectionStyle>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SectionStyle>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                className={classes.tooltipGridBox}
              >
                <Title>Cena za metr</Title>
                <Tooltip
                  classes={{ tooltip: classes.customWidth }}
                  enterTouchDelay={0}
                  title={
                    <span
                      style={{
                        fontSize: '1.2rem',
                        lineHeight: '1.2',
                        fontFamily: 'Inter',
                      }}
                    >
                      {toolTipPricePerSqTender}
                    </span>
                  }
                  arrow
                  placement="top"
                >
                  <InfoSharpIcon className={classes.tooltipIcon} />
                </Tooltip>
              </Grid>
              <PricePerSquareMeter
                handleChangeFrom={this.handleChangeFrom}
                handleChangeTo={this.handleChangeTo}
                pricePerSquareMeter={state.pricePerSquareMeter}
                validateFrom={this.validateFrom}
                validateTo={this.validateTo}
                options={PricePerSquareMeterOptions}
              />
            </SectionStyle>
          </Grid>
        </Grid>
        <FilterButtons
          clearFilters={this.clearFilters}
          handleSubmit={this.handleSubmit}
        />
        <br />
        <SaveFilterButtons
          saveFilter={this.saveFilter}
          name={this.name}
          handleChange={this.handleChange}
          alertSms={state.alertSms}
          alertEmail={state.alertEmail}
          pushAlert={state.pushAlert}
          filterId={state.filterId}
        />
      </form>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FiltersTender);
