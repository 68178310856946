import React, { useState, memo } from 'react';
import Adown from '@material-ui/icons/ArrowDropDown';
import styled, { keyframes } from 'styled-components';
import gsap from 'gsap';

import FiltersAuctions from './auctions/FiltersAuctions';
import FiltersAdvertisement from './advertisements/FiltersAdvertisement';
import FiltersTender from './tender/FiltersTender';
import FiltersBailiff from './bailiff/FiltersBailiff';
import FiltersCooperatives from './cooperatives/FiltersCooperatives';
import FiltersAnalysis from './analysis/FiltersAnalysis';
import FiltersRent from './rent/FiltersRent';

const gross = keyframes`
  0% {
    max-height: 0;
  }
  100% {
    max-height: 500px;
  }
`;

const appear = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ContainerFiltr = styled.div`
  animation: ${gross} 0.2s;
`;

const Wrapper = styled.div`
  animation: ${appear} 0.2s;
`;

const Container = styled.div`
  font-size: ${({ theme }) => theme.font.xs};
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07),
    0px 1px 11px 0px rgba(0, 0, 0, 0.07);
`;

const FiltrButton = memo(({ handleSubmit, type, tooltipDataList, tooltipDataListTender, tooltipDataListAuction, tooltipDataListCoop, tooltipDataListBaliff }) => {
  const Filters = () => {
    if (type === 'advertisement') {
      return <FiltersAdvertisement handleSubmit={handleSubmit} tooltipDataList={tooltipDataList} />;
    }
    if (type === 'auctions') {
      return <FiltersAuctions handleSubmit={handleSubmit} tooltipDataListAuction={tooltipDataListAuction} />;
    }
    if (type === 'tender') {
      return <FiltersTender handleSubmit={handleSubmit} tooltipDataListTender={tooltipDataListTender} />;
    }
    if (type === 'bailiff') {
      return <FiltersBailiff handleSubmit={handleSubmit} tooltipDataListBaliff={tooltipDataListBaliff} />;
    }
    if (type === 'cooperatives') {
      return <FiltersCooperatives handleSubmit={handleSubmit} tooltipDataListCoop={tooltipDataListCoop} />;
    }
    if (type === 'analysis') {
      return <FiltersAnalysis handleSubmit={handleSubmit} tooltipDataList={tooltipDataList} />;
    }
    if (type === 'rent') {
      return <FiltersRent handleSubmit={handleSubmit} tooltipDataList={tooltipDataList} />;
    }
  };

  return (
    <Container id='filters'>
      <ContainerFiltr>
        <Wrapper>
          <Filters />
        </Wrapper>
      </ContainerFiltr>
    </Container>
  );
});

export default FiltrButton;
