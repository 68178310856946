import Cookies from 'js-cookie';
import { API, redirect } from '../../API';
import axios from 'axios';
import { activeNotification } from '../functions/activeNotification';

export const like = async (id, type, likeAdded, status) => {

  try {
    const resp = await axios({
      method: 'PUT',
      url: `${API}${type}/reaction/${id}`,
      data: {
        like: status,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (resp){
      likeAdded()
    } 
  } catch (e) {
    if (e?.response?.status === 401) {
      window.location = redirect;
    } else {
      activeNotification(
        'Coś poszło nie tak',
        'odśwież stronę i spróbuj jeszcze raz!',
        'danger'
      );
      likeAdded();
    }
  }

}