import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Grid, List, ListItem, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const gross = keyframes`
  0% {
    max-height: 0;
  }
  100% {
    max-height: 500px;
  }
`;

const appear = keyframes`
  0% {
    top: -35px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
`;

const ContainerDuplicates = styled.div`
  margin-top: 30px;
  animation: ${gross} 0.4s;
`;

const Wrapper = styled(Grid)`
  animation: ${appear} 0.4s;
`;
const TitleWrapper = styled(Grid)`
  background-color:#DFE6EC;
  border: #cfdde8;
  border-radius: 5px;
`;

const MainTitle = styled.p`
  font-size: 13px;
  font-weight:600;
  margin:0;
  padding:0.7em 0.5em 0.7em 0.7em;
  color:#0e2e60;
`;
const Title = styled.p`
  font-size: 13px;
  font-weight:600;
  margin:0;
  padding:0.7em 0.5em 0.7em 0.1em;
`;
const DateTitle = styled.p`
  font-size: 13px;
  font-weight:600;
  margin:0;
  padding:0.7em 0.5em 0.7em 0.1em;
  color:#0e2e60;
`;
const SectionTitle = styled.p`
  font-size: 19px;
  font-weight:700;
  margin:0;
  padding:0.5em;
  `;
const TitleTime = styled.p`
font-size: 13px;
  font-weight:500;
  margin:0;
  padding:0.7em 0.5em 0.7em 0.1em;
  color:#0e2e60;

`;
const ValueP = styled.p`
  font-size: 13px;
  font-weight:500;
  margin:0;
  padding:0.5em;
`;

const useStyles = makeStyles({
  dateWrapper: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    width: '100%',
  },
  listWrapper: {
    width: '100%',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
  }
});
const Modyfications = ({ modifications }) => {
  const classes = useStyles();

  return (
    <ContainerDuplicates>
      <Paper>
        <Wrapper container xs={12} sm={12} lg={12} direction="column">
          <List className={classes.listWrapper}>
            {modifications.map((el, index) => (
              <ListItem className={classes.listItem}>
                <Grid container item xs={12} sm={12} lg={12} style={{ paddingRight: '5px' }} alignItems='center'>
                  <SectionTitle>
                    Modyfikacje
                  </SectionTitle>
                  <TitleWrapper item container direction="row" xs={12} sm={12} lg={12}>
                    <MainTitle>
                      Data:
                  </MainTitle>
                    <DateTitle>
                      {el.date.slice(0, 10)}
                    </DateTitle>
                    <TitleTime>
                      {el.date.slice(11, 19)}
                    </TitleTime>
                  </TitleWrapper>
                </Grid>

                {el.modifications.filter(item => item.field === 'Cena').map(item => (
                  <Grid container item xs={12} sm={12} lg={12} style={{ paddingLeft: '1.5em' }}>
                    <Grid item xs={4} sm={2} lg={1}>
                      <Title>{item.field}</Title>
                    </Grid>
                    <Grid item container xs={8} sm={10} lg={11} alignItems='center'>
                      <ValueP>{item.value}</ValueP>
                    </Grid>
                  </Grid>
                ))}
                {el.modifications.filter(item => item.field === 'Opis').map(item => (
                  <Grid container item xs={12} sm={12} lg={12} style={{ paddingLeft: '1.5em' }}>
                    <Grid item xs={4} sm={2} lg={1}>
                      <Title>{item.field}</Title>
                    </Grid>
                    <Grid item container xs={8} sm={10} lg={11} alignItems='center'>
                      <ValueP>{item.value}</ValueP>
                    </Grid>
                  </Grid>
                ))}
                {el.modifications.filter(item => item.field === 'Metraż').map(item => (
                  <Grid container item xs={12} sm={12} lg={12} style={{ paddingLeft: '1.5em' }} alignItems='center'>
                    <Grid item xs={4} sm={2} lg={1}>
                      <Title>{item.field}</Title>
                    </Grid>
                    <Grid item container xs={8} sm={10} lg={11} alignItems='center'>
                      <ValueP>{item.value}</ValueP>
                    </Grid>
                  </Grid>
                ))}
                {el.modifications.filter(item => item.field === 'Tytuł').map(item => (
                  <Grid container item xs={12} sm={12} lg={12} style={{ paddingLeft: '1.5em' }} alignItems='center'>
                    <Grid item xs={4} sm={2} lg={1}>
                      <Title>{item.field}</Title>
                    </Grid>
                    <Grid item container xs={8} sm={10} lg={11} alignItems='center'>
                      <ValueP>{item.value}</ValueP>
                    </Grid>
                  </Grid>
                ))}
                {el.modifications.filter(item => item.field === 'Piętro').map(item => (
                  <Grid container item xs={12} sm={12} lg={12} style={{ paddingLeft: '1.5em' }} alignItems='center'>
                    <Grid item xs={4} sm={2} lg={1}>
                      <Title>{item.field}</Title>
                    </Grid>
                    <Grid item container xs={8} sm={10} lg={11} alignItems='center'>
                      <ValueP>{item.value}</ValueP>
                    </Grid>
                  </Grid>
                ))}
                {el.modifications.filter(item => item.field === 'Liczba pokoi').map(item => (
                  <Grid container item xs={12} sm={12} lg={12} style={{ paddingLeft: '1.5em' }} alignItems='center'>
                    <Grid item xs={4} sm={2} lg={1}>
                      <Title>{item.field}</Title>
                    </Grid>
                    <Grid item container xs={8} sm={10} lg={11} alignItems='center'>
                      <ValueP>{item.value}</ValueP>
                    </Grid>
                  </Grid>
                ))}
                {el.modifications.filter(item => item.field === 'Typ budynku').map(item => (
                  <Grid container item xs={12} sm={12} lg={12} style={{ paddingLeft: '1.5em' }} alignItems='center'>
                    <Grid item xs={4} sm={2} lg={1}>
                      <Title>{item.field}</Title>
                    </Grid>
                    <Grid item container xs={8} sm={10} lg={11} alignItems='center'>
                      <ValueP>{item.value}</ValueP>
                    </Grid>
                  </Grid>
                ))}

              </ListItem>
            ))}
          </List>
        </Wrapper>
      </Paper>
    </ContainerDuplicates>
  );
};

export default Modyfications;
