import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';
import gsap from 'gsap';

import LaunchIcon from '@material-ui/icons/Launch';
import ImgLink from '../../assets/External_link_font_awesome.svg.png';
import Adown from '@material-ui/icons/ArrowDropDown';
import Note from '@material-ui/icons/SpeakerNotesOutlined';
import Heart from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';

import Notes from '../Reactions/Note';
import Button from '../atom/Button';

const appear = keyframes`
  0% {
    top: -35px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
`;
const NotesWrapper = styled(Grid)`
  text-align: center;
  padding: 20px 3vw 0;
  width: 100%;
`;

const Circle = styled(Grid)`
  border-radius: 50%;
  height: 35px;
  width: 35px;
  padding: 5px;
  box-shadow: 0 3px 5px 4px #b7b2b22e;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  background-color: white;
  cursor: pointer;

  &:nth-child(1) {
    margin-right: 30px;
  }

  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 3px 3px 8px 3px #ccc;
  }

  @media (max-width: 1320px) {
    height: 30px;
    width: 30px;
  }
`;

const Arrow = styled(Adown)`
  font-size: 15px;
  transform: ${({ up }) => (up ? 'rotate(180deg)' : null)};
`;

const AdditionalDetails = styled(Grid)`
  animation: ${appear} 0.4s;
`;

const SingleData = styled(Grid)`
  font-size: ${({ theme }) => theme.font.xs};
  padding: 3px;

  span {
    color: ${({ theme }) => theme.textColor};
    font-weight: bold;
  }
`;

const LinkStyled = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.main};
  transition: 0.2s;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  &:hover {
    color: ${({ theme }) => theme.text};
  }
`;

const SingleCooperative = ({ data, setFavourite, setNote }) => {
  console.log(data);

  const [addNoteOpen, toggleaddNote] = useState(false);
  const [detailsOpen, toggleDetailsOpen] = useState(false);

  const likeStatus = data.reactions[0] && data.reactions[0].likeStatus;
  const noteText = data.reactions[0] && data.reactions[0].note;
  //to check with window
  const isClient = typeof window === 'object';
  const getWidth = () => (isClient ? window.innerWidth : undefined);

  const [windowWith, setWindowWidth] = useState(getWidth);

  useEffect(() => {
    if (!isClient) return false;

    const handleResize = () => setWindowWidth(getWidth());

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let additionalDetailsContainer;
  let detaildContainer;

  const tl2 = gsap.timeline({
    defaults: {
      ease: 'power3.inOut',
    },
  });

  tl2.set(additionalDetailsContainer, { autoAlpha: 0 });
  const toggleContainerDetails = () => {
    if (!detailsOpen) {
      toggleDetailsOpen(true);
      tl2.fromTo(
        detaildContainer,
        {
          maxHeight: '300px',
        },
        {
          maxHeight: '1000px',
          duration: 0.3,
        }
      );
    } else {
      tl2
        .fromTo(
          additionalDetailsContainer,
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.2,
          }
        )
        .to(detaildContainer, {
          maxHeight: '500px',
          duration: 0.3,
        });
      setTimeout(() => toggleDetailsOpen(false), 400);
    }
  };

  const circles = (
    <Grid
      container
      item
      xs={12}
      md={6}
      justify='flex-end'
      alignItems='center'
      style={{ paddingBottom: `${windowWith < 961 ? '20px' : ''}` }}
    >
      <Circle
        onClick={() => {
          setFavourite(data.cooperativeId);
        }}
      >
        {likeStatus ? (
          <FavoriteIcon style={{ color: '#FF0000' }} />
        ) : (
          <Heart style={{ color: '#FF0000' }} />
        )}
      </Circle>
      <Circle
        onClick={() => {
          toggleaddNote(!addNoteOpen);
        }}
      >
        <Note fontSize={noteText?.length > 0 ? 'large' : 'small'} />
      </Circle>
    </Grid>
  );

  return (
    <>
      <tr key={data.cooperativeId}>
        <td style={{ width: '13%' }}>
          <div style={{ padding: '10px' }} class='td-content customer-name'>
            {data?.dateAdded?.slice(0, 10)}
          </div>
        </td>
        <td style={{ maxWidth: '60%' }}>
          <div class='td-content product-brand'>
            <p> {data?.title && <strong>{data.title}</strong>}</p>
            {data.address || '-'}
            <SingleData>
              <LinkStyled href={data.link} target='_blank'>
                Przejdź do ogłoszenia:
                <LaunchIcon />
              </LinkStyled>
            </SingleData>
          </div>
        </td>
        <td
          style={{
            maxWidth: '350px',
          }}
        >
          <div class='td-content'>
            <Grid container justify={`${windowWith > 850 && 'flex-end'}`}>
              {windowWith > 850 && circles}
              <Grid item md={6}>
                <Button
                  width='small'
                  onClick={() => toggleContainerDetails()}
                  style={{
                    minWidth: `${windowWith > 850 ? '120px' : ''}`,
                  }}
                >
                  {windowWith > 850 && 'Szczegóły'}
                  <Arrow up={detailsOpen ? true : false} />
                </Button>
              </Grid>
            </Grid>
          </div>
        </td>
      </tr>
      <tr>
        <td
          colSpan={3}
          style={{
            padding: 0,
          }}
        >
          {detailsOpen && (
            <AdditionalDetails
              container
              xs={12}
              ref={el => (additionalDetailsContainer = el)}
            >
              <div
                container
                alignItems='center'
                style={{
                  width: '100%',
                  padding: `${windowWith < 850 ? '0 3vw' : '0 8vw'}`,
                }}
              >
                <Grid
                  container
                  justify={`${windowWith < 850 ? 'space-around' : 'center'}`}
                  alignItems='center'
                >
                  <Grid item> Szczegóły </Grid>
                  <Grid item> {windowWith < 850 && circles}</Grid>
                </Grid>
                <div
                  className='infobox-1'
                  style={{
                    width: '100%',
                  }}
                >
                  <p
                    className='info-text'
                    style={{
                      textAlign: 'left',
                      color: '#6F747D',
                    }}
                  >
                    {data.description}
                  </p>
                </div>
              </div>
            </AdditionalDetails>
          )}
          {addNoteOpen && (
            <NotesWrapper>
              <Notes
                note={noteText}
                id={data.cooperativeId}
                setNoteText={noteText}
                type='cooperative'
                setNoteToState={setNote}
              />
            </NotesWrapper>
          )}
        </td>
      </tr>
    </>
  );
};

export default SingleCooperative;
