import axios from 'axios';
import Cookies from 'js-cookie';
import { API, redirect } from '../../API';

import { activeNotification } from './activeNotification';

const addAlertToDB = (type, city, value, method, module, func) => {
  const typeLargeLetter = `${type[0].toUpperCase()}${type.slice(1)}`;
  const param = type === 'push' ? `${type}Alert` : `alert${typeLargeLetter}`

  axios({
    method,
    url: `${API}${module}/filter/alert/${type}/?city=${city}&${param}=${value}`,
  })
    .then(resp => {
      activeNotification(
        'Udało się!',
        `${value === true ? 'Alert został dodany' : 'Alert został usunięty'}`,
        `${value === true ? 'success' : 'warning'}`
      );
      func(city);
    })
    .catch(err => {
      if (err?.response?.status === 401) {
        window.location = redirect;
      } else if (err?.response?.status === 400) {
        activeNotification(
          'Wykorzystano dostępną ilość filtrów dla Twojego pakietu!',
          'Zmień pakiet, lub usuń jeden z zapisanych filtrów.',
          'danger'
        );
      } else {
        activeNotification(
          'Coś poszło nie tak',
          'spróbuj jeszcze raz!',
          'danger'
        );
      }
    });
};

export default addAlertToDB;
