import React, { Component, memo } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import MainList from '../components/MainList';
import HeaderSection from '../components/HeaderSection';
import FilterButton from '../components/FilterButton';
import { API, redirect } from '../API';
import { activeNotification } from '../components/functions/activeNotification';
import { like } from '../components/Reactions/Like';
import Indicator from '../components/atom/Indicator';

import {
  comparePriceAscending,
  comparePriceDescending,
  compareDateAscending,
  compareDateDescending,
  comparePricePerSquareMeterAscending,
  comparePricePerSquareMeterDescending,
  compareAuctionDateAscending,
  compareAuctionDateDescending,
} from '../components/Sort';



const Content = memo(
  ({
    auctionsList,
    handleChangeSort,
    handleChangeNotes,
    handleChangeFavorite,
    checkedFavorite,
    checkedNotes,
    sortType,
    setFavourite,
    setNote,
    sortFromOptions,
    sortPlaceholder,
  }) => {
    return (
      <>
        <HeaderSection
          list={auctionsList}
          handleChangeSort={handleChangeSort}
          handleChangeNotes={handleChangeNotes}
          handleChangeFavorite={handleChangeFavorite}
          checkedFavorite={checkedFavorite}
          checkedNotes={checkedNotes}
          sortType={sortType}
          sortOptions={sortFromOptions}
          sortPlaceholder={sortPlaceholder}
        />
        <MainList
          list={auctionsList}
          type='auctions'
          setFavourite={setFavourite}
          setNote={setNote}
        />
      </>
    );
  }
);


class AuctionsPage extends Component {
  state = {
    defaultAuctionsList: [],
    auctionsList: [],
    sortType: '',
    checkedFavorite: false,
    checkedNotes: false,
    isLoading: false,
    filters: React.createRef(),
    tooltipDataListAuction: [],

  };

  componentDidMount() {
    axios({
      method: 'get',
      url: `${API}auction/auctionDirectory`,
    })
      .then(resp => {
        const tooltipData = resp.data;
        
        this.setState({
          tooltipDataListAuction: tooltipData,
        });
      })
  }

  handleSubmit = (e, data) => {
    e.preventDefault();

    this.setState({
      isLoading: true,
      defaultAuctionsList: [],
      auctionsList: [],
    });

    for (const key of Object.keys(data.params)) {
      if (data.params[key] === '') {
        delete data.params[key];
      }
    }

    axios({
      method: 'get',
      url: `${API}auction/findByFilter/${data.city}/`,
      params: data.params,
    })
      .then(resp => {
        if (resp.data) {
          const auctionsList = resp.data
            .filter(el => el.link.includes('licytacje.komornik.pl'))
            .sort(compareAuctionDateDescending);

          if (auctionsList.length === 0)
            activeNotification(
              'Nie znaleziono wyników',
              'Zmień wartości wyszukiwania',
              'warning'
            );
          else {
            this.setState({
              defaultAuctionsList: auctionsList,
              auctionsList,
            });

            window.scrollTo(0, this.state.filters.current.offsetTop + 20);
          }
        } else {
          activeNotification(
            'Nie znaleziono wyników',
            'Zmień wartości wyszukiwania',
            'warning'
          );
        }

        this.setState({
          isLoading: false,
        });
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          window.location = redirect;
        } else {
          this.setState({ isLoading: false });
          activeNotification(
            'Coś poszło nie tak!',
            'Spróbuj ponownie',
            'danger'
          );
        }
      });
  };


  sort = sortType => {
    let { auctionsList } = this.state;

    switch (sortType) {
      case 'Po cenie rosnąco':
        auctionsList.sort(comparePriceAscending);
        break;

      case 'Po cenie malejąco':
        auctionsList.sort(comparePriceDescending);
        break;

      case 'Po dacie licytacji rosnąco':
        auctionsList.sort(compareAuctionDateAscending);
        break;

      case 'Po dacie licytacji malejąco':
        auctionsList.sort(compareAuctionDateDescending);
        break;
      case 'Po dacie dodania rosnąco':
        auctionsList.sort(compareDateAscending);
        break;

      case 'Po dacie dodania malejąco':
        auctionsList.sort(compareDateDescending);
        break;

      // case 'Po cenie za m2 rosnąco':
      //   auctionsList.sort(comparePricePerSquareMeterAscending);
      //   break;

      // case 'Po cenie za m2 malejąco':
      //   auctionsList.sort(comparePricePerSquareMeterDescending);
      //   break;

      default:
        break;
    }
    this.setState({ auctionsList });
  };

  handleChangeSort = (fieldName, value) => {
    this.setState({
      [fieldName]: value,
    });

    this.sort(value);
  };

  handleChangeFavorite = event => {
    let auctionsList = this.state.defaultAuctionsList;
    if (event.target.checked) {
      if (this.state.checkedNotes)
        auctionsList = auctionsList.filter(
          el => el.reactions[0]?.likeStatus && el.reactions[0]?.note
        );
      else
        auctionsList = auctionsList.filter(el => el.reactions[0]?.likeStatus);
    } else {
      if (this.state.checkedNotes)
        auctionsList = this.state.defaultAuctionsList.filter(
          el => el.reactions[0]?.note
        );
      else auctionsList = this.state.defaultAuctionsList;
    }

    this.setState({
      checkedFavorite: event.target.checked,
      auctionsList,
      isLoading: false,
    });
  };

  handleChangeNotes = event => {
    let auctionsList = this.state.defaultAuctionsList;
    if (event.target.checked) {
      if (this.state.checkedFavorite)
        auctionsList = auctionsList.filter(
          el => el.reactions[0]?.likeStatus && el.reactions[0]?.note
        );
      else auctionsList = auctionsList.filter(el => el.reactions[0]?.note);
    } else {
      if (this.state.checkedFavorite)
        auctionsList = this.state.defaultAuctionsList.filter(
          el => el.reactions[0]?.likeStatus
        );
      else auctionsList = this.state.defaultAuctionsList;
    }

    this.setState({
      checkedNotes: event.target.checked,
      auctionsList,
    });
  };

  setFavourite = id => {
    this.setState({ isLoading: true });

    const list = this.state.auctionsList;

    const likeAdded = () => this.setState({ isLoading: false });

    const newList = list.map(el => {
      const reactions = el.reactions[0];
      const newStatus = reactions ? !reactions.likeStatus : true
      const newReactions = { ...reactions, likeStatus: newStatus };

      if (el.auctionId === id) {
        like(id, 'auction', likeAdded, newStatus);
        if (el.reactions[0]) {
          el.reactions[0].likeStatus = newStatus;
        } else {
          el.reactions[0] = newReactions;
        }
      }
      return el;
    });
    this.setState({ auctionsList: newList });
  };

  setNote = (id, note) => {
    let list = this.state.auctionsList;

    list = list.map(el => {
      if (el.auctionId === id) {
        if (el.reactions[0]) {
          el.reactions[0].note = note;
        } else {
          el.reactions.push({ note });
        }
      }
      return el;
    });
    this.setState({ auctionsList: list });
  };

  render() {
    console.log(this.state.auctionsList)
    const sortFromOptions =
      [
        'Po dacie licytacji malejąco',
        'Po dacie licytacji rosnąco',
        'Po dacie dodania malejąco',
        'Po dacie dodania rosnąco',
        'Po cenie rosnąco',
        'Po cenie malejąco',
      ];

    const sortPlaceholder = 'Po dacie licytacji malejąco'
    const {
      auctionsList,
      sortType,
      checkedFavorite,
      checkedNotes,
      isLoading,
      tooltipDataListAuction,
    } = this.state;

    return (

      <>
        <FilterButton handleSubmit={this.handleSubmit} type='auctions' tooltipDataListAuction={tooltipDataListAuction} />
        <hr
          style={{ backgroundColor: '#edecf2', color: '#edecf2', height: 2 }}
          ref={this.state.filters}
        />
        {isLoading && <Indicator />}
        {(auctionsList.length > 0 || checkedFavorite || checkedNotes) && (
          <Content
            auctionsList={auctionsList}
            handleChangeSort={this.handleChangeSort}
            handleChangeNotes={this.handleChangeNotes}
            handleChangeFavorite={this.handleChangeFavorite}
            checkedFavorite={checkedFavorite}
            checkedNotes={checkedNotes}
            sortType={sortType}
            setFavourite={this.setFavourite}
            setNote={this.setNote}
            sortFromOptions={sortFromOptions}
            sortPlaceholder={sortPlaceholder}
          />
        )}
      </>
    );
  }
}
export default AuctionsPage;
