import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import {
  FormAutoCompleteFreeSolo,
  FormSelect,
  FormButtonStyle,
} from './InputFilters';
import Button from './atom/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export const SectionStyle = styled.div`
  margin: 5px 5px;
  padding: 10px 10px 15px;
  overflow: hidden;
  box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 5%);
  background-color: white;
  border-radius: 5px;
  border: #e0e0e0 1px solid;
`;
export const SectionStyleCity = styled.div`
  margin: 5px 5px;
  padding: 10px 10px 15px;
  overflow: hidden;
  box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 5%);
  background-color: white;
  border-radius: 5px;
  border: #e0e0e0 1px solid;
`;

const Title = styled.p`
  font-size: 1.4rem;
  margin-bottom: 0;
`;

const ClearFiltersButton = styled.button`
  display: inline-block;
  box-shadow: rgb(0 0 0 / 10%) 0px 5px 20px 0px;
  padding: 0.4375rem 1.25rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.25rem;
  vertical-align: middle;
  text-align: center;
  color: #2550aa;
  background-color: #ffffff;
  border: 1px solid #2550aa;
  transition: all 0.3s ease 0s;
  text-transform: capitalize;
  min-height: 40px;
  width: 100% !important;

  :hover {
    transform: translateY(-5%);
  }
`;

const HideFiltersButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background: transparent;
  font-size: 1.4rem;
  color: #515365;

  svg {
    width: 20px;
    height: 20px;
    transition: transform 0.5s ease-in-out;
    transform: rotateZ(${({ rotate }) => (rotate ? '0deg' : '180deg')});
  }
`;

export const Area = ({
  handleChangeFrom,
  handleChangeTo,
  validateFrom,
  validateTo,
  aera,
  options,
}) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <Grid container spacing={0} alignItems={'center'}>
          <Grid item xs={9}>
            <FormAutoCompleteFreeSolo
              id="areaFrom"
              name="area"
              maxLength={8}
              value={aera.from && aera.from.toString()}
              onChange={(fieldName, value) =>
                handleChangeFrom(fieldName, value, options)
              }
              onBlur={(fieldName, value) =>
                validateFrom(fieldName, value, options)
              }
              error={aera.errorFrom}
              optionList={aera.fromOptions}
              placeholder="Od"
              thousandSeparator
            />
          </Grid>
          <Grid item xs={3}>
            <span style={{ marginLeft: 4 }}>
              m<sup>2</sup>
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={0} alignItems={'center'}>
          <Grid item xs={9}>
            <FormAutoCompleteFreeSolo
              id="areaTo"
              name="area"
              maxLength={8}
              onBlur={(fieldName, value) =>
                validateTo(fieldName, value, options)
              }
              error={aera.errorTo}
              value={aera.to && aera.to.toString()}
              optionList={aera.toOptions}
              onChange={(fieldName, value) =>
                handleChangeTo(fieldName, value, options)
              }
              placeholder="Do"
              thousandSeparator
            />
          </Grid>
          <Grid item xs={3}>
            <span style={{ marginLeft: 4 }}>
              m<sup>2</sup>
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const PricePerSquareMeter = ({
  handleChangeFrom,
  handleChangeTo,
  validateFrom,
  validateTo,
  pricePerSquareMeter,
  options,
}) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <Grid container spacing={0} alignItems={'center'}>
          <Grid item xs={7}>
            <FormAutoCompleteFreeSolo
              id="pricePerSquareMeterFrom"
              name="pricePerSquareMeter"
              maxLength={10}
              value={
                pricePerSquareMeter.from && pricePerSquareMeter.from.toString()
              }
              onChange={(fieldName, value) =>
                handleChangeFrom(fieldName, value, options)
              }
              optionList={pricePerSquareMeter.fromOptions}
              placeholder="Od"
              onBlur={(fieldName, value) =>
                validateFrom(fieldName, value, options)
              }
              error={pricePerSquareMeter.errorFrom}
              thousandSeparator
            />
          </Grid>
          <Grid item xs={5}>
            <span style={{ marginLeft: 4 }}>
              zł/m<sup>2</sup>
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={0} alignItems={'center'}>
          <Grid item xs={7}>
            <FormAutoCompleteFreeSolo
              id="pricePerSquareMeterTo"
              name="pricePerSquareMeter"
              maxLength={10}
              value={
                pricePerSquareMeter.to && pricePerSquareMeter.to.toString()
              }
              optionList={pricePerSquareMeter.toOptions}
              onChange={(fieldName, value) =>
                handleChangeTo(fieldName, value, options)
              }
              placeholder="Do"
              onBlur={(fieldName, value) =>
                validateTo(fieldName, value, options)
              }
              error={pricePerSquareMeter.errorTo}
              thousandSeparator
            />
          </Grid>
          <Grid item xs={5}>
            <span style={{ marginLeft: 4 }}>
              zł/m<sup>2</sup>
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const Price = ({
  handleChangeFrom,
  handleChangeTo,
  validateFrom,
  validateTo,
  price,
  options,
}) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <Grid container spacing={0} alignItems={'center'}>
          <Grid item xs={9}>
            <FormAutoCompleteFreeSolo
              id="priceFrom"
              name="price"
              maxLength={15}
              value={price.from && price.from.toString()}
              onChange={(fieldName, value) =>
                handleChangeFrom(fieldName, value, options)
              }
              optionList={price.fromOptions}
              placeholder="Od"
              onBlur={(fieldName, value) =>
                validateFrom(fieldName, value, options)
              }
              error={price.errorFrom}
              thousandSeparator
            />
          </Grid>
          <Grid item xs={3}>
            <span style={{ marginLeft: 4 }}>zł</span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={0} alignItems={'center'}>
          <Grid item xs={9}>
            <FormAutoCompleteFreeSolo
              id="priceTo"
              name="price"
              maxLength={15}
              value={price.to && price.to.toString()}
              optionList={price.toOptions}
              onChange={(fieldName, value) =>
                handleChangeTo(fieldName, value, options)
              }
              placeholder="Do"
              onBlur={(fieldName, value) =>
                validateTo(fieldName, value, options)
              }
              error={price.errorTo}
              thousandSeparator
            />
          </Grid>
          <Grid item xs={3}>
            <span style={{ marginLeft: 4 }}>zł</span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const DepositPrice = ({
  handleChangeFrom,
  handleChangeTo,
  validateFrom,
  validateTo,
  depositPrice,
  options,
}) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <Grid container spacing={0} alignItems={'center'}>
          <Grid item xs={9}>
            <FormAutoCompleteFreeSolo
              id="depositPriceFrom"
              name="depositPrice"
              maxLength={15}
              value={depositPrice.from && depositPrice.from.toString()}
              onChange={(fieldName, value) =>
                handleChangeFrom(fieldName, value, options)
              }
              optionList={depositPrice.fromOptions}
              placeholder="Od"
              onBlur={(fieldName, value) =>
                validateFrom(fieldName, value, options)
              }
              error={depositPrice.errorFrom}
              thousandSeparator
            />
          </Grid>
          <Grid item xs={3}>
            <span style={{ marginLeft: 4 }}>zł</span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={0} alignItems={'center'}>
          <Grid item xs={9}>
            <FormAutoCompleteFreeSolo
              id="depositPriceTo"
              name="depositPrice"
              maxLength={15}
              value={depositPrice.to && depositPrice.to.toString()}
              optionList={depositPrice.toOptions}
              onChange={(fieldName, value) =>
                handleChangeTo(fieldName, value, options)
              }
              placeholder="Do"
              onBlur={(fieldName, value) =>
                validateTo(fieldName, value, options)
              }
              error={depositPrice.errorTo}
              thousandSeparator
            />
          </Grid>
          <Grid item xs={3}>
            <span style={{ marginLeft: 4 }}>zł</span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const RentPrice = ({
  handleChangeFrom,
  handleChangeTo,
  validateFrom,
  validateTo,
  rentPrice,
  options,
}) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <Grid container spacing={0} alignItems={'center'}>
          <Grid item xs={9}>
            <FormAutoCompleteFreeSolo
              id="rentPriceFrom"
              name="rentPrice"
              maxLength={15}
              value={rentPrice.from && rentPrice.from.toString()}
              onChange={(fieldName, value) =>
                handleChangeFrom(fieldName, value, options)
              }
              optionList={rentPrice.fromOptions}
              placeholder="Od"
              onBlur={(fieldName, value) =>
                validateFrom(fieldName, value, options)
              }
              error={rentPrice.errorFrom}
              thousandSeparator
            />
          </Grid>
          <Grid item xs={3}>
            <span style={{ marginLeft: 4 }}>zł</span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={0} alignItems={'center'}>
          <Grid item xs={9}>
            <FormAutoCompleteFreeSolo
              id="rentPriceTo"
              name="rentPrice"
              maxLength={15}
              value={rentPrice.to && rentPrice.to.toString()}
              optionList={rentPrice.toOptions}
              onChange={(fieldName, value) =>
                handleChangeTo(fieldName, value, options)
              }
              placeholder="Do"
              onBlur={(fieldName, value) =>
                validateTo(fieldName, value, options)
              }
              error={rentPrice.errorTo}
              thousandSeparator
            />
          </Grid>
          <Grid item xs={3}>
            <span style={{ marginLeft: 4 }}>zł</span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const SavedFilter = ({
  savedFilter,
  handleChange,
  options,
  removeSavedFilter,
}) => {
  return (
    <SectionStyle>
      <Title>Zapisane filtry</Title>
      <Grid container spacing={0} style={{ flexGrow: 1, alignItems: 'center' }}>
        <Grid item xs={9}>
          <FormSelect
            name="savedFilter"
            value={savedFilter}
            onChange={handleChange}
            optionList={options}
            placeholder="Wybierz filtr"
          />
        </Grid>
        <Grid item xs={3}>
          <FormButtonStyle onClick={removeSavedFilter} color="primary">
            usuń
          </FormButtonStyle>
        </Grid>
      </Grid>
    </SectionStyle>
  );
};

export const FilterButtons = ({
  isHideFilters,
  hideFilters,
  clearFilters,
  handleSubmit,
  isAnalysis
}) => {
  return (
    <Grid
      container
      spacing={1}
      justify="flex-end"
      alignItems="center"
      style={{ marginTop: 5 }}
    >
      {!isAnalysis && hideFilters && (
        <Grid item xs={5} lg={4} md={3}>
          <HideFiltersButton
            type="button"
            onClick={hideFilters}
            rotate={isHideFilters}
          >
            {isHideFilters ? 'Więcej filtrów' : 'Mniej filtrów'}{' '}
            <ArrowDropDownIcon />
          </HideFiltersButton>
        </Grid>
      )}
      <Grid item>
        <ClearFiltersButton
          onClick={clearFilters}
          type="button"
          style={{ fontSize: 14 }}
        >
          Wyczyść filtry
        </ClearFiltersButton>
      </Grid>
      <Grid item xs={5} lg={2} md={3}>
        <Button height="big" type="button" onClick={handleSubmit}>
        {isAnalysis ? 'Analizuj' : 'Filtruj'}
        </Button>
      </Grid>
    </Grid>
  );
};
