import styled from 'styled-components';
import { Field } from 'react-final-form';

const Input = styled(Field)`
  padding: 5px 10px;
  font-size: ${({ theme }) => theme.font.lg};
  width: 100%;
  margin-bottom: 10px;
`;

export default Input;
