import React, { useState, useEffect, useRef } from 'react';
import html2canvas from 'html2canvas';
import Button from '../atom/Button';
import logo from '../../assets/icons/investoro_logo.png'
import logoPdf from '../../assets/icons/investoro_pdf_logo.png'
import { Document, Page, Text, View, StyleSheet,Image as PdfImage} from '@react-pdf/renderer';


import robotoFont from "../../assets/fonts/Roboto-Regular.ttf"
import interFont from "../../assets/fonts/Inter-Regular.ttf"
import { PDFDownloadLink, usePDF, Font} from '@react-pdf/renderer';
import styled from 'styled-components';
import Chart from 'chart.js/auto';
import {BarWithErrorBarsController, BarWithErrorBar } from 'chartjs-chart-error-bars';
Chart.register(BarWithErrorBarsController, BarWithErrorBar );

export const SectionStyle = styled.div`

margin-top: 20px;
margin-bottom: 20px;
padding: 30px 30px 30px;
overflow: hidden;
box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 5%);
background-color: white;
border-radius: 5px !important;
border: #e0e0e0 1px solid;


`;

export const ContainerStyle = styled.div`

display: flex;
justify-content: left;
`;
export const HeaderStyle = styled.div`

color: #3b3f5c !important;
font-size: 16px !important;
font-weight: 600;
margin: 0;
padding-left: 15px;
`;
export const DisclaimerStyle = styled.p`
font-size: 10px;

`;

export const TableContainerDiv = styled.div`
overflow: auto;

`;

export const TableStyle = styled.table`
table-layout:fixed;
width:98%;
`;



export const IndStyle = styled.span`
font-weight:400 !important;
font-size:14px !important;
padding-left: 10px;
color: #3b3f5c;
`;
export const IndConStyle = styled.span`

padding-top: 0px;
padding-top: 15px;
    padding-bottom: 15px;

`;
export const LabelStyle = styled.span`

height: 18px;
margin: 0;
`;




const AnalysisList = ({ timeAnalysiList = [], areaAnalysisList = [], trendResult, filters}) => {


  const [hideAreaCols, setHideAreaCols] = useState(true);
  const [hideTimeCols, setHideTimeCols] = useState(true);
  const [priceAreaChartImage, setPriceAreaChartImage] = useState(null);
  const [areaPieChartImage, setAreaPieChartImage] = useState(null);
  const [squareMeterPriceAreaChartImage, setSquareMeterPriceAreaChartImage] = useState(null);
  const [combinedPriceAreaChartImage, setCombinedPriceAreaChartImage] = useState(null);
  const [areaTableImage, setAreaTableImage] = useState(null);
  const [timeTableImage, setTimeTableImage] = useState(null);
  const [trendTableImage, setTrendTableImage] = useState(null);

  const [timePieChartImage, setTimePieChartImage] = useState(null);
  const [priceSquareMeterTimeChartImage, setPriceSquareMeterTimeChartImage] = useState(null);
  const [priceTimeChartImage, setPriceTimeChartImage] = useState(null);
  const [combinedPriceTimeChartImage, setCombinedPriceTimeChartImage] = useState(null);
  const [combinedPriceTimeAreaChartImage, setCombinedPriceTimeAreaChartImage] = useState(null);
  const [combinedPriceSquareMeterTimeAreaChartImage, setCombinedPriceSquareMeterTimeAreaChartImage] = useState(null);

  const [trendChartImage, setTrendChartImage] = useState(null);
  var today = new Date();
  var image = new Image();
  image.src = logo;
  Chart.defaults.font.family = "Inter";
useEffect(() => {
  let chartStatus = Chart.getChart("area-pie-chart");
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }
    
  var areaPieChart =  new Chart(document.getElementById("area-pie-chart"), {
    type: 'pie',
    data: {
      labels: areaAnalysisList.map(x => x.areaLabel).slice(0,-1),
      datasets: [{
        label: "",
        backgroundColor: ["#3e95cdE6", "#8e5ea2E6","#3cba9fE6","#e8c3b9E6","#c45850E6", "#c45850E6","#c45251E6","#b15231E6"],
        data: areaAnalysisList.map(x => x.transactionAmount).slice(0,-1),
      }]
    },
    options: {
      animation: {
        onComplete: function(){
          setAreaPieChartImage(areaPieChart.toBase64Image());         
        }
      },
      plugins:{
        title: {
           display: true,
           font: {
             size: 14
           },
           text: 'Liczba transakcji wg przedziałów powierzchni'
           },
        legend: {
          position: 'right',
          labels: {
            usePointStyle: true
          }
        }
       },
      responsive:true,
      maintainAspectRatio: false,
    },
    plugins:[{
      id: 'customImage',
      beforeDraw: (chart) => {
        if (image.complete) {
          const ctx = chart.ctx;
          const {
            width,
            height
          } = chart.chartArea;
          const x = width/2-width/4;
          const y = height/2;
          ctx.drawImage(image, x, y, width/2,((width/2) *image.height)/image.width);
        } else {
          image.onload = () => chart.draw();
        }
      }
  
    }]
});

  return () => {}
  }, [areaAnalysisList])

  useEffect(() => {
    let chartStatus = Chart.getChart("price-area-chart");
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }
   
    var priceAreaChart =  new Chart(document.getElementById("price-area-chart"), {
      type: 'bar',
    data: {
      labels: areaAnalysisList.map(x => x.areaLabel).slice(0,-1),
      datasets: [
        {
          label: "Śr. ceny całk. brutto [PLN]",
          backgroundColor: "#3e95cdE6",
          data: areaAnalysisList.map(x => x.avgPrice).slice(0,-1)
        }, {
          label: "Mediana ceny całk. brutto [PLN]",
          backgroundColor: "#8e5ea2E6",
          data: areaAnalysisList.map(x => x.medianPrice).slice(0,-1)
        }
      ]
    },
    
      options: {
        animation: {
          onComplete: function(){
            setPriceAreaChartImage(priceAreaChart.toBase64Image());            
          }
        },
        scales:{
          y: {
            title: {
              display: true,
              text: "Cena m2 brutto [PLN]"
            }
          },
        },
        plugins:{
          title: {
            font: {
              size: 14
            },
             display: true,
             text: 'Cena całkowita wg przedziałów powierzchni'
             },
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true
            }
          }
         },
        responsive:true,
        maintainAspectRatio: false,
      },
      plugins:[{
        id: 'customImage',
        beforeDraw: (chart) => {
          if (image.complete) {
            const ctx = chart.ctx;
            const {
              width,
              height
            } = chart.chartArea;
            const x = width/3;
            const y = height/2;
            ctx.drawImage(image, x, y, width/2,((width/2) *image.height)/image.width);
          } else {
            image.onload = () => chart.draw();
          }
        }
    
      }]
  });
    return () => {}
    }, [areaAnalysisList])
    useEffect(() => {
      let chartStatus = Chart.getChart("square-meter-price-area-chart");
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }
     var squareMeterPriceAreaChart =  new Chart(document.getElementById("square-meter-price-area-chart"), {
        type: 'bar',
      data: {
        labels: areaAnalysisList.map(x => x.areaLabel).slice(0,-1),
        datasets: [
          {
            label: "Śr. cena m2 brutto [PLN]",
            backgroundColor: "#3e95cdE6",
            data: areaAnalysisList.map(x => x.avgPricePerSquareMeter).slice(0,-1)
          }, {
            label: "Mediana ceny m2 brutto [PLN]",
            backgroundColor: "#8e5ea2E6",
            data: areaAnalysisList.map(x => x.medianPricePerSquareMeter).slice(0,-1)
          }
        ]
      },
  
        options: {
          animation: {
            onComplete: function(){
              setSquareMeterPriceAreaChartImage(squareMeterPriceAreaChart.toBase64Image());
              
            }
          },
          scales:{
            y: {
              title: {
                display: true,
                text: "Cena m2 brutto [PLN]"
              }
            },
          },
          plugins:{
            title: {
              font: {
                size: 14
              },
               display: true,
               text: 'Cena metra kwadratowego wg przedziałów powierzchni'
               },
            legend: {
              position: 'bottom',
              labels: {
                usePointStyle: true
              }
            }
           },
          responsive:true,
          maintainAspectRatio: false,
        },
        plugins:[{
          id: 'customImage',
          beforeDraw: (chart) => {
            if (image.complete) {
              const ctx = chart.ctx;
              const {
                width,
                height
              } = chart.chartArea;
              const x = width/3;
              const y = height/2;
              ctx.drawImage(image, x, y, width/2,((width/2) *image.height)/image.width);
            } else {
              image.onload = () => chart.draw();
            }
          }
      
        }]
    });
      return () => {}
      }, [areaAnalysisList])

      useEffect(() => {
        let chartStatus = Chart.getChart("combined-price-area-chart");
        if (chartStatus != undefined) {
          chartStatus.destroy();
        }
        var combinedPriceAreaChart =  new Chart(document.getElementById("combined-price-area-chart"), {
          type: 'bar',
        data: {
          labels: areaAnalysisList.map(x => x.areaLabel).slice(0,-1),
          datasets: [
            {
              label: "Śr. cena całkowita brutto [PLN]",
              type: "line",
              backgroundColor: "#8e5ea2",
              borderColor: "#8e5ea2",
              data: areaAnalysisList.map(x => x.avgPrice).slice(0,-1),
              yAxisID: 'y1',
            },
            {
              label: "Śr. cena m2 brutto [PLN]",
              type: "bar",
              backgroundColor: "#3e95cdE6",
              data: areaAnalysisList.map(x => x.avgPricePerSquareMeter).slice(0,-1),
              yAxisID: 'y',
            }
          ]
        },
    
          options: {
            animation: {
              onComplete: function(){
                setCombinedPriceAreaChartImage(combinedPriceAreaChart.toBase64Image());
                
              }
            },
            scales:{
              y: {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                  display: true,
                  text: "Cena m2 brutto [PLN]"
                }
              },
              y1: {
                type: 'linear',
                display: true,
                position: 'right',
                title: {
                  display: true,
                  text: "Cena całk. brutto [PLN]"
                }
              },
            },
            plugins:{
              title: {
                font: {
                  size: 14
                },
                 display: true,
                 text: 'Średnia cena metra kwadratowego oraz średnia cena całkowita wg przedziałów powierzchni'
                 },
              legend: {
                position: 'bottom',
                labels: {
                  usePointStyle: true
                }
              }
             },
            responsive:true,
            maintainAspectRatio: false,
          },
          plugins:[{
            id: 'customImage',
            beforeDraw: (chart) => {
              if (image.complete) {
                const ctx = chart.ctx;
                const {
                  width,
                  height
                } = chart.chartArea;
                const x = width/3;
                const y = height/2;
                ctx.drawImage(image, x, y, width/2,((width/2) *image.height)/image.width);
              } else {
                image.onload = () => chart.draw();
              }
            }
        
          }]
      });
        return () => {}
        }, [areaAnalysisList])

        useEffect(() => {
          let chartStatus = Chart.getChart("time-pie-chart");
          if (chartStatus != undefined) {
            chartStatus.destroy();
          }
          var timePieChart =  new Chart(document.getElementById("time-pie-chart"), {
            type: 'pie',
            data: {
              labels: timeAnalysiList.map(x => x.timeRangeLabel).slice(0,-1),
              datasets: [{
                label: "",
                backgroundColor: ["#3e95cdE6", "#8e5ea2E6","#3cba9fE6","#e8c3b9E6","#c45850E6", "#c45850E6","#c45251E6","b15231E6","#2ea5cdE6", "#2e6ea2E6","#ccbaffE6","#eac369E6","#c35a50E6", "#c25f50E6","#ef5a21E6","#d142d1E6"],
                data: timeAnalysiList.map(x => x.transactionAmount).slice(0,-1),
              }]
            },
            options: {
              animation: {
                onComplete: function(){
                  setTimePieChartImage(timePieChart.toBase64Image());
                }
              },
              plugins:{
                title: {
                  font: {
                    size: 14
                  },
                   display: true,
                   text: 'Liczba transakcji w okresach czasowych'
                   },
                legend: {
                  position: 'right',
                  labels: {
                    usePointStyle: true
                  }
                }
               },
              responsive:true,
              maintainAspectRatio: false,
            },
            plugins:[{
              id: 'customImage',
              beforeDraw: (chart) => {
                if (image.complete) {
                  const ctx = chart.ctx;
                  const {
                    width,
                    height
                  } = chart.chartArea;
                  const x = width/2 - width/4;
                  const y = height/2;
                  ctx.drawImage(image, x, y, width/2,((width/2) *image.height)/image.width);
                } else {
                  image.onload = () => chart.draw();
                }
              }
          
            }]
        });
          return () => {}
          }, [timeAnalysiList])

          useEffect(() => {
            let chartStatus = Chart.getChart("price-square-meter-time-chart");
            if (chartStatus != undefined) {
              chartStatus.destroy();
            }
            var priceSquareMeterTimeChart =  new Chart(document.getElementById("price-square-meter-time-chart"), {
              type: 'bar',
            data: {
              labels: timeAnalysiList.map(x => x.timeRangeLabel).slice(0,-1),
              datasets: [
                {
                  label: "Śr. cena m2 brutto [PLN]",
                  backgroundColor: "#3e95cdE6",
                  data: timeAnalysiList.map(x => x.avgPricePerSquareMeter).slice(0,-1)
                }
              ]
            },
        
              options: {
                animation: {
                  onComplete: function(){
                    setPriceSquareMeterTimeChartImage(priceSquareMeterTimeChart.toBase64Image());
                  }
                },
                scales:{
                  y: {
                    title: {
                      display: true,
                      text: "Cena m2 brutto [PLN]"
                    }
                  },
                },
                plugins:{
                  title: {
                    font: {
                      size: 14
                    },
                     display: true,
                     text: 'Cena metra kwadratowego w okresach czasowych'
                     },
                  legend: {
                    position: 'bottom',
                    labels: {
                      usePointStyle: true
                    }
                  }
                 },
                responsive:true,
                maintainAspectRatio: false,
              },
              plugins:[{
                id: 'customImage',
                beforeDraw: (chart) => {
                  if (image.complete) {
                    const ctx = chart.ctx;
                    const {
                      width,
                      height
                    } = chart.chartArea;
                    const x = width/3;
                    const y = height/2;
                    ctx.drawImage(image, x, y, width/2,((width/2) *image.height)/image.width);
                  } else {
                    image.onload = () => chart.draw();
                  }
                }
            
              }]
          });
            return () => {}
            }, [timeAnalysiList])
            useEffect(() => {
              let chartStatus = Chart.getChart("price-time-chart");
              if (chartStatus != undefined) {
                chartStatus.destroy();
              }
              var priceTimeChart =  new Chart(document.getElementById("price-time-chart"), {
                type: 'bar',
              data: {
                labels: timeAnalysiList.map(x => x.timeRangeLabel).slice(0,-1),
                datasets: [
                  {
                    label: "Śr. cena całk. brutto [PLN]",
                    backgroundColor: "#3e95cdE6",
                    data: timeAnalysiList.map(x => x.avgPrice).slice(0,-1)
                  }
                ]
              },
          
                options: {
                  animation: {
                    onComplete: function(){
                      setPriceTimeChartImage(priceTimeChart.toBase64Image());
                    }
                  },
                  scales:{
                    y: {
                      title: {
                        display: true,
                        text: "Cena całk. brutto [PLN]"
                      }
                    },
                  },
                  plugins:{
                    title: {
                      font: {
                        size: 14
                      },
                       display: true,
                       text: 'Cena całkowita w okresach czasowych'
                       },
                    legend: {
                      position: 'bottom',
                      labels: {
                        usePointStyle: true
                      }
                    }
                   },
                  responsive:true,
                  maintainAspectRatio: false,
                },
                plugins:[{
                  id: 'customImage',
                  beforeDraw: (chart) => {
                    if (image.complete) {
                      const ctx = chart.ctx;
                      const {
                        width,
                        height
                      } = chart.chartArea;
                      const x = width/3;
                      const y = height/2;
                      ctx.drawImage(image, x, y, width/2,((width/2) *image.height)/image.width);
                    } else {
                      image.onload = () => chart.draw();
                    }
                  }
              
                }]
            });
              return () => {}
              }, [timeAnalysiList])

              useEffect(() => {
                let chartStatus = Chart.getChart("combined-price-time-chart");
                if (chartStatus != undefined) {
                  chartStatus.destroy();
                }
                var combinedPriceTimeChart =  new Chart(document.getElementById("combined-price-time-chart"), {
                  type: 'bar',
                data: {
                  labels: timeAnalysiList.map(x => x.timeRangeLabel).slice(0,-1),
                  datasets: [
                    {
                      label: "Śr. cena całkowita brutto [PLN]",
                      type: "line",
                      backgroundColor: "#8e5ea2",
                      borderColor: "#8e5ea2",
                      data: timeAnalysiList.map(x => x.avgPrice).slice(0,-1),
                      yAxisID: 'y1',
                    },
                    {
                      label: "Śr. cena m2 brutto [PLN]",
                      type: "bar",
                      backgroundColor: "#3e95cdE6",
                      data: timeAnalysiList.map(x => x.avgPricePerSquareMeter).slice(0,-1),
                      yAxisID: 'y',
                    }
                  ]
                },
            
                  options: {
                    animation: {
                      onComplete: function(){
                        setCombinedPriceTimeChartImage(combinedPriceTimeChart.toBase64Image());
                      }
                    },
                    scales:{
                      y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        title: {
                          display: true,
                          text: "Cena m2 brutto [PLN]"
                        }
                      },
                      y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        title: {
                          display: true,
                          text: "Cena całk. brutto [PLN]"
                        }
                      },
                    },
                    plugins:{
                      title: {
                        font: {
                          size: 14
                        },
                         display: true,
                         text: 'Średnia cena metra kwadratowego oraz średnia cena całkowita w okresach czasowych'
                         },
                      legend: {
                        position: 'bottom',
                        labels: {
                          usePointStyle: true
                        }
                      }
                     },
                    responsive:true,
                    maintainAspectRatio: false,
                  },
                  plugins:[{
                    id: 'customImage',
                    beforeDraw: (chart) => {
                      if (image.complete) {
                        const ctx = chart.ctx;
                        const {
                          width,
                          height
                        } = chart.chartArea;
                        const x = width/3;
                        const y = height/2;
                        ctx.drawImage(image, x, y, width/2,((width/2) *image.height)/image.width);
                      } else {
                        image.onload = () => chart.draw();
                      }
                    }
                
                  }]
              });
                return () => {}
                }, [timeAnalysiList])
                useEffect(() => {
                  let chartStatus = Chart.getChart("combined-price-time-area-chart");
                  if (chartStatus != undefined) {
                    chartStatus.destroy();
                  }
                  var combinedPriceTimeAreaChart =  new Chart(document.getElementById("combined-price-time-area-chart"), {
                    type: 'bar',
                  data: {
                    labels: timeAnalysiList.map(x => x.timeRangeLabel).slice(0,-1),
                    datasets: [
                      {
                        label: "Śr. powierzchnia [m2]",
                        type: "line",
                        backgroundColor: "#8e5ea2",
                        borderColor: "#8e5ea2",
                        data: timeAnalysiList.map(x => x.avgArea).slice(0,-1),
                        yAxisID: 'y1',
                      },
                      {
                        label: "Śr. cena całk. brutto [PLN]",
                        type: "bar",
                        backgroundColor: "#3e95cdE6",
                        data: timeAnalysiList.map(x => x.avgPrice).slice(0,-1),
                        yAxisID: 'y',
                      }
                    ]
                  },
              
                    options: {
                      animation: {
                        onComplete: function(){
                          setCombinedPriceTimeAreaChartImage(combinedPriceTimeAreaChart.toBase64Image());
                        }
                      },
                      scales:{
                        y: {
                          type: 'linear',
                          display: true,
                          position: 'left',
                          title: {
                            display: true,
                            text: "Cena całk. brutto [PLN]"
                          }
                        },
                        y1: {
                          type: 'linear',
                          display: true,
                          position: 'right',
                          title: {
                            display: true,
                            text: "Powierzchnia [m2]"
                          }
                        },
                      },
                      plugins:{
                        title: {
                          font: {
                            size: 14
                          },
                           display: true,
                           text: 'Średnia cena całkowita oraz średnia powierzchnia w okresach czasowych'
                           },
                        legend: {
                          position: 'bottom',
                          labels: {
                            usePointStyle: true
                          }
                        }
                       },
                      responsive:true,
                      maintainAspectRatio: false,
                    },
                    plugins:[{
                      id: 'customImage',
                      beforeDraw: (chart) => {
                        if (image.complete) {
                          const ctx = chart.ctx;
                          const {
                            width,
                            height
                          } = chart.chartArea;
                          const x = width/3;
                          const y = height/2;
                          ctx.drawImage(image, x, y, width/2,((width/2) *image.height)/image.width);
                        } else {
                          image.onload = () => chart.draw();
                        }
                      }
                  
                    }]
                });
                  return () => {}
                  }, [timeAnalysiList])
                  useEffect(() => {
                    let chartStatus = Chart.getChart("combined-price-square-meter-time-area-chart");
                    if (chartStatus != undefined) {
                      chartStatus.destroy();
                    }
                    var combinedPriceSquareMeterTimeAreaChart =  new Chart(document.getElementById("combined-price-square-meter-time-area-chart"), {
                      type: 'bar',
                    data: {
                      labels: timeAnalysiList.map(x => x.timeRangeLabel).slice(0,-1),
                      datasets: [
                          {
                          label: "Śr. powierzchnia [m2]",
                          type: "line",
                          backgroundColor: "#8e5ea2",
                          borderColor: "#8e5ea2",
                          data: timeAnalysiList.map(x => x.avgArea).slice(0,-1),
                          yAxisID: 'y1',
                        },
                        {
                          label: "Śr. cena m2 brutto [PLN]",
                          type: "bar",
                          backgroundColor: "#3e95cdE6",
                          data: timeAnalysiList.map(x => x.avgPricePerSquareMeter).slice(0,-1),
                          yAxisID: 'y',
                        }
                      ]
                    },
                
                      options: {
                        animation: {
                          onComplete: function(){
                            setCombinedPriceSquareMeterTimeAreaChartImage(combinedPriceSquareMeterTimeAreaChart.toBase64Image());
                          }
                        },
                        scales:{
                          y: {
                            type: 'linear',
                            display: true,
                            position: 'left',
                            title: {
                              display: true,
                              text: "Cena m2 brutto [PLN]"
                            }
                          },
                          y1: {
                            type: 'linear',
                            display: true,
                            position: 'right',
                            title: {
                              display: true,
                              text: "Powierzchnia [m2]"
                            }
                          },
                        },
                        plugins:{
                          title: {
                            font: {
                              size: 14
                            },
                             display: true,
                             text: 'Średnia cena metra kwadratowego oraz średnia powierzchnia w okresach czasowych'
                             },
                          legend: {
                            position: 'bottom',
                            labels: {
                              usePointStyle: true
                            }
                          }
                         },
                        responsive:true,
                        maintainAspectRatio: false,
                      },
                      plugins:[{
                        id: 'customImage',
                        beforeDraw: (chart) => {
                          if (image.complete) {
                            const ctx = chart.ctx;
                            const {
                              width,
                              height
                            } = chart.chartArea;
                            const x = width/3;
                            const y = height/2;
                            ctx.drawImage(image, x, y, width/2,((width/2) *image.height)/image.width);
                          } else {
                            image.onload = () => chart.draw();
                          }
                        }
                    
                      }]
                  });
                    return () => {}
                    }, [timeAnalysiList])
                    function randomValues(count, min, max) {
                      const delta = max - min;
                      return Array.from({ length: count }).map(() => Math.random() * delta + min);
                    }
                    

                    useEffect(() => {
                    if(trendResult != null && timeAnalysiList.length > 1){
                      var trendData = [];
                     

                      var multiplier;
                      switch(filters[0].timeRange){
                            case('Miesiąc'):
                             multiplier = 30;
                             break;
                            case('Tydzień'):
                             multiplier = 7;
                             break;
                            case('Kwartał'):
                             multiplier = 120;
                             break;
                            case('Półrocze'):
                             multiplier = 182;
                             break;
                            case('Rok'):
                             multiplier = 365;
                             break;
                      }
                      for(let i=0; i < timeAnalysiList.length-1; i++){
                        trendData.push(Number(trendResult[3])*i*multiplier+Number(trendResult[4]));
                      }

                      let chartStatus = Chart.getChart("trend-chart");
                      if (chartStatus != undefined) {
                        chartStatus.destroy();
                      }
                      var trendChart =  new Chart(document.getElementById("trend-chart"), {
                        type: 'line',
                      data: {
                        labels: timeAnalysiList.map(x => x.timeRangeLabel).slice(0,-1),
                        datasets: [
                
                          {
                            label: "Linia trendu",
                            backgroundColor: "#2550aa",
                            borderColor:"#2550aa",
                            type: "lineWithErrorBars",
                            data: timeAnalysiList.slice(0,-1).map(function(x,i) { return {y: trendData[i], yMin: [x.minPricePerSquareMeter, x.avgPricePerSquareMeter], yMax: [x.maxPricePerSquareMeter,x.avgPricePerSquareMeter]}})
                          },
                         
                        ]
                      },
                  
                        options: {
                          animation: {
                            onComplete: function(){
                              setTrendChartImage(trendChart.toBase64Image());
                            }
                          },
                          scales:{
                            y: {
                              type: 'linear',
                              display: true,
                              position: 'left',
                              title: {
                                display: true,
                                text: "Cena m2 brutto [PLN]"
                              }
                            },
                           
                          },
                          plugins:{
                            title: {
                              font: {
                                size: 14
                              },
                               display: true,
                               text: 'Trend ceny metra kwadratowego'
                               },
                            legend: {
                              position: 'bottom',
                              labels: {
                                usePointStyle: true
                              }
                            }
                           },
                          responsive:true,
                          maintainAspectRatio: false,
                        },
                        plugins:[{
                          id: 'customImage',
                          beforeDraw: (chart) => {
                            if (image.complete) {
                              const ctx = chart.ctx;
                              const {
                                width,
                                height
                              } = chart.chartArea;
                              const x = width/3;
                              const y = height/2;
                              ctx.drawImage(image, x, y, width/2,((width/2) *image.height)/image.width);
                            } else {
                              image.onload = () => chart.draw();
                            }
                          }
                      
                        }]
                    }); }
                      return () => {}
                      }, [trendResult])

                      Font.register({ family: 'Roboto', src: robotoFont });
                      Font.register({ family: 'Inter', src: interFont });
                      const styles = StyleSheet.create({
                        page: {
                          backgroundColor: 'white',
                        },
                        flexRow: {
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          margin: 50


                        },
                        chart: {
                          width: "470px",
                          height:"200px",
                          backgroundColor: 'white',
                          marginTop: 15,
                          
                        },
                        logo: {
                          width: "169px",
                          height:"50px",
                          marginTop:20,
                          marginHorizontal: 50,
                        },
                        sectionTable: {
                          flexBasis: "100%",
                          textAlign: "center",
                          marginTop: 15,

                        },
                        tableBig:{
                          width:"100%",
                        },
                        tableSmall:{
                          width:"60%",
                        },
                        header:{
                          marginTop:20,
                          marginBottom:20,
                          fontSize: 10,
                          textAlign:"center",
                          fontFamily: 'Inter'
                        },
                        normalText:{
                          fontSize: 10,
                          marginLeft:50,
                          fontFamily: 'Inter'

                        },
                        titleText:{
                          fontSize: 14,
                          marginLeft:50,
                          fontFamily: 'Inter'

                        },
                        lineTop: {
                          borderTopWidth: 1,
                          borderTopColor: "black",
                          borderTopStyle: "solid",
                          marginHorizontal: 50,
                          paddingVertical:10,
                          bottom:"30",
                          position:"absolute"

                        },
                        lineBottom: {
                          borderBottomWidth: 1,
                          borderBottomColor: "black",
                          borderBottomStyle: "solid",
                          marginHorizontal: 50,
                          paddingVertical:10
                          
                        },

                        
                        
                      });
                      
                      // Create Document Component
                      const AnalysisDocument = () => (
                        
                        <Document>
                          <Page size="A4" style={styles.page}>
                          
                          <View  fixed>
                            <PdfImage style={[ styles.logo]} src={logoPdf}></PdfImage>
                             <hr style={[styles.lineBottom]}></hr>
                            </View>
                            <View style={[styles.header]}>                        
                                 <Text>Analiza mieszań na podstawie cen ofertowych</Text>
                                 <Text>Raport z dnia {  today.getDate()  + '.' + (today.getMonth() + 1)+ '.' + today.getFullYear() + 'r.'}</Text>
                              </View>
                            {areaAnalysisList.length > 0 &&
                            <>
                            <Text style={styles.titleText}>Analiza według przedziałów powierzchni: </Text>
                            <View style={styles.flexRow}>
                              <View style={styles.sectionTable}>                        
                                 <PdfImage  style={hideAreaCols ? styles.tableSmall : styles.tableBig} src={ areaTableImage}></PdfImage>
                              </View>
                            </View>
                            <View style={styles.flexRow}>
                              <View style={styles.section}>

                                <PdfImage style={styles.chart} src={ areaPieChartImage}></PdfImage>
                              </View>
                           
                            </View>

                            <View style={styles.flexRow}>
                              <View style={styles.section}>
                                <PdfImage style={styles.chart} src={ priceAreaChartImage}></PdfImage>
                              </View>
                            </View>
                            <View style={styles.flexRow}>
                              <View style={styles.section}>
                                <PdfImage style={styles.chart} src={ squareMeterPriceAreaChartImage}></PdfImage>
                              </View>
                            </View>                            
                            <View style={styles.flexRow}>
                              <View style={styles.section}>
                                <PdfImage style={styles.chart} src={ combinedPriceAreaChartImage}></PdfImage>
                              </View>
                            </View></>}


                            {timeAnalysiList.length > 0 &&
                            <>
                            <Text style={styles.titleText}>Analiza według czasu: </Text>
                            <View style={styles.flexRow}>
                              <View style={styles.sectionTable}>                        
                                 <PdfImage  style={hideTimeCols ? styles.tableSmall : styles.tableBig} src={ timeTableImage}></PdfImage>
                              </View>
                            </View>
                            <View style={styles.flexRow}>
                              <View style={styles.section}>
                                <PdfImage style={styles.chart} src={ timePieChartImage}></PdfImage>
                              </View>
                           
                            </View>

                            <View style={styles.flexRow}>
                              <View style={styles.section}>
                                <PdfImage style={styles.chart} src={ priceSquareMeterTimeChartImage}></PdfImage>
                              </View>
                            </View>
                            <View style={styles.flexRow}>
                              <View style={styles.section}>
                                <PdfImage style={styles.chart} src={ priceTimeChartImage}></PdfImage>
                              </View>
                            </View>                            
                            <View style={styles.flexRow}>
                              <View style={styles.section}>
                                <PdfImage style={styles.chart} src={ combinedPriceTimeChartImage}></PdfImage>
                              </View>
                            </View>
                            <View style={styles.flexRow}>
                              <View style={styles.section}>
                                <PdfImage style={styles.chart} src={ combinedPriceTimeAreaChartImage}></PdfImage>
                              </View>
                            </View>
                            <View style={styles.flexRow}>
                              <View style={styles.section}>
                                <PdfImage style={styles.chart} src={ combinedPriceSquareMeterTimeAreaChartImage}></PdfImage>
                              </View>
                            </View>

                            {areaAnalysisList.length > 0 &&
                            <>
                            <Text style={styles.titleText}>Analiza według trendu: </Text>
                            <View style={styles.flexRow}>
                              <View style={styles.sectionTable}>                        
                                 <PdfImage  style={styles.tableBig} src={ trendTableImage}></PdfImage>
                              </View>
                            </View>
                            <View style={styles.flexRow}>
                              <View style={styles.section}>
                                <PdfImage style={styles.chart} src={ trendChartImage}></PdfImage>
                              </View>                          
                            </View></>}
                            
                            </>}
                            {filters &&
                            <>
                            <Text style={styles.normalText}>Parametry filtra:</Text>
                            <Text style={styles.normalText}>Miasto: {filters[1]}</Text>
                            <Text style={styles.normalText}>Data dodania oferty: {filters[0].daysAmount == -1 ? "Wszystkie" : (filters[0].daysAmount + " dni")} </Text>
                            {(filters[0].priceFrom || filters[0].priceTo) && <><Text style={styles.normalText}>Cena: { filters[0].priceFrom ? ("od " + filters[0].priceFrom ) : ""} 
                            {filters[0].priceTo ? (" do " + filters[0].priceTo) : ""}</Text></>}
                            {(filters[0].pricePerSquareMeterFrom || filters[0].pricePerSquareMeterTo) && <><Text style={styles.normalText}>Cena za metr kwadratowy: { filters[0].pricePerSquareMeterFrom ? ("od " + filters[0].pricePerSquareMeterFrom ) : ""} 
                            {filters[0].pricePerSquareMeterTo ? (" do " + filters[0].pricePerSquareMeterTo) : ""}</Text></>}
                            {(filters[0].numberOfRoomsFrom || filters[0].numberOfRoomsTo) && <><Text style={styles.normalText}>Liczba pokoi: { filters[0].numberOfRoomsFrom ? ("od " + filters[0].numberOfRoomsFrom ) : ""} 
                            {filters[0].numberOfRoomsTo ? (" do " + filters[0].numberOfRoomsTo) : ""}</Text></>}
                            {(filters[0].areaFrom || filters[0].areaTo) && <><Text style={styles.normalText}>Metraż: { filters[0].areaFrom ? ("od " + filters[0].areaFrom ) : ""} 
                            {filters[0].areaTo ? (" do " + filters[0].areaTo) : ""}</Text></>}
                            {(filters[0].floorFrom || filters[0].floorTo) && <><Text style={styles.normalText}>Piętro: { filters[0].floorFrom ? ("od " + filters[0].floorFrom ) : ""} 
                            {filters[0].floorTo ? (" do " + filters[0].floorTo) : ""}</Text></>}
                            {(filters[0].type) && <><Text style={styles.normalText}>Typ nieruchomości: {filters[0].type.map(item => {return(<Text style={styles.normalText}>{item + ", "}</Text>)})}</Text></>}
                            {(filters[0].offerFrom) && <><Text style={styles.normalText}>Oferta od: {filters[0].offerFrom}</Text></>}
                            {(filters[0].wantedKeywords) && <><Text style={styles.normalText}>Słowa kluczowe pożądane: {filters[0].wantedKeywords}</Text></>}
                            {(filters[0].unwantedKeywords) && <><Text style={styles.normalText}>Słowa kluczowe niepożądane: {filters[0].unwantedKeywords}</Text></>}
                            {(filters[0].builtYearFrom || filters[0].builtYearTo) && <><Text style={styles.normalText}>Rok budowy: { filters[0].builtYearFrom ? ("od " + filters[0].builtYearFrom ) : ""} 
                            {filters[0].builtYearTo ? (" do " + filters[0].builtYearTo) : ""}</Text></>}
                            {(filters[0].buildingType) && <><Text style={styles.normalText}>Rodzaj budynku: {filters[0].buildingType.map(item => {return(<Text style={styles.normalText}>{item + ", "}</Text>)})}</Text></>}
                            {(filters[0].typeOfMarket) && <><Text style={styles.normalText}>Rynek: {filters[0].typeOfMarket}</Text></>}
                            {(filters[0].timeRange) && <><Text style={styles.normalText}>Przedział: {filters[0].timeRange}</Text></>}
                            {(filters[0].boundaries) && <><Text style={styles.normalText}>Granice przedziałów: {filters[0].boundaries}</Text></>}
                            {(filters[0].reportType) && <><Text style={styles.normalText}>Rodzaje raportu: {filters[0].reportType.map(item => {return(<Text style={styles.normalText}>{item + ", "}</Text>)})}</Text></>}

                            </>
                            }
                            <Text style={[styles.header, styles.lineTop]} render={({ pageNumber, totalPages }) => (
                             `Data ${  today.getDate()  + '.' + (today.getMonth() + 1)+ '.' + today.getFullYear() + 'r.'}                                                                                                                                          Strona ${pageNumber}`
                             )} fixed />

                          </Page>
                        </Document>
                      );
                  
                    useEffect(() => {
                    html2canvas(document.querySelector("#area-table")).then(canvas => {
                      const imgData = canvas.toDataURL('image/jpeg');
                      setAreaTableImage(imgData);
                    });
                    },
                    
                    [areaAnalysisList, hideAreaCols]) 

                    useEffect(() => {
                      html2canvas(document.querySelector("#time-table")).then(canvas => {
                        const imgData = canvas.toDataURL('image/jpeg');
                        setTimeTableImage(imgData);
                      });
                      },
                      
                      [timeAnalysiList, hideTimeCols]) 
                    useEffect(() => {
                      html2canvas(document.querySelector("#trend-table")).then(canvas => {
                        const imgData = canvas.toDataURL('image/jpeg');
                        setTrendTableImage(imgData);
                      });
                      },
                      
                      [trendResult]) 
                        

      

  //   const printDocument = () => {
  //   const pdf = new jsPDF('l', 'pt', 'a4', false);
  //   html2canvas(document.querySelector("#analysis-filters")).then(canvas => {
  //       const imgData = canvas.toDataURL('image/png');
  //       pdf.addImage(imgData, 'PNG', 0, 0, 800, 0, undefined, false);
  //       html2canvas(document.querySelector("#analysis-report")).then(canvas => {
  //           const imgData2 = canvas.toDataURL('image/png');
  //           pdf.addPage();
  //           pdf.addImage(imgData2, 'PNG', 0, 0, 800, 0, undefined, false);
  //           pdf.save("download.pdf"); 
  //       });
  //   });

 
  
    
  // };


  return (
    
      <div>
    
    <ContainerStyle>
      <div id="analysis-report" >  
      {((priceAreaChartImage && areaPieChartImage && combinedPriceAreaChartImage && squareMeterPriceAreaChartImage &&
      timePieChartImage && priceSquareMeterTimeChartImage && priceTimeChartImage && combinedPriceTimeChartImage && combinedPriceTimeAreaChartImage && combinedPriceSquareMeterTimeAreaChartImage &&
      trendChartImage)
      || (priceAreaChartImage && areaPieChartImage && combinedPriceAreaChartImage && squareMeterPriceAreaChartImage && timePieChartImage && priceSquareMeterTimeChartImage && priceTimeChartImage && combinedPriceTimeChartImage && combinedPriceTimeAreaChartImage && combinedPriceSquareMeterTimeAreaChartImage)
      || (priceAreaChartImage && areaPieChartImage && combinedPriceAreaChartImage && squareMeterPriceAreaChartImage && trendChartImage)
      || (timePieChartImage && priceSquareMeterTimeChartImage && priceTimeChartImage && combinedPriceTimeChartImage && combinedPriceTimeAreaChartImage && combinedPriceSquareMeterTimeAreaChartImage && trendChartImage)
      || (priceAreaChartImage && areaPieChartImage && combinedPriceAreaChartImage && squareMeterPriceAreaChartImage)
      || (timePieChartImage && priceSquareMeterTimeChartImage && priceTimeChartImage && combinedPriceTimeChartImage && combinedPriceTimeAreaChartImage && combinedPriceSquareMeterTimeAreaChartImage)
      || trendChartImage
      ) && 
      <PDFDownloadLink document={<AnalysisDocument />} fileName={"FileName"}> 
        <Button height="big" type="button" width="small" > Eksportuj do PDF </Button> 
      </PDFDownloadLink>}
      <div>
   {(areaAnalysisList.length > 0) && (<SectionStyle>
    <div class="row">
      
    <div className="App">


    </div>
    <div class="col-xl-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">
      <HeaderStyle>
    <div>Raport wg przedziałów powierzchni</div>
    </HeaderStyle>
    </div>
    <IndConStyle>
      <div class="col-xl-12 col-md-12 col-sm-12 col-12">
        <LabelStyle>
        <label class="new-control new-checkbox checkbox-primary">
          <input type="checkbox" class="new-control-input todochkbox" id="todoAll" checked={!hideAreaCols}  onClick={() => setHideAreaCols(!hideAreaCols)}></input>
          <span class="new-control-indicator"></span>
          <IndStyle>
          <sapn>Więcej danych</sapn>
          </IndStyle>
        </label>
        </LabelStyle>
      </div>
      </IndConStyle>
    </div>
    <TableContainerDiv>
    <div>
    <TableStyle>
    <table id="area-table" class="table-sm table-deal kv-grid-table table-analysis  table-striped table-condensed kv-table-wrap mb-4 table-layout: fixed table-hover ">
    <thead>
      <tr>
        <th>Powierzchnia</th>
        <th>Liczba Ofert</th>
        <th >Średnia cena m2</th>
        {!hideAreaCols &&<th>Mininimalna cena m2</th>}
        {!hideAreaCols &&<th>Maksymalna cena m2</th>}
        {!hideAreaCols &&<th>Mediana ceny m2</th>}
        {!hideAreaCols &&<th>Odchylenie standardowe ceny m2 </th>}
        <th>Średnia cena całkowita</th>
        {!hideAreaCols &&<th>Minimalna cena całkowita</th>}
        {!hideAreaCols &&<th>Maksymalna cena całkowita</th>}
        {!hideAreaCols &&<th>Mediana ceny całkowitej</th>}
        {!hideAreaCols &&<th>Odchylenie standardowe ceny całkowitej</th>}
      </tr>
    </thead>
    <tbody>
      {areaAnalysisList.map(item => {
        return (
          <tr key={item.areaLabel}>
            <td>{ item.areaLabel}</td>
            <td>{ item.transactionAmount.toLocaleString('fr', {useGrouping:true}) }</td>
            <td>{ item.avgPricePerSquareMeter.toLocaleString('fr', {useGrouping:true}) }</td>
            {!hideAreaCols &&<td>{ item.minPricePerSquareMeter.toLocaleString('fr', {useGrouping:true}) }</td>}
            {!hideAreaCols &&<td>{ item.maxPricePerSquareMeter.toLocaleString('fr', {useGrouping:true}) }</td>}
            {!hideAreaCols && <td>{ item.medianPricePerSquareMeter.toLocaleString('fr', {useGrouping:true}) }</td>}
            {!hideAreaCols &&<td>{ item.sdPricePerSquareMeter.toLocaleString('fr', {useGrouping:true}) }</td>}
            <td>{ item.avgPrice.toLocaleString('fr', {useGrouping:true}) }</td>
            {!hideAreaCols &&<td>{ item.minPrice.toLocaleString('fr', {useGrouping:true}) }</td>}
            {!hideAreaCols &&<td>{ item.maxPrice.toLocaleString('fr', {useGrouping:true}) }</td>}
            {!hideAreaCols &&<td>{ item.medianPrice.toLocaleString('fr', {useGrouping:true}) }</td>}
            {!hideAreaCols &&<td>{ item.sdPrice.toLocaleString('fr', {useGrouping:true}) }</td>}
          </tr>
        );
      })}
    </tbody>

  </table>
  <DisclaimerStyle>
  <p>*Puste przedziały nie są wyświetlane.</p>
  </DisclaimerStyle>
  </TableStyle>
  </div>
  </TableContainerDiv>
  <div class="row justify-content-center">
    <div class="chart-container column">
      <canvas id="area-pie-chart"></canvas>
    </div>
    <div class="chart-container column">
      <canvas  id="square-meter-price-area-chart"></canvas>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="chart-container column">
      <canvas id="price-area-chart"></canvas>
    </div>
    <div class="chart-container column">
      <canvas id="combined-price-area-chart"></canvas>
    </div>
  </div>

  </SectionStyle> ) }
  </div>

  

<div>
{(timeAnalysiList.length > 0) && (<SectionStyle>
  <div class="row">
    <div class="col-xl-12 col-md-12 col-sm-12 col-12  pl-0 pr-0">
      <HeaderStyle>
  <div>Raport wg czasu</div>
  </HeaderStyle>
    </div>
    <IndConStyle>
      <div class="col-xl-12 col-md-12 col-sm-12 col-12 ">
        <LabelStyle>
        <label class="new-control new-checkbox checkbox-primary">
          <input type="checkbox" class="new-control-input todochkbox" id="todoAll" checked={!hideTimeCols} onClick={() => setHideTimeCols(!hideTimeCols)}></input>
          <span class="new-control-indicator"></span>
          <IndStyle>
          <sapn>Więcej danych</sapn>
          </IndStyle>
        </label>
        </LabelStyle>
      </div>
      </IndConStyle>
    </div>
    <TableContainerDiv>
    <div>
    <TableStyle>
    <table id="time-table" class="table-sm table-deal kv-grid-table table-analysis  table-striped table-condensed kv-table-wrap mb-4 table-layout: fixed table-hover ">
 <thead>
   <tr>
     <th>Okres</th>
     <th colspan="2">Liczba Ofert</th>
     <th colspan="2">Średnia<br></br> powierzchnia m2</th>
     {!hideTimeCols && <th colspan="2">Minimalna powierzchnia m2</th>}
     {!hideTimeCols && <th colspan="2">Maksymalna powierzchnia m2</th>}
     {!hideTimeCols && <th colspan="2">Odchylenie standardowe powierzchni</th>}
     <th colspan="2">Średnia cena m2</th>
     {!hideTimeCols && <th colspan="2">Mininimalna cena m2</th>}
     {!hideTimeCols && <th colspan="2">Maksymalna cena m2</th>}
     {!hideTimeCols && <th colspan="2">Mediana ceny m2</th>}
     {!hideTimeCols && <th colspan="2">Odchylenie standardowe ceny m2 </th>}
     <th colspan="2">Średnia cena całkowita</th>
     {!hideTimeCols && <th colspan="2">Minimalna cena całkowita</th>}
     {!hideTimeCols && <th colspan="2">Maksymalna cena całkowita</th>}
     {!hideTimeCols && <th colspan="2">Mediana ceny całkowitej</th>}
     {!hideTimeCols && <th colspan="2">Odchylenie standardowe ceny całkowitej</th>}

   </tr>
 </thead>
 <tbody>
   {timeAnalysiList.map((item,index) => {
     if(item.growthList){
      item.growthList.forEach(element => {
        element.first = element.first > 10000 ? "∞" : element.first;
      })
     }
     return (
       <tr key={item.timeRangeLabel}>
         <td>{ item.timeRangeLabel}</td>
         <td class="border-td-none">{ item.transactionAmount.toLocaleString('fr', {useGrouping:true}) }</td>
         <td className={item.growthList && (item.growthList[0].second ? 'growthGreen' : 'growthRed')}>{item.growthList ? item.growthList[0].first + "%": ""} </td>
         <td class="border-td-none">{ item.avgArea.toLocaleString('fr', {useGrouping:true}) }</td>
         <td className={item.growthList && (item.growthList[1].second ? 'growthGreen' : 'growthRed')}>{item.growthList ? item.growthList[1].first + "%": ""}</td>
         {!hideTimeCols && <td class="border-td-none">{ item.minArea.toLocaleString('fr', {useGrouping:true}) }</td> }
         {!hideTimeCols && <td className={item.growthList && (item.growthList[2].second ? 'growthGreen' : 'growthRed')}>{item.growthList ? item.growthList[2].first + "%": ""}</td> }
         {!hideTimeCols && <td class="border-td-none">{ item.maxArea.toLocaleString('fr', {useGrouping:true}) }</td>}
         {!hideTimeCols && <td className={item.growthList && (item.growthList[3].second ? 'growthGreen' : 'growthRed')}>{item.growthList ? item.growthList[3].first + "%": ""}</td> }
         {!hideTimeCols && <td class="border-td-none">{ item.sdArea.toLocaleString('fr', {useGrouping:true}) }</td>}
         {!hideTimeCols && <td className={item.growthList && (item.growthList[4].second ? 'growthGreen' : 'growthRed')}>{item.growthList ? item.growthList[4].first + "%": ""}</td> }
         <td class="border-td-none">{ item.avgPricePerSquareMeter.toLocaleString('fr', {useGrouping:true}) }</td>
         <td className={item.growthList && (item.growthList[5].second ? 'growthGreen' : 'growthRed')}>{item.growthList ? item.growthList[5].first + "%": ""}</td>
         {!hideTimeCols && <td class="border-td-none">{ item.minPricePerSquareMeter.toLocaleString('fr', {useGrouping:true}) }</td>}
         {!hideTimeCols && <td className={item.growthList && (item.growthList[6].second ? 'growthGreen' : 'growthRed')}>{item.growthList ? item.growthList[6].first + "%": ""}</td> }
         {!hideTimeCols && <td class="border-td-none">{ item.maxPricePerSquareMeter.toLocaleString('fr', {useGrouping:true}) }</td>}
         {!hideTimeCols && <td className={item.growthList && (item.growthList[7].second ? 'growthGreen' : 'growthRed')}>{item.growthList ? item.growthList[7].first + "%": ""}</td> }
         {!hideTimeCols && <td class="border-td-none">{ item.medianPricePerSquareMeter.toLocaleString('fr', {useGrouping:true}) }</td>}
         {!hideTimeCols && <td className={item.growthList && (item.growthList[8].second ? 'growthGreen' : 'growthRed')}>{item.growthList ? item.growthList[8].first + "%": ""}</td> }
         {!hideTimeCols && <td class="border-td-none">{ item.sdPricePerSquareMeter.toLocaleString('fr', {useGrouping:true}) }</td>}
         {!hideTimeCols && <td className={item.growthList && (item.growthList[9].second ? 'growthGreen' : 'growthRed')}>{item.growthList ? item.growthList[9].first + "%": ""}</td> }
         <td class="border-td-none">{ item.avgPrice.toLocaleString('fr', {useGrouping:true})  }</td> <td className={item.growthList && (item.growthList[10].second ? 'growthGreen' : 'growthRed')}>{item.growthList ? item.growthList[10].first + "%": ""}</td>
         {!hideTimeCols && <td class="border-td-none">{ item.minPrice.toLocaleString('fr', {useGrouping:true}) }</td>}
         {!hideTimeCols && <td className={item.growthList && (item.growthList[11].second ? 'growthGreen' : 'growthRed')}>{item.growthList ? item.growthList[11].first + "%": ""}</td> }
         {!hideTimeCols && <td class="border-td-none">{ item.maxPrice.toLocaleString('fr', {useGrouping:true}) }</td>}
         {!hideTimeCols && <td className={item.growthList && (item.growthList[12].second ? 'growthGreen' : 'growthRed')}>{item.growthList ? item.growthList[12].first + "%": ""}</td> }
         {!hideTimeCols && <td class="border-td-none">{ item.medianPrice.toLocaleString('fr', {useGrouping:true}) }</td>}
         {!hideTimeCols && <td className={item.growthList && (item.growthList[13].second ? 'growthGreen' : 'growthRed')}>{item.growthList ? item.growthList[13].first + "%": ""}</td> }
         {!hideTimeCols && <td class="border-td-none">{ item.sdPrice.toLocaleString('fr', {useGrouping:true}) }</td>}
         {!hideTimeCols && <td className={item.growthList && (item.growthList[14].second ? 'growthGreen' : 'growthRed')}>{item.growthList ? item.growthList[14].first + "%": ""}</td> }
       </tr>
     );
   })}
 </tbody>
</table>
<DisclaimerStyle>
<p>*Puste przedziały nie są wyświetlane.</p>
</DisclaimerStyle>
</TableStyle>
</div>
</TableContainerDiv>
<div class="row justify-content-center">
  <div class="chart-container column">
      <canvas class="chart-watermark" id="time-pie-chart"></canvas>
  </div>
  <div class="chart-container column">
      <canvas id="price-square-meter-time-chart"></canvas>
  </div>
</div>
<div class="row justify-content-center">
  <div class="chart-container column">
      <canvas id="price-time-chart"></canvas>
  </div>
  <div class="chart-container column">
      <canvas id="combined-price-time-chart"></canvas>
  </div>
</div>
<div class="row justify-content-center">
  <div class="chart-container column">
      <canvas id="combined-price-square-meter-time-area-chart"></canvas>
  </div>
  <div class="chart-container column">
      <canvas id="combined-price-time-area-chart"></canvas>
  </div>
</div>



</SectionStyle> ) }
</div>

<div>
{trendResult && trendResult.length > 0 && (<SectionStyle>
  <div class="row">
    <div class="col-xl-12 col-md-12 col-sm-12 col-12">
      <HeaderStyle>
    <div>Raport wg trendu</div>
    </HeaderStyle>
    </div>
    </div>
    <div class="row text-center">
    <div class="col-12 mw-1000px m-auto mt-5">
    <div id="trend-table" class="row text-center">
            <div class="col">
                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4">
                    <div class="fs-6 fw-boldest text-gray-700">{trendResult[0]}</div>
                    <div class="fw-bold text-gray-400">trend miesięczny</div>
                </div>
            </div>
            <div class="col">
                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4">
                    <div class="fs-6 fw-boldest text-gray-700">{trendResult[1]}</div>
                    <div class="fw-bold text-gray-400">trend kwartalny</div>
                </div>

            </div>
            <div class="col">
                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4">
                    <div class="fs-6 fw-boldest text-gray-700">{trendResult[2]}</div>
                    <div class="fw-bold text-gray-400">trend roczny</div>
                </div>

            </div>
        </div>
        <div class="row justify-content-center">
        <div class="chart-container column">
          <canvas id="trend-chart"></canvas>
        </div>
        </div>
      </div>
      </div>
     

    </SectionStyle>
)}
</div>


</div>
</ContainerStyle>
</div>
  );
};

export default AnalysisList;
