export const dateFormat = date => {
  return (
    date.getFullYear() +
    '-' +
    ('0' + (date.getMonth() * 1 + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2)
  );
};

export const dateFromFunc = date => {
  const dFrom = new Date(date);
  return dFrom;
};

export const dateToFunc = date => {
  const dTo = new Date(date);
  return dTo;
};
