import React, { Component, memo } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

import { API, redirect } from '../API';
import HeaderSection from '../components/HeaderSection';
import TableOfCooperatives from '../components/cooperatives/TableOfCooperatives';
import FiltrButton from '../components/FilterButton';
import { activeNotification } from '../components/functions/activeNotification';
import Indicator from '../components/atom/Indicator';

import {
  comparePriceAscending,
  comparePriceDescending,
  compareDateAscending,
  compareDateDescending,
  comparePricePerSquareMeterAscending,
  comparePricePerSquareMeterDescending,
} from '../components/Sort';
import { like } from '../components/Reactions/Like';
import ListOfPages from '../components/cooperatives/ListOfPages';

const Content = memo(
  ({
    cooperativesList,
    handleChangeSort,
    handleChangeNotes,
    handleChangeFavorite,
    sortType,
    checkedFavorite,
    checkedNotes,
    setFavourite,
    setNote,
    pagesList,
    sortFromOptions,
    sortPlaceholder,
  }) => {
    return (
      <>
        <HeaderSection
          list={cooperativesList}
          handleChangeSort={handleChangeSort}
          handleChangeNotes={handleChangeNotes}
          handleChangeFavorite={handleChangeFavorite}
          sortType={sortType}
          checkedFavorite={checkedFavorite}
          checkedNotes={checkedNotes}
          sortOptions={sortFromOptions}
          sortPlaceholder={sortPlaceholder}
        />
        <TableOfCooperatives
          list={cooperativesList}
          setFavourite={setFavourite}
          setNote={setNote}
        />

      </>
    );
  }
);

class Cooperatives extends Component {
  state = {
    defaultOoperativesList: [],
    cooperativesList: [],
    pagesList: [],
    sortType: '',
    checkedFavorite: false,
    checkedNotes: false,
    isLoading: false,
    filters: React.createRef(),
    tooltipDataListCoop: [],

  };

  componentDidMount() {
    axios({
      method: 'get',
      url: `${API}cooperative/directory`,
    })
      .then(resp => {
        const tooltipData = resp.data;
        
        this.setState({
          tooltipDataListCoop: tooltipData,
        });
      })
  }

  handleSubmit = (e, city) => {
    this.setState({
      isLoading: true,
      defaultOoperativesList: [],
      cooperativesList: [],
      pagesList: [],
    });
    axios({
      method: 'get',
      url: `${API}cooperative/showScrappedServices?city=${city}`,
    })
      .then(rsp => {
        if (rsp.data.length === 0) {
          console.log(rsp)
        } else {
          const scrappedPages = rsp.data
        
          this.setState({
            pagesList: scrappedPages,

          })
        }
      })
    axios({
      method: 'get',
      url: `${API}cooperative/findByFilter/${city}/`,
    })
      .then(resp => {
        if (resp.data.length === 0) {
          activeNotification(
            'Nie znaleziono wyników',
            'Zmień wartości wyszukiwania',
            'warning'
          );
          this.setState({
            defaultOoperativesList: [],
            cooperativesList: [],
            isLoading: false,
          });
        } else {
          const cooperativesList = resp.data.sort(compareDateDescending);
          this.setState({
            defaultOoperativesList: cooperativesList,
            cooperativesList,
            isLoading: false,
          });
        }
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          window.location = redirect;
        } else {
          this.setState({ isLoading: false });
          activeNotification(
            'Coś poszło nie tak!',
            'Spróbuj ponownie',
            'danger'
          );
        }
      });
    e.preventDefault();
  };

  sort = sortType => {
    let { cooperativesList } = this.state;

    switch (sortType) {
      case 'Po cenie rosnąco':
        cooperativesList.sort(comparePriceAscending);
        break;

      case 'Po cenie malejąco':
        cooperativesList.sort(comparePriceDescending);
        break;

      case 'Po dacie rosnąco':
        cooperativesList.sort(compareDateAscending);
        break;

      case 'Po dacie malejąco':
        cooperativesList.sort(compareDateDescending);
        break;

      case 'Po cenie za m2 rosnąco':
        cooperativesList.sort(comparePricePerSquareMeterAscending);
        break;

      case 'Po cenie za m2 malejąco':
        cooperativesList.sort(comparePricePerSquareMeterDescending);
        break;

      default:
        break;
    }
    this.setState({ cooperativesList });
  };

  handleChangeSort = (fieldName, value) => {
    this.setState({
      [fieldName]: value,
    });
    this.sort(value);
  };

  handleChangeFavorite = event => {
    let cooperativesList = this.state.defaultOoperativesList;
    if (event.target.checked) {
      if (this.state.checkedNotes)
        cooperativesList = cooperativesList.filter(
          el => el.reactions[0]?.likeStatus && el.reactions[0]?.note
        );
      else
        cooperativesList = cooperativesList.filter(
          el => el.reactions[0]?.likeStatus
        );
    } else {
      if (this.state.checkedNotes)
        cooperativesList = this.state.defaultOoperativesList.filter(
          el => el.reactions[0]?.note
        );
      else cooperativesList = this.state.defaultOoperativesList;
    }

    this.setState({
      checkedFavorite: event.target.checked,
      cooperativesList,
    });
  };

  handleChangeNotes = event => {
    let cooperativesList = this.state.defaultOoperativesList;
    if (event.target.checked) {
      if (this.state.checkedFavorite)
        cooperativesList = cooperativesList.filter(
          el => el.reactions[0]?.likeStatus && el.reactions[0]?.note
        );
      else
        cooperativesList = cooperativesList.filter(el => el.reactions[0]?.note);
    } else {
      if (this.state.checkedFavorite)
        cooperativesList = this.state.defaultOoperativesList.filter(
          el => el.reactions[0]?.likeStatus
        );
      else cooperativesList = this.state.defaultOoperativesList;
    }

    this.setState({
      checkedNotes: event.target.checked,
      cooperativesList,
    });
  };

  setFavourite = id => {
    this.setState({ isLoading: true });

    const list = this.state.cooperativesList;

    const likeAdded = () => this.setState({ isLoading: false });

    const newList = list.map(el => {
      const reactions = el.reactions[0];
      const newStatus = reactions ? !reactions.likeStatus : true
      const newReactions = { ...reactions, likeStatus: newStatus };

      if (el.cooperativeId === id) {
        like(id, 'cooperative', likeAdded, newStatus);
        if (el.reactions[0]) {
          el.reactions[0].likeStatus = newStatus;
        } else {
          el.reactions[0] = newReactions;
        }
      }
      return el;
    });
    this.setState({ cooperativesList: newList });
  };

  setNote = (id, note) => {
    let list = this.state.cooperativesList;

    list = list.map(el => {
      if (el.cooperativeId === id) {
        if (el.reactions[0]) {
          el.reactions[0].note = note;
        } else {
          el.reactions.push({ note });
        }
      }
      return el;
    });
    this.setState({ cooperativesList: list });
  };

  render() {
    const sortFromOptions =
      [
        'Po dacie malejąco',
        'Po dacie rosnąco',
        'Po cenie rosnąco',
        'Po cenie malejąco',
        'Po cenie za m2 rosnąco',
        'Po cenie za m2 malejąco',
      ];
    const sortPlaceholder = 'Po dacie malejąco';
    const {
      cooperativesList,
      sortType,
      checkedFavorite,
      checkedNotes,
      isLoading,
      pagesList,
      tooltipDataListCoop,
    } = this.state;
     return (
      <div>
        <FiltrButton handleSubmit={this.handleSubmit} type='cooperatives' tooltipDataListCoop={tooltipDataListCoop} />
        <hr
          style={{ backgroundColor: '#edecf2', color: '#edecf2', height: 2 }}
        />
        {isLoading && <Indicator />}
        {(cooperativesList.length > 0 || checkedFavorite || checkedNotes) && (
          <Content
            cooperativesList={cooperativesList}
            handleChangeSort={this.handleChangeSort}
            handleChangeNotes={this.handleChangeNotes}
            handleChangeFavorite={this.handleChangeFavorite}
            sortType={sortType}
            checkedFavorite={checkedFavorite}
            checkedNotes={checkedNotes}
            setFavourite={this.setFavourite}
            setNote={this.setNote}
            sortFromOptions={sortFromOptions}
            sortPlaceholder={sortPlaceholder}
          />
        )}
        {(pagesList.length > 0) && (
          <ListOfPages list={pagesList} />

        )}

      </div>
    );
  }
}

export default Cooperatives;
