import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Grid } from '@material-ui/core';

import Pagination from '../functions/Pagination';
import SingleCooperative from './SingleCooperative';

const appear = keyframes`
  0% {
    top: -35px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
`;

const Container = styled.div`
  margin: 30px 0 50px;
`;

const Wrapper = styled(Grid)`
  animation: ${appear} 0.4s;
`;

const Buttons = styled.th`
  @media (min-width: 850px) {
    min-width: 200px;
  }

  @media (min-width: 960px) {
    min-width: 300px;
  }
`;

const TableOfCooperatives = ({ list, setFavourite, setNote }) => {
  console.log(list);
  //pagination
  const [currentPage, setCurrentPage] = useState(0);

  const [amountRows, setAmountRows] = useState(25);

  const [amountSites, setAmountSites] = useState(0);

  const [firstElement, setFirstelement] = useState(currentPage * amountRows);

  const setAmountSitesFn = () => {
    const amount =
      list.length % amountRows === 0
        ? Math.floor(list.length / amountRows)
        : Math.floor(list.length / amountRows) + 1;
    setAmountSites(amount);
  };

  useEffect(() => {
    setAmountSitesFn();
    setFirstelement(currentPage * amountRows);
  });

  const currentList = list.slice(firstElement, firstElement + amountRows);

  const handleChangeRows = e => {
    const rows = +e.target.value;
    setAmountRows(rows);
    setAmountSitesFn();
    if (currentPage < amountSites) setCurrentPage(0);
  };

  //end pagination
 

  const cooperativesList = currentList.map(el => (
    <SingleCooperative
      data={el}
      setFavourite={setFavourite}
      setNote={setNote}
    />
  ));
  return (
    <Container>
      <Wrapper>
        <div class='col-xl-12' style={{ paddingLeft: '5px' }}>
          <div class='widget widget-table-two'>
            <div class='widget-content'>
              <div class='table-responsive'>
                <table class='table'>
                  <thead>
                    <tr>
                      <th style={{ maxWidth: '40%', width: '13%' }}>
                        <div class='th-content'>Data dodania</div>
                      </th>
                      <th>
                        <div class='th-content'>Spółdzielnia</div>
                      </th>
                      <Buttons>
                        <div class='th-content'></div>
                      </Buttons>
                    </tr>
                  </thead>
                  <tbody>{cooperativesList}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
      <Pagination
        amountSites={amountSites}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handleChangeRows={handleChangeRows}
        amountRows={amountRows}
      />
    </Container>
  );
};

export default TableOfCooperatives;
