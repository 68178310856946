import { dateFormat, dateFromFunc, dateToFunc } from '../FilterFunctions';

const AreaOptions = ['25', '40', '50', '60', '70', '80'];

const PricePerSquareMeterOptions = [
  '50000',
  '100000',
  '150000',
  '200000',
  '250000',
  '300000',
  '350000',
  '400000',
  '500000',
  '600000',
  '700000',
  '800000',
];

const PriceOptions = [
  '50000',
  '100000',
  '150000',
  '200000',
  '250000',
  '300000',
  '350000',
  '400000',
  '500000',
  '600000',
  '700000',
  '800000',
];

export const setStateValues = ({
  filterId,
  name,
  city,
  dateFrom,
  dateTo,
  areaFrom,
  areaTo,
  priceFrom,
  priceTo,
  pricePerSquareMeterFrom,
  pricePerSquareMeterTo,
  alertSms,
  alertEmail,
  dateRangeFilter,
  daysAmount,
  pushAlert,
}) => {
  const date = {
    filterId,
    name,
    city,
    cityError: false,
    area: {
      from: areaFrom,
      to: areaTo,
      fromOptions: AreaOptions,
      toOptions: AreaOptions,
      errorTo: false,
      errorFrom: false,
    },
    date: {
      from: dateFrom,
      to: dateTo,
      errorFrom: false,
      errorTo: false,
    },
    pricePerSquareMeter: {
      from: pricePerSquareMeterFrom,
      to: pricePerSquareMeterTo,
      fromOptions: PricePerSquareMeterOptions,
      toOptions: PricePerSquareMeterOptions,
      errorTo: false,
      errorFrom: false,
    },
    price: {
      from: priceFrom,
      to: priceTo,
      fromOptions: PriceOptions,
      toOptions: PriceOptions,
      errorTo: false,
      errorFrom: false,
    },
    alertSms,
    alertEmail,
    dateRangeFilter,
    daysAmount,
    pushAlert,
  };
  return date;
};

export const getValueFromState = ({
  userId,
  name,
  city,
  date,
  area,
  price,
  pricePerSquareMeter,
  alertEmail,
  alertSms,
  dateRangeFilter,
  daysAmount,
  pushAlert,
}) => {
  return {
    userId,
    name,
    city,
    dateFrom: date.from ? dateFormat(dateFromFunc(date.from)) : null,
    dateTo: date.to ? dateFormat(dateToFunc(date.to)) : null,
    areaFrom: area.from,
    areaTo: area.to,
    priceFrom: price.from,
    priceTo: price.to,
    pricePerSquareMeterFrom: pricePerSquareMeter.from,
    pricePerSquareMeterTo: pricePerSquareMeter.to,
    alertSms,
    alertEmail,
    dateRangeFilter,
    daysAmount,
    pushAlert,
  };
};
