export const CityOptions = [
  'Aleksandrów Kujawski',
  'Aleksandrów Łódzki',
  'Alwernia',
  'Andrychów',
  'Annopol',
  'Augustów',
  'Babimost',
  'Baborów',
  'Baranów Sandomierski',
  'Barcin',
  'Barczewo',
  'Bardo',
  'Barlinek',
  'Bartoszyce',
  'Barwice',
  'Bełchatów',
  'Bełżyce',
  'Będzin',
  'Biała',
  'Biała Piska',
  'Biała Podlaska',
  'Biała Rawska',
  'Białobrzegi',
  'Białogard',
  'Biały Bór',
  'Białystok',
  'Biecz',
  'Bielawa',
  'Bielsk Podlaski',
  'Bielsko-Biała',
  'Bieruń',
  'Bierutów',
  'Bieżuń',
  'Biłgoraj',
  'Biskupiec',
  'Bisztynek',
  'Blachownia',
  'Błaszki',
  'Błażowa',
  'Błonie',
  'Bobolice',
  'Bobowa',
  'Bochnia',
  'Bodzentyn',
  'Bogatynia',
  'Boguchwała',
  'Boguszów-Gorce',
  'Bojanowo',
  'Bolesławiec',
  'Bolków',
  'Borek Wielkopolski',
  'Borne Sulinowo',
  'Braniewo',
  'Brańsk',
  'Brodnica',
  'Brok',
  'Brusy',
  'Brwinów',
  'Brzeg',
  'Brzeg Dolny',
  'Brzesko',
  'Brzeszcze',
  'Brześć Kujawski',
  'Brzeziny',
  'Brzostek',
  'Brzozów',
  'Buk',
  'Bukowno',
  'Busko-Zdrój',
  'Bychawa',
  'Byczyna',
  'Bydgoszcz',
  'Bystrzyca Kłodzka',
  'Bytom',
  'Bytom Odrzański',
  'Bytów',
  'Cedynia',
  'Chełm',
  'Chełmek',
  'Chełmno',
  'Chełmża',
  'Chęciny',
  'Chmielnik',
  'Chocianów',
  'Chociwel',
  'Chocz',
  'Chodecz',
  'Chodzież',
  'Chojna',
  'Chojnice',
  'Chojnów',
  'Choroszcz',
  'Chorzele',
  'Chorzów',
  'Choszczno',
  'Chrzanów',
  'Ciechanowiec',
  'Ciechanów',
  'Ciechocinek',
  'Cieszanów',
  'Cieszyn',
  'Ciężkowice',
  'Cybinka',
  'Czaplinek',
  'Czarna Białostocka',
  'Czarna Woda',
  'Czarne',
  'Czarnków',
  'Czchów',
  'Czechowice-Dziedzice',
  'Czeladź',
  'Czempiń',
  'Czerniejewo',
  'Czersk',
  'Czerwieńsk',
  'Czerwionka-Leszczyny',
  'Częstochowa',
  'Człopa',
  'Człuchów',
  'Czyżew',
  'Ćmielów',
  'Daleszyce',
  'Darłowo',
  'Dąbie',
  'Dąbrowa Białostocka',
  'Dąbrowa Górnicza',
  'Dąbrowa Tarnowska',
  'Debrzno',
  'Dębica',
  'Dęblin',
  'Dębno',
  'Dobczyce',
  'Dobiegniew',
  'Dobra',
  'Dobre Miasto',
  'Dobrodzień',
  'Dobrzany',
  'Dobrzyca',
  'Dobrzyń nad Wisłą',
  'Dolsk',
  'Drawno',
  'Drawsko Pomorskie',
  'Drezdenko',
  'Drobin',
  'Drohiczyn',
  'Drzewica',
  'Dukla',
  'Duszniki-Zdrój',
  'Dynów',
  'Działdowo',
  'Działoszyce',
  'Działoszyn',
  'Dzierzgoń',
  'Dzierżoniów',
  'Dziwnów',
  'Elbląg',
  'Ełk',
  'Frampol',
  'Frombork',
  'Garwolin',
  'Gąbin',
  'Gdańsk',
  'Gdynia',
  'Giżycko',
  'Glinojeck',
  'Gliwice',
  'Głogów',
  'Głogów Małopolski',
  'Głogówek',
  'Głowno',
  'Głubczyce',
  'Głuchołazy',
  'Głuszyca',
  'Gniew',
  'Gniewkowo',
  'Gniezno',
  'Gogolin',
  'Golczewo',
  'Goleniów',
  'Golina',
  'Golub-Dobrzyń',
  'Gołańcz',
  'Gołdap',
  'Goniądz',
  'Gorlice',
  'Gorzów Śląski',
  'Gorzów Wielkopolski',
  'Gostynin',
  'Gostyń',
  'Gościno',
  'Gozdnica',
  'Góra',
  'Góra Kalwaria',
  'Górowo Iławeckie',
  'Górzno',
  'Grabów nad Prosną',
  'Grajewo',
  'Grodków',
  'Grodzisk Mazowiecki',
  'Grodzisk Wielkopolski',
  'Grójec',
  'Grudziądz',
  'Grybów',
  'Gryfice',
  'Gryfino',
  'Gryfów Śląski',
  'Gubin',
  'Hajnówka',
  'Halinów',
  'Hel',
  'Hrubieszów',
  'Iława',
  'Iłowa',
  'Iłża',
  'Imielin',
  'Inowrocław',
  'Ińsko',
  'Iwonicz-Zdrój',
  'Izbica Kujawska',
  'Jabłonowo Pomorskie',
  'Janikowo',
  'Janowiec Wielkopolski',
  'Janów Lubelski',
  'Jaraczewo',
  'Jarocin',
  'Jarosław',
  'Jasień',
  'Jasło',
  'Jastarnia',
  'Jastrowie',
  'Jastrzębie-Zdrój',
  'Jawor',
  'Jaworzno',
  'Jaworzyna Śląska',
  'Jedlicze',
  'Jedlina-Zdrój',
  'Jedwabne',
  'Jelcz-Laskowice',
  'Jelenia Góra',
  'Jeziorany',
  'Jędrzejów',
  'Jordanów',
  'Józefów',
  'Józefów nad Wisłą',
  'Jutrosin',
  'Kalety',
  'Kalisz',
  'Kalisz Pomorski',
  'Kalwaria Zebrzydowska',
  'Kałuszyn',
  'Kamienna Góra',
  'Kamień Krajeński',
  'Kamień Pomorski',
  'Kamieńsk',
  'Kańczuga',
  'Karczew',
  'Kargowa',
  'Karlino',
  'Karpacz',
  'Kartuzy',
  'Katowice',
  'Kazimierz Dolny',
  'Kazimierza Wielka',
  'Kąty Wrocławskie',
  'Kcynia',
  'Kędzierzyn-Koźle',
  'Kępice',
  'Kępno',
  'Kętrzyn',
  'Kęty',
  'Kielce',
  'Kietrz',
  'Kisielice',
  'Kleczew',
  'Kleszczele',
  'Kluczbork',
  'Kłecko',
  'Kłobuck',
  'Kłodawa',
  'Kłodzko',
  'Knurów',
  'Knyszyn',
  'Kobylin',
  'Kobyłka',
  'Kock',
  'Kolbuszowa',
  'Kolno',
  'Kolonowskie',
  'Koluszki',
  'Kołaczyce',
  'Koło',
  'Kołobrzeg',
  'Koniecpol',
  'Konin',
  'Konstancin-Jeziorna',
  'Konstantynów Łódzki',
  'Końskie',
  'Koprzywnica',
  'Korfantów',
  'Koronowo',
  'Korsze',
  'Kosów Lacki',
  'Kostrzyn',
  'Kostrzyn nad Odrą',
  'Koszalin',
  'Koszyce',
  'Kościan',
  'Kościerzyna',
  'Kowal',
  'Kowalewo Pomorskie',
  'Kowary',
  'Koziegłowy',
  'Kozienice',
  'Koźmin Wielkopolski',
  'Kożuchów',
  'Kórnik',
  'Krajenka',
  'Kraków',
  'Krapkowice',
  'Krasnobród',
  'Krasnystaw',
  'Kraśnik',
  'Krobia',
  'Krosno',
  'Krosno Odrzańskie',
  'Krośniewice',
  'Krotoszyn',
  'Kruszwica',
  'Krynica Morska',
  'Krynica-Zdrój',
  'Krynki',
  'Krzanowice',
  'Krzepice',
  'Krzeszowice',
  'Krzywiń',
  'Krzyż Wielkopolski',
  'Książ Wielkopolski',
  'Kudowa-Zdrój',
  'Kunów',
  'Kutno',
  'Kuźnia Raciborska',
  'Kwidzyn',
  'Lądek-Zdrój',
  'Legionowo',
  'Legnica',
  'Lesko',
  'Leszno',
  'Leśna',
  'Leśnica',
  'Lewin Brzeski',
  'Leżajsk',
  'Lębork',
  'Lędziny',
  'Libiąż',
  'Lidzbark',
  'Lidzbark Warmiński',
  'Limanowa',
  'Lipiany',
  'Lipno',
  'Lipsk',
  'Lipsko',
  'Lubaczów',
  'Lubań',
  'Lubartów',
  'Lubawa',
  'Lubawka',
  'Lubień Kujawski',
  'Lubin',
  'Lublin',
  'Lubliniec',
  'Lubniewice',
  'Lubomierz',
  'Luboń',
  'Lubowidz',
  'Lubraniec',
  'Lubsko',
  'Lubycza Królewska',
  'Lwówek',
  'Lwówek Śląski',
  'Łabiszyn',
  'Łagów',
  'Łańcut',
  'Łapy',
  'Łasin',
  'Łask',
  'Łaskarzew',
  'Łaszczów',
  'Łaziska Górne',
  'Łazy',
  'Łeba',
  'Łęczna',
  'Łęczyca',
  'Łęknica',
  'Łobez',
  'Łobżenica',
  'Łochów',
  'Łomianki',
  'Łomża',
  'Łosice',
  'Łowicz',
  'Łódź',
  'Łuków',
  'Maków Mazowiecki',
  'Maków Podhalański',
  'Malbork',
  'Małogoszcz',
  'Małomice',
  'Margonin',
  'Marki',
  'Maszewo',
  'Miasteczko Śląskie',
  'Miastko',
  'Michałowo',
  'Miechów',
  'Miejska Górka',
  'Mielec',
  'Mielno',
  'Mieroszów',
  'Mieszkowice',
  'Międzybórz',
  'Międzychód',
  'Międzylesie',
  'Międzyrzec Podlaski',
  'Międzyrzecz',
  'Międzyzdroje',
  'Mikołajki',
  'Mikołów',
  'Mikstat',
  'Milanówek',
  'Milicz',
  'Miłakowo',
  'Miłomłyn',
  'Miłosław',
  'Mińsk Mazowiecki',
  'Mirosławiec',
  'Mirsk',
  'Mława',
  'Młynary',
  'Modliborzyce',
  'Mogielnica',
  'Mogilno',
  'Mońki',
  'Morawica',
  'Morąg',
  'Mordy',
  'Moryń',
  'Mosina',
  'Mrągowo',
  'Mrocza',
  'Mrozy',
  'Mszana Dolna',
  'Mszczonów',
  'Murowana Goślina',
  'Muszyna',
  'Mysłowice',
  'Myszków',
  'Myszyniec',
  'Myślenice',
  'Myślibórz',
  'Nakło nad Notecią',
  'Nałęczów',
  'Namysłów',
  'Narol',
  'Nasielsk',
  'Nekla',
  'Nidzica',
  'Niemcza',
  'Niemodlin',
  'Niepołomice',
  'Nieszawa',
  'Nisko',
  'Nowa Dęba',
  'Nowa Ruda',
  'Nowa Sarzyna',
  'Nowa Słupia',
  'Nowa Sól',
  'Nowe',
  'Nowe Brzesko',
  'Nowe Miasteczko',
  'Nowe Miasto Lubawskie',
  'Nowe Miasto nad Pilicą',
  'Nowe Skalmierzyce',
  'Nowe Warpno',
  'Nowogard',
  'Nowogrodziec',
  'Nowogród',
  'Nowogród Bobrzański',
  'Nowy Dwór Gdański',
  'Nowy Dwór Mazowiecki',
  'Nowy Korczyn',
  'Nowy Sącz',
  'Nowy Staw',
  'Nowy Targ',
  'Nowy Tomyśl',
  'Nowy Wiśnicz',
  'Nysa',
  'Oborniki',
  'Oborniki Śląskie',
  'Obrzycko',
  'Odolanów',
  'Ogrodzieniec',
  'Okonek',
  'Olecko',
  'Olesno',
  'Oleszyce',
  'Oleśnica',
  'Olkusz',
  'Olsztyn',
  'Olsztynek',
  'Olszyna',
  'Oława',
  'Opalenica',
  'Opatowiec',
  'Opatów',
  'Opatówek',
  'Opoczno',
  'Opole',
  'Opole Lubelskie',
  'Orneta',
  'Orzesze',
  'Orzysz',
  'Osieczna',
  'Osiek',
  'Ostrołęka',
  'Ostroróg',
  'Ostrowiec Świętokrzyski',
  'Ostróda',
  'Ostrów Lubelski',
  'Ostrów Mazowiecka',
  'Ostrów Wielkopolski',
  'Ostrzeszów',
  'Ośno Lubuskie',
  'Oświęcim',
  'Otmuchów',
  'Otwock',
  'Otyń',
  'Ozimek',
  'Ozorków',
  'Ożarów',
  'Ożarów Mazowiecki',
  'Pabianice',
  'Pacanów',
  'Paczków',
  'Pajęczno',
  'Pakość',
  'Parczew',
  'Pasłęk',
  'Pasym',
  'Pelplin',
  'Pełczyce',
  'Piaseczno',
  'Piaski',
  'Piastów',
  'Piechowice',
  'Piekary Śląskie',
  'Pieniężno',
  'Pieńsk',
  'Pierzchnica',
  'Pieszyce',
  'Pilawa',
  'Pilica',
  'Pilzno',
  'Piła',
  'Piława Górna',
  'Pińczów',
  'Pionki',
  'Piotrków Kujawski',
  'Piotrków Trybunalski',
  'Pisz',
  'Piwniczna-Zdrój',
  'Pleszew',
  'Płock',
  'Płońsk',
  'Płoty',
  'Pniewy',
  'Pobiedziska',
  'Poddębice',
  'Podkowa Leśna',
  'Pogorzela',
  'Polanica-Zdrój',
  'Polanów',
  'Police',
  'Polkowice',
  'Połaniec',
  'Połczyn-Zdrój',
  'Poniatowa',
  'Poniec',
  'Poręba',
  'Poznań',
  'Prabuty',
  'Praszka',
  'Prochowice',
  'Proszowice',
  'Prószków',
  'Pruchnik',
  'Prudnik',
  'Prusice',
  'Pruszcz Gdański',
  'Pruszków',
  'Przasnysz',
  'Przecław',
  'Przedbórz',
  'Przedecz',
  'Przemków',
  'Przemyśl',
  'Przeworsk',
  'Przysucha',
  'Pszczyna',
  'Pszów',
  'Puck',
  'Puławy',
  'Pułtusk',
  'Puszczykowo',
  'Pyrzyce',
  'Pyskowice',
  'Pyzdry',
  'Rabka-Zdrój',
  'Raciąż',
  'Racibórz',
  'Radków',
  'Radlin',
  'Radłów',
  'Radom',
  'Radomsko',
  'Radomyśl Wielki',
  'Radoszyce',
  'Radymno',
  'Radziejów',
  'Radzionków',
  'Radzymin',
  'Radzyń Chełmiński',
  'Radzyń Podlaski',
  'Rajgród',
  'Rakoniewice',
  'Raszków',
  'Rawa Mazowiecka',
  'Rawicz',
  'Recz',
  'Reda',
  'Rejowiec',
  'Rejowiec Fabryczny',
  'Resko',
  'Reszel',
  'Rogoźno',
  'Ropczyce',
  'Różan',
  'Ruciane-Nida',
  'Ruda Śląska',
  'Rudnik nad Sanem',
  'Rumia',
  'Rybnik',
  'Rychwał',
  'Rydułtowy',
  'Rydzyna',
  'Ryglice',
  'Ryki',
  'Rymanów',
  'Ryn',
  'Rypin',
  'Rzepin',
  'Rzeszów',
  'Rzgów',
  'Sandomierz',
  'Sanniki',
  'Sanok',
  'Sejny',
  'Serock',
  'Sędziszów',
  'Sędziszów Małopolski',
  'Sępopol',
  'Sępólno Krajeńskie',
  'Sianów',
  'Siechnice',
  'Siedlce',
  'Siedliszcze',
  'Siemianowice Śląskie',
  'Siemiatycze',
  'Sieniawa',
  'Sieradz',
  'Sieraków',
  'Sierpc',
  'Siewierz',
  'Skalbmierz',
  'Skała',
  'Skarszewy',
  'Skaryszew',
  'Skarżysko-Kamienna',
  'Skawina',
  'Skępe',
  'Skierniewice',
  'Skoczów',
  'Skoki',
  'Skórcz',
  'Skwierzyna',
  'Sława',
  'Sławków',
  'Sławno',
  'Słomniki',
  'Słubice',
  'Słupca',
  'Słupsk',
  'Sobótka',
  'Sochaczew',
  'Sokołów Małopolski',
  'Sokołów Podlaski',
  'Sokółka',
  'Solec Kujawski',
  'Sompolno',
  'Sopot',
  'Sosnowiec',
  'Sośnicowice',
  'Stalowa Wola',
  'Starachowice',
  'Stargard Szczeciński',
  'Starogard Gdański',
  'Stary Sącz',
  'Staszów',
  'Stawiski',
  'Stawiszyn',
  'Stąporków',
  'Stepnica',
  'Stęszew',
  'Stoczek Łukowski',
  'Stopnica',
  'Stronie Śląskie',
  'Strumień',
  'Stryków',
  'Strzegom',
  'Strzelce Krajeńskie',
  'Strzelce Opolskie',
  'Strzelin',
  'Strzelno',
  'Strzyżów',
  'Sucha Beskidzka',
  'Suchań',
  'Suchedniów',
  'Suchowola',
  'Sulechów',
  'Sulejów',
  'Sulejówek',
  'Sulęcin',
  'Sulmierzyce',
  'Sułkowice',
  'Supraśl',
  'Suraż',
  'Susz',
  'Suwałki',
  'Swarzędz',
  'Syców',
  'Szadek',
  'Szamocin',
  'Szamotuły',
  'Szczawnica',
  'Szczawno-Zdrój',
  'Szczebrzeszyn',
  'Szczecin',
  'Szczecinek',
  'Szczekociny',
  'Szczucin',
  'Szczuczyn',
  'Szczyrk',
  'Szczytna',
  'Szczytno',
  'Szepietowo',
  'Szklarska Poręba',
  'Szlichtyngowa',
  'Szprotawa',
  'Sztum',
  'Szubin',
  'Szydłowiec',
  'Szydłów',
  'Ścinawa',
  'Ślesin',
  'Śmigiel',
  'Śrem',
  'Środa Śląska',
  'Środa Wielkopolska',
  'Świątniki Górne',
  'Świdnica',
  'Świdnik',
  'Świdwin',
  'Świebodzice',
  'Świebodzin',
  'Świecie',
  'Świeradów-Zdrój',
  'Świerzawa',
  'Świętochłowice',
  'Świnoujście',
  'Tarczyn',
  'Tarnobrzeg',
  'Tarnogród',
  'Tarnowskie Góry',
  'Tarnów',
  'Tczew',
  'Terespol',
  'Tłuszcz',
  'Tolkmicko',
  'Tomaszów Lubelski',
  'Tomaszów Mazowiecki',
  'Toruń',
  'Torzym',
  'Toszek',
  'Trzcianka',
  'Trzciel',
  'Trzcińsko-Zdrój',
  'Trzebiatów',
  'Trzebinia',
  'Trzebnica',
  'Trzemeszno',
  'Tuchola',
  'Tuchów',
  'Tuczno',
  'Tuliszków',
  'Tułowice',
  'Turek',
  'Tuszyn',
  'Twardogóra',
  'Tychowo',
  'Tychy',
  'Tyczyn',
  'Tykocin',
  'Tyszowce',
  'Ujazd',
  'Ujście',
  'Ulanów',
  'Uniejów',
  'Urzędów',
  'Ustka',
  'Ustroń',
  'Ustrzyki Dolne',
  'Wadowice',
  'Wałbrzych',
  'Wałcz',
  'Warka',
  'Warszawa',
  'Warta',
  'Wasilków',
  'Wąbrzeźno',
  'Wąchock',
  'Wągrowiec',
  'Wąsosz',
  'Wejherowo',
  'Węgliniec',
  'Węgorzewo',
  'Węgorzyno',
  'Węgrów',
  'Wiązów',
  'Wielbark',
  'Wieleń',
  'Wielichowo',
  'Wieliczka',
  'Wieluń',
  'Wieruszów',
  'Więcbork',
  'Wilamowice',
  'Wisła',
  'Wiślica',
  'Witkowo',
  'Witnica',
  'Wleń',
  'Władysławowo',
  'Włocławek',
  'Włodawa',
  'Włoszczowa',
  'Wodzisław Śląski',
  'Wojcieszów',
  'Wojkowice',
  'Wojnicz',
  'Wolbórz',
  'Wolbrom',
  'Wolin',
  'Wolsztyn',
  'Wołczyn',
  'Wołomin',
  'Wołów',
  'Woźniki',
  'Wrocław',
  'Wronki',
  'Września',
  'Wschowa',
  'Wyrzysk',
  'Wysoka',
  'Wysokie Mazowieckie',
  'Wyszków',
  'Wyszogród',
  'Wyśmierzyce',
  'Zabłudów',
  'Zabrze',
  'Zagórów',
  'Zagórz',
  'Zakliczyn',
  'Zaklików',
  'Zakopane',
  'Zakroczym',
  'Zalewo',
  'Zambrów',
  'Zamość',
  'Zator',
  'Zawadzkie',
  'Zawichost',
  'Zawidów',
  'Zawiercie',
  'Ząbki',
  'Ząbkowice Śląskie',
  'Zbąszynek',
  'Zbąszyń',
  'Zduny',
  'Zduńska Wola',
  'Zdzieszowice',
  'Zelów',
  'Zgierz',
  'Zgorzelec',
  'Zielona Góra',
  'Zielonka',
  'Ziębice',
  'Złocieniec',
  'Złoczew',
  'Złotoryja',
  'Złotów',
  'Złoty Stok',
  'Zwierzyniec',
  'Zwoleń',
  'Żabno',
  'Żagań',
  'Żarki',
  'Żarów',
  'Żary',
  'Żelechów',
  'Żerków',
  'Żmigród',
  'Żnin',
  'Żory',
  'Żukowo',
  'Żuromin',
  'Żychlin',
  'Żyrardów',
  'Żywiec',
  'Gromadka',
  'Osiecznica',
  'Warta Bolesławiecka',
  'Męcinka',
  'Mściwojów',
  'Paszowice',
  'Wądroże Wielkie',
  'Janowice Wielkie',
  'Jeżów Sudecki',
  'Mysłakowice',
  'Podgórzyn',
  'Stara Kamienica',
  'Marciszów',
  'Platerówka',
  'Siekierczyn',
  'Sulików',
  'Pielgrzymka',
  'Zagrodno',
  'Jerzmanowa',
  'Kotla',
  'Pęcław',
  'Żukowice',
  'Jemielno',
  'Niechlów',
  'Krotoszyce',
  'Kunice',
  'Legnickie Pole',
  'Miłkowice',
  'Ruja',
  'Rudna',
  'Gaworzyce',
  'Grębocice',
  'Radwanice',
  'Łagiewniki',
  'Duszniki',
  'Lewin Kłodzki',
  'Dobromierz',
  'Marcinowice',
  'Szczawno',
  'Czarny Bór',
  'Stare Bogaczowice',
  'Walim',
  'Ciepłowody',
  'Kamieniec Ząbkowicki',
  'Stoszowice',
  'Cieszków',
  'Krośnice',
  'Dobroszyce',
  'Dziadowa Kłoda',
  'Domaniów',
  'Borów',
  'Kondratowice',
  'Przeworno',
  'Kostomłoty',
  'Malczyce',
  'Miękinia',
  'Udanin',
  'Wisznia Mała',
  'Zawonia',
  'Wińsko',
  'Czernica',
  'Długołęka',
  'Jordanów Śląski',
  'Kobierzyce',
  'Mietków',
  'Święta Katarzyna',
  'Żórawina',
  'Białe Błota',
  'Dąbrowa Chełmińska',
  'Dobrcz',
  'Nowa Wieś Wielka',
  'Osielsko',
  'Sicienko',
  'Czernikowo',
  'Lubicz',
  'Łubianka',
  'Łysomice',
  'Obrowo',
  'Wielka Nieszawka',
  'Zławieś Wielka',
  'Bobrowo',
  'Brzozie',
  'Bartniczka',
  'Świedziebnia',
  'Zbiczno',
  'Kijewo Królewskie',
  'Lisewo',
  'Papowo Biskupie',
  'Stolno',
  'Unisław',
  'Ciechocin',
  'Radomin',
  'Zbójno',
  'Gruta',
  'Rogóźno',
  'Świecie nad Osą',
  'Sośno',
  'Bukowiec',
  'Dragacz',
  'Drzycim',
  'Jeżewo',
  'Lniano',
  'Osie',
  'Pruszcz',
  'Świekatowo',
  'Warlubie',
  'Cekcyn',
  'Gostycyn',
  'Kęsowo',
  'Lubiewo',
  'Śliwice',
  'Dębowa Łąka',
  'Książki',
  'Płużnica',
  'Bądkowo',
  'Koneck',
  'Raciążek',
  'Waganiec',
  'Zakrzewo',
  'Dąbrowa Biskupia',
  'Rojewo',
  'Złotniki Kujawskie',
  'Bobrowniki',
  'Chrostkowo',
  'Kikół',
  'Tłuchowo',
  'Wielgie',
  'Dąbrowa',
  'Jeziora Wielkie',
  'Sadki',
  'Bytoń',
  'Dobre',
  'Osięciny',
  'Topólka',
  'Brzuze',
  'Rogowo',
  'Skrwilno',
  'Wąpielsk',
  'Baruchowo',
  'Boniewo',
  'Choceń',
  'Fabianki',
  'Lubanie',
  'Gąsawa',
  'Drelów',
  'Janów Podlaski',
  'Kodeń',
  'Konstantynów',
  'Leśna Podlaska',
  'Łomazy',
  'Piszczac',
  'Rokitno',
  'Rossosz',
  'Sławatycze',
  'Sosnówka',
  'Tuczna',
  'Wisznice',
  'Zalesie',
  'Dębowa Kłoda',
  'Jabłoń',
  'Milanów',
  'Podedwórze',
  'Siemień',
  'Sosnowica',
  'Borki',
  'Czemierniki',
  'Kąkolewnica Wschodnia',
  'Komarówka Podlaska',
  'Ulan',
  'Wohyń',
  'Hanna',
  'Hańsk',
  'Stary Brus',
  'Urszulin',
  'Wola Uhruska',
  'Wyryki',
  'Aleksandrów',
  'Biszcza',
  'Goraj',
  'Księżpol',
  'Łukowa',
  'Obsza',
  'Potok Górny',
  'Tereszpol',
  'Turobin',
  'Białopole',
  'Dorohusk',
  'Dubienka',
  'Kamień',
  'Leśniowice',
  'Ruda',
  'Sawin',
  'Wierzbica',
  'Wojsławice',
  'Żmudź',
  'Dołhobyczów',
  'Horodło',
  'Mircze',
  'Trzeszczany',
  'Uchanie',
  'Werbkowice',
  'Fajsławice',
  'Gorzków',
  'Izbica',
  'Kraśniczyn',
  'Łopiennik Górny',
  'Rudnik',
  'Siennica Różana',
  'Żółkiewka',
  'Bełżec',
  'Jarczów',
  'Krynice',
  'Rachanie',
  'Susiec',
  'Tarnawatka',
  'Telatyn',
  'Ulhówek',
  'Adamów',
  'Grabowiec',
  'Komarów',
  'Łabunie',
  'Miączyn',
  'Nielisz',
  'Radecznica',
  'Sitno',
  'Skierbieszów',
  'Stary Zamość',
  'Sułów',
  'Abramów',
  'Firlej',
  'Jeziorzany',
  'Kamionka',
  'Michów',
  'Niedźwiada',
  'Ostrówek',
  'Serniki',
  'Uścimów',
  'Borzechów',
  'Garbów',
  'Głusk',
  'Jabłonna',
  'Jastków',
  'Konopnica',
  'Krzczonów',
  'Niedrzwica Duża',
  'Niemce',
  'Strzyżewice',
  'Wojciechów',
  'Wólka',
  'Wysokie',
  'Zakrzew',
  'Cyców',
  'Ludwin',
  'Milejów',
  'Puchaczów',
  'Spiczyn',
  'Mełgiew',
  'Rybczewice',
  'Trawniki',
  'Batorz',
  'Dzwola',
  'Godziszów',
  'Potok Wielki',
  'Dzierzkowice',
  'Gościeradów',
  'Szastarka',
  'Trzydnik Duży',
  'Wilkołaz',
  'Zakrzówek',
  'Krzywda',
  'Serokomla',
  'Stanin',
  'Trzebieszów',
  'Wojcieszków',
  'Wola Mysłowska',
  'Chodel',
  'Karczmiska',
  'Łaziska',
  'Wilków',
  'Baranów',
  'Janowiec',
  'Końskowola',
  'Kurów',
  'Markuszów',
  'Wąwolnica',
  'Żyrzyn',
  'Kłoczew',
  'Nowodwór',
  'Stężyca',
  'Ułęż',
  'Bogdaniec',
  'Deszczno',
  'Lubiszyn',
  'Santok',
  'Bledzew',
  'Przytoczna',
  'Pszczew',
  'Górzyca',
  'Stare Kurowo',
  'Zwierzyn',
  'Krzeszyce',
  'Słońsk',
  'Bobrowice',
  'Bytnica',
  'Kolsko',
  'Siedlisko',
  'Lubrza',
  'Skąpe',
  'Szczaniec',
  'Bojadła',
  'Trzebiechów',
  'Zabór',
  'Brzeźnica',
  'Niegosławice',
  'Wymiarki',
  'Brody',
  'Lipinki Łużyckie',
  'Przewóz',
  'Trzebiel',
  'Tuplice',
  'Andrespol',
  'Brójce',
  'Dmosin',
  'Jeżów',
  'Nowosolna',
  'Rogów',
  'Dłutów',
  'Dobroń',
  'Ksawerów',
  'Lutomiersk',
  'Parzęczew',
  'Drużbice',
  'Kleszczów',
  'Kluki',
  'Rusiec',
  'Szczerców',
  'Białaczów',
  'Mniszków',
  'Paradyż',
  'Poświętne',
  'Żarnów',
  'Czarnocin',
  'Gorzkowice',
  'Grabica',
  'Łęki Szlacheckie',
  'Moszczenica',
  'Ręczno',
  'Rozprza',
  'Wola Krzysztoporska',
  'Dobryszyce',
  'Gidle',
  'Gomunice',
  'Kobiele Wielkie',
  'Kodrąb',
  'Lgota Wielka',
  'Ładzice',
  'Masłowice',
  'Wielgomłyny',
  'Żytno',
  'Będków',
  'Budziszewice',
  'Czerniewice',
  'Inowłódz',
  'Lubochnia',
  'Rokiciny',
  'Rzeczyca',
  'Żelechlinek',
  'Buczek',
  'Sędziejowice',
  'Widawa',
  'Wodzierady',
  'Kiełczygłów',
  'Nowa Brzeźnica',
  'Rząśnia',
  'Siemkowice',
  'Strzelce Wielkie',
  'Dalików',
  'Pęczniew',
  'Wartkowice',
  'Zadzim',
  'Brąszewice',
  'Brzeźnio',
  'Burzenin',
  'Goszczanów',
  'Klonowa',
  'Wróblew',
  'Czarnożyły',
  'Mokrsko',
  'Osjaków',
  'Pątnów',
  'Skomlin',
  'Wierzchlas',
  'Czastary',
  'Galewice',
  'Lututów',
  'Łubnice',
  'Sokolniki',
  'Zapolice',
  'Bedlno',
  'Dąbrowice',
  'Krzyżanów',
  'Łanięta',
  'Nowe Ostrowy',
  'Oporów',
  'Strzelce',
  'Daszyna',
  'Góra Świętej Małgorzaty',
  'Grabów',
  'Piątek',
  'Świnice Warckie',
  'Witonia',
  'Bielawy',
  'Chąśno',
  'Domaniewice',
  'Kiernozia',
  'Kocierzew Południowy',
  'Łyszkowice',
  'Nieborów',
  'Cielądz',
  'Regnów',
  'Sadkowice',
  'Bolimów',
  'Głuchów',
  'Godzianów',
  'Kowiesy',
  'Lipce Reymontowskie',
  'Maków',
  'Nowy Kawęczyn',
  'Słupia',
  'Drwinia',
  'Lipnica Murowana',
  'Łapanów',
  'Rzezawa',
  'Trzciana',
  'Żegocina',
  'Czernichów',
  'Igołomia',
  'Iwanowice',
  'Jerzmanowice',
  'Kocmyrzów',
  'Liszki',
  'Michałowice',
  'Mogilany',
  'Sułoszowa',
  'Wielka Wieś',
  'Zabierzów',
  'Zielonki',
  'Charsznica',
  'Gołcza',
  'Kozłów',
  'Książ Wielki',
  'Racławice',
  'Słaboszów',
  'Lubień',
  'Pcim',
  'Raciechowice',
  'Siepraw',
  'Tokarnia',
  'Wiśniowa',
  'Koniusza',
  'Pałecznica',
  'Radziemice',
  'Biskupice',
  'Gdów',
  'Kłaj',
  'Lipinki',
  'Łużna',
  'Ropa',
  'Sękowa',
  'Uście Gorlickie',
  'Jodłownik',
  'Kamienica',
  'Laskowa',
  'Łukowica',
  'Niedźwiedź',
  'Słopnice',
  'Tymbark',
  'Chełmiec',
  'Gródek nad Dunajcem',
  'Kamionka Wielka',
  'Korzenna',
  'Łabowa',
  'Łącko',
  'Łososina Dolna',
  'Nawojowa',
  'Podegrodzie',
  'Rytro',
  'Czarny Dunajec',
  'Czorsztyn',
  'Jabłonka',
  'Krościenko nad Dunajcem',
  'Lipnica Wielka',
  'Łapsze Niżne',
  'Ochotnica Dolna',
  'Raba Wyżna',
  'Spytkowice',
  'Szaflary',
  'Biały Dunajec',
  'Bukowina Tatrzańska',
  'Kościelisko',
  'Poronin',
  'Babice',
  'Bolesław',
  'Klucze',
  'Trzyciąż',
  'Polanka Wielka',
  'Przeciszów',
  'Budzów',
  'Bystra',
  'Stryszawa',
  'Zawoja',
  'Zembrzyce',
  'Lanckorona',
  'Mucharz',
  'Stryszów',
  'Tomice',
  'Wieprz',
  'Borzęcin',
  'Gnojnik',
  'Iwkowa',
  'Szczurowa',
  'Gręboszów',
  'Mędrzechów',
  'Radgoszcz',
  'Gromnik',
  'Lisia Góra',
  'Pleśna',
  'Rzepiennik Strzyżewski',
  'Skrzyszów',
  'Wierzchosławice',
  'Wietrzychowice',
  'Szerzyny',
  'Gołymin',
  'Grudusk',
  'Ojrzeń',
  'Opinogóra Górna',
  'Regimin',
  'Sońsk',
  'Pacyna',
  'Szczawin Kościelny',
  'Dzierzgowo',
  'Lipowiec Kościelny',
  'Radzanów',
  'Strzegowo',
  'Stupsk',
  'Szreńsk',
  'Szydłowo',
  'Wieczfnia Kościelna',
  'Wiśniewo',
  'Bielsk',
  'Bodzanów',
  'Brudzeń Duży',
  'Bulkowo',
  'Łąck',
  'Mała Wieś',
  'Nowy Duninów',
  'Radzanowo',
  'Słupno',
  'Stara Biała',
  'Staroźreby',
  'Baboszewo',
  'Czerwińsk nad Wisłą',
  'Dzierzążnia',
  'Joniec',
  'Naruszewo',
  'Nowe Miasto',
  'Sochocin',
  'Załuski',
  'Gozdowo',
  'Mochowo',
  'Rościszewo',
  'Szczutowo',
  'Zawidz',
  'Kuczbork',
  'Lutocin',
  'Siemiątkowo',
  'Huszlew',
  'Olszanka',
  'Platerów',
  'Sarnaki',
  'Stara Kornica',
  'Czerwonka',
  'Karniewo',
  'Krasnosielc',
  'Młynarze',
  'Płoniawy',
  'Rzewnie',
  'Sypniewo',
  'Szelków',
  'Baranowo',
  'Czarnia',
  'Czerwin',
  'Goworowo',
  'Kadzidło',
  'Lelis',
  'Łyse',
  'Olszewo',
  'Rzekuń',
  'Troszyn',
  'Andrzejewo',
  'Boguty',
  'Małkinia Górna',
  'Nur',
  'Stary Lubotyń',
  'Szulborze Wielkie',
  'Wąsewo',
  'Zaręby Kościelne',
  'Czernice Borowe',
  'Jednorożec',
  'Krasne',
  'Krzynowłoga Mała',
  'Gzy',
  'Obryte',
  'Pokrzywnica',
  'Świercze',
  'Winnica',
  'Zatory',
  'Domanice',
  'Korczew',
  'Kotuń',
  'Mokobody',
  'Paprotnia',
  'Przesmyki',
  'Skórzec',
  'Suchożebry',
  'Wiśniew',
  'Wodynie',
  'Zbuczyn',
  'Bielany',
  'Ceranów',
  'Jabłonna Lacka',
  'Repki',
  'Sabnie',
  'Sterdyń',
  'Grębków',
  'Korytnica',
  'Liw',
  'Miedzna',
  'Sadowne',
  'Stoczek',
  'Wierzbno',
  'Brańszczyk',
  'Długosiodło',
  'Rząśnik',
  'Somianka',
  'Zabrodzie',
  'Promna',
  'Stara Błotnica',
  'Stromiec',
  'Garbatka',
  'Głowaczów',
  'Gniewoszów',
  'Grabów nad Pilicą',
  'Magnuszew',
  'Sieciechów',
  'Chotcza',
  'Ciepielów',
  'Rzeczniów',
  'Sienno',
  'Solec nad Wisłą',
  'Borkowice',
  'Gielniów',
  'Klwów',
  'Odrzywół',
  'Potworów',
  'Rusinów',
  'Wieniawa',
  'Gózd',
  'Jastrzębia',
  'Jedlińsk',
  'Jedlnia',
  'Kowala',
  'Przytyk',
  'Wolanów',
  'Chlewiska',
  'Jastrząb',
  'Mirów',
  'Orońsko',
  'Kazanów',
  'Policzna',
  'Przyłęk',
  'Tczów',
  'Bemowo',
  'Białołęka',
  'Mokotów',
  'Ochota',
  'Praga',
  'Rembertów',
  'Śródmieście',
  'Targówek',
  'Ursus',
  'Ursynów',
  'Wawer',
  'Wesoła',
  'Wilanów',
  'Włochy',
  'Wola',
  'Żoliborz',
  'Borowie',
  'Maciejowice',
  'Miastków Kościelny',
  'Parysów',
  'Sobolew',
  'Trojanów',
  'Wilga',
  'Nieporęt',
  'Wieliszew',
  'Cegłów',
  'Dębe Wielkie',
  'Jakubów',
  'Latowicz',
  'Siennica',
  'Stanisławów',
  'Czosnów',
  'Leoncin',
  'Pomiechówek',
  'Celestynów',
  'Kołbiel',
  'Osieck',
  'Sobienie',
  'Wiązowna',
  'Dąbrówka',
  'Jadów',
  'Klembów',
  'Strachówka',
  'Jaktorów',
  'Żabia Wola',
  'Belsk Duży',
  'Błędów',
  'Chynów',
  'Goszczyn',
  'Jasieniec',
  'Lesznowola',
  'Prażmów',
  'Nadarzyn',
  'Raszyn',
  'Brochów',
  'Iłów',
  'Młodzieszyn',
  'Nowa Sucha',
  'Rybno',
  'Teresin',
  'Izabelin',
  'Kampinos',
  'Stare Babice',
  'Puszcza Mariańska',
  'Radziejowice',
  'Wiskitki',
  'Skarbimierz',
  'Lubsza',
  'Lasowice Wielkie',
  'Domaszowice',
  'Pokój',
  'Świerczów',
  'Kamiennik',
  'Łambinowice',
  'Pakosławice',
  'Skoroszyce',
  'Branice',
  'Bierawa',
  'Cisek',
  'Pawłowiczki',
  'Polska Cerekiew',
  'Reńska Wieś',
  'Strzeleczki',
  'Walce',
  'Rudniki',
  'Zębowice',
  'Chrząstowice',
  'Dobrzeń Wielki',
  'Komprachcice',
  'Łubniany',
  'Murów',
  'Popielów',
  'Tarnów Opolski',
  'Turawa',
  'Izbicko',
  'Jemielnica',
  'Baligród',
  'Cisna',
  'Czarna',
  'Lutowiska',
  'Olszanica',
  'Solina',
  'Domaradz',
  'Dydnia',
  'Haczów',
  'Jasienica Rosielna',
  'Nozdrzec',
  'Brzyska',
  'Dębowiec',
  'Krempna',
  'Nowy Żmigród',
  'Osiek Jasielski',
  'Skołyszyn',
  'Tarnowiec',
  'Chorkówka',
  'Korczyna',
  'Krościenko Wyżne',
  'Miejsce Piastowe',
  'Wojaszówka',
  'Besko',
  'Bukowsko',
  'Komańcza',
  'Tyrawa Wołoska',
  'Zarszyn',
  'Chłopice',
  'Laszki',
  'Pawłosiów',
  'Rokietnica',
  'Roźwienica',
  'Wiązownica',
  'Horyniec',
  'Stary Dzików',
  'Wielkie Oczy',
  'Bircza',
  'Dubiecko',
  'Fredropol',
  'Krasiczyn',
  'Krzywcza',
  'Medyka',
  'Orły',
  'Stubno',
  'Żurawica',
  'Adamówka',
  'Gać',
  'Jawornik Polski',
  'Tryńcza',
  'Zarzecze',
  'Cmolas',
  'Majdan Królewski',
  'Niwiska',
  'Raniżów',
  'Dzikowiec',
  'Markowa',
  'Rakszawa',
  'Żołynia',
  'Iwierzyce',
  'Ostrów',
  'Wielopole Skrzyńskie',
  'Hyżne',
  'Lubenia',
  'Świlcza',
  'Trzebownisko',
  'Czudec',
  'Frysztak',
  'Niebylec',
  'Jodłowa',
  'Żyraków',
  'Grodzisko Dolne',
  'Kuryłówka',
  'Borowa',
  'Czermin',
  'Gawłuszowice',
  'Padew Narodowa',
  'Tuszów Narodowy',
  'Wadowice Górne',
  'Harasiuki',
  'Jeżowe',
  'Krzeszów',
  'Bojanów',
  'Pysznica',
  'Radomyśl nad Sanem',
  'Zaleszany',
  'Gorzyce',
  'Grębów',
  'Dobrzyniewo Duże',
  'Gródek',
  'Juchnowiec Kościelny',
  'Turośń Kościelna',
  'Zawady',
  'Janów',
  'Korycin',
  'Kuźnica',
  'Nowy Dwór',
  'Sidra',
  'Szudziałowo',
  'Boćki',
  'Orla',
  'Rudka',
  'Wyszki',
  'Białowieża',
  'Czeremcha',
  'Czyże',
  'Dubicze Cerkiewne',
  'Narew',
  'Narewka',
  'Grabowo',
  'Mały Płock',
  'Turośl',
  'Miastkowo',
  'Piątnica',
  'Przytuły',
  'Śniadowo',
  'Wizna',
  'Zbójna',
  'Dziadkowice',
  'Grodzisk',
  'Mielnik',
  'Milejczyce',
  'Nurzec',
  'Perlejewo',
  'Klukowo',
  'Kulesze Kościelne',
  'Nowe Piekuty',
  'Sokoły',
  'Kołaki Kościelne',
  'Rutki',
  'Szumowo',
  'Bargłów Kościelny',
  'Nowinka',
  'Płaska',
  'Sztabin',
  'Radziłów',
  'Jasionówka',
  'Jaświły',
  'Krypno',
  'Trzcianne',
  'Giby',
  'Krasnopol',
  'Puńsk',
  'Bakałarzewo',
  'Filipów',
  'Jeleniewo',
  'Przerośl',
  'Raczki',
  'Rutka',
  'Szypliszki',
  'Wiżajny',
  'Cedry Wielkie',
  'Kolbudy',
  'Przywidz',
  'Pszczółki',
  'Suchy Dąb',
  'Trąbki Wielkie',
  'Chmielno',
  'Przodkowo',
  'Sierakowice',
  'Somonino',
  'Sulęczyno',
  'Ostaszewo',
  'Stegna',
  'Sztutowo',
  'Kosakowo',
  'Krokowa',
  'Choczewo',
  'Gniewino',
  'Linia',
  'Luzino',
  'Łęczyce',
  'Szemud',
  'Borzytuchom',
  'Czarna Dąbrówka',
  'Kołczygłowy',
  'Lipnica',
  'Parchowo',
  'Studzienice',
  'Trzebielino',
  'Tuchomie',
  'Konarzyny',
  'Koczała',
  'Przechlewo',
  'Rzeczenica',
  'Cewice',
  'Nowa Wieś Lęborska',
  'Wicko',
  'Damnica',
  'Dębnica Kaszubska',
  'Główczyce',
  'Kobylnica',
  'Potęgowo',
  'Smołdzino',
  'Dziemiany',
  'Karsin',
  'Liniewo',
  'Lipusz',
  'Nowa Karczma',
  'Stara Kiszewa',
  'Gardeja',
  'Ryjewo',
  'Sadlinki',
  'Lichnowy',
  'Mikołajki Pomorskie',
  'Miłoradz',
  'Stare Pole',
  'Stary Dzierzgoń',
  'Stary Targ',
  'Bobowo',
  'Kaliska',
  'Lubichowo',
  'Smętowo Graniczne',
  'Zblewo',
  'Morzeszczyn',
  'Subkowy',
  'Bestwina',
  'Buczkowice',
  'Jasienica',
  'Jaworze',
  'Kozy',
  'Porąbka',
  'Wilkowice',
  'Brenna',
  'Chybie',
  'Goleszów',
  'Hażlach',
  'Istebna',
  'Zebrzydowice',
  'Gilowice',
  'Jeleśnia',
  'Koszarawa',
  'Lipowa',
  'Łękawica',
  'Łodygowice',
  'Milówka',
  'Radziechowy',
  'Rajcza',
  'Ślemień',
  'Świnna',
  'Ujsoły',
  'Węgierska Górka',
  'Boronów',
  'Ciasna',
  'Herby',
  'Kochanowice',
  'Koszęcin',
  'Pawonków',
  'Krupski Młyn',
  'Ożarowice',
  'Świerklaniec',
  'Tworóg',
  'Zbrosławice',
  'Dąbrowa Zielona',
  'Kamienica Polska',
  'Kłomnice',
  'Konopiska',
  'Kruszyna',
  'Lelów',
  'Mstów',
  'Mykanów',
  'Poczesna',
  'Przyrów',
  'Rędziny',
  'Starcza',
  'Lipie',
  'Miedźno',
  'Panki',
  'Popów',
  'Przystajń',
  'Wręczyca Wielka',
  'Niegowa',
  'Poraj',
  'Gierałtowice',
  'Pilchowice',
  'Rudziniec',
  'Wielowieś',
  'Kornowac',
  'Krzyżanowice',
  'Nędza',
  'Pietrowice Wielkie',
  'Gaszowice',
  'Jejkowice',
  'Lyski',
  'Świerklany',
  'Godów',
  'Lubomia',
  'Marklowice',
  'Mszana',
  'Mierzęcice',
  'Psary',
  'Irządze',
  'Kroczyce',
  'Włodowice',
  'Żarnowiec',
  'Ornontowice',
  'Wyry',
  'Goczałkowice',
  'Kobiór',
  'Miedźna',
  'Pawłowice',
  'Suszec',
  'Bojszowy',
  'Chełm Śląski',
  'Bieliny',
  'Górno',
  'Łopuszno',
  'Masłów',
  'Miedziana Góra',
  'Mniów',
  'Piekoszów',
  'Raków',
  'Sitkówka',
  'Strawczyn',
  'Zagnańsk',
  'Fałków',
  'Gowarczów',
  'Ruda Maleniecka',
  'Smyków',
  'Bałtów',
  'Bodzechów',
  'Waśniów',
  'Bliżyn',
  'Łączna',
  'Skarżysko Kościelne',
  'Mirzec',
  'Pawłów',
  'Gnojno',
  'Solec',
  'Tuczępy',
  'Imielno',
  'Nagłowice',
  'Oksa',
  'Sobków',
  'Wodzisław',
  'Bejsce',
  'Baćkowice',
  'Iwaniska',
  'Lipnik',
  'Sadowie',
  'Tarłów',
  'Wojciechowice',
  'Kije',
  'Michałów',
  'Złota',
  'Dwikozy',
  'Klimontów',
  'Łoniów',
  'Obrazów',
  'Samborzec',
  'Wilczyce',
  'Bogoria',
  'Rytwiany',
  'Kluczewsko',
  'Krasocin',
  'Moskorzew',
  'Secemin',
  'Lelkowo',
  'Płoskinia',
  'Wilczęta',
  'Iłowo',
  'Płośnica',
  'Godkowo',
  'Gronowo Elbląskie',
  'Markusy',
  'Milejewo',
  'Rychliki',
  'Grodziczno',
  'Kurzętnik',
  'Dąbrówno',
  'Grunwald',
  'Łukta',
  'Małdyty',
  'Kalinowo',
  'Prostki',
  'Stare Juchy',
  'Banie Mazurskie',
  'Budry',
  'Kruklanki',
  'Miłki',
  'Pozezdrze',
  'Wydminy',
  'Dubeninki',
  'Kowale Oleckie',
  'Świętajno',
  'Wieliczki',
  'Barciany',
  'Srokowo',
  'Kiwity',
  'Lubomino',
  'Piecki',
  'Sorkwity',
  'Janowiec Kościelny',
  'Janowo',
  'Kozłowo',
  'Dywity',
  'Gietrzwałd',
  'Jonkowo',
  'Purda',
  'Stawiguda',
  'Świątki',
  'Dźwierzuty',
  'Jedwabno',
  'Rozogi',
  'Kotlin',
  'Blizanów',
  'Ceków',
  'Godziesze Wielkie',
  'Koźminek',
  'Lisków',
  'Mycielin',
  'Szczytniki',
  'Żelazków',
  'Bralin',
  'Łęka Opatowska',
  'Perzów',
  'Rychtal',
  'Trzcinica',
  'Rozdrażew',
  'Przygodzice',
  'Sieroszewice',
  'Sośnie',
  'Czajków',
  'Doruchów',
  'Kobyla Góra',
  'Kraszewice',
  'Gizałki',
  'Gołuchów',
  'Kiszkowo',
  'Łubowo',
  'Mieleszyn',
  'Niechanowo',
  'Babiak',
  'Chodów',
  'Grzegorzew',
  'Kościelec',
  'Olszówka',
  'Osiek Mały',
  'Grodziec',
  'Kazimierz Biskupi',
  'Kramsk',
  'Krzymów',
  'Skulsk',
  'Stare Miasto',
  'Wierzbinek',
  'Wilczyn',
  'Lądek',
  'Orchowo',
  'Ostrowite',
  'Powidz',
  'Strzałkowo',
  'Brudzew',
  'Kawęczyn',
  'Malanów',
  'Przykona',
  'Władysławów',
  'Kołaczkowo',
  'Pępowo',
  'Granowo',
  'Kamieniec',
  'Krzemieniewo',
  'Święciechowa',
  'Wijewo',
  'Włoszakowice',
  'Chrzypsko Wielkie',
  'Kwilcz',
  'Kuślin',
  'Miedzichowo',
  'Pakosław',
  'Przemęt',
  'Siedlec',
  'Budzyń',
  'Drawsko',
  'Lubasz',
  'Połajewo',
  'Białośliwie',
  'Kaczory',
  'Miasteczko Krajeńskie',
  'Damasławek',
  'Mieścisko',
  'Wapno',
  'Lipka',
  'Tarnówka',
  'Ryczywół',
  'Czerwonak',
  'Dopiewo',
  'Kleszczewo',
  'Komorniki',
  'Suchy Las',
  'Tarnowo Podgórne',
  'Kaźmierz',
  'Dominowo',
  'Krzykosy',
  'Nowe Miasto nad Wartą',
  'Zaniemyśl',
  'Ostrowice',
  'Wierzchowo',
  'Dygowo',
  'Rymań',
  'Siemyśl',
  'Ustronie Morskie',
  'Będzino',
  'Biesiekierz',
  'Manowo',
  'Świeszyno',
  'Malechowo',
  'Postomino',
  'Grzmiąca',
  'Brzeżno',
  'Rąbino',
  'Sławoborze',
  'Bierzwnik',
  'Krzęcin',
  'Brojce',
  'Karnice',
  'Radowo Małe',
  'Rewal',
  'Boleszkowice',
  'Nowogródek Pomorski',
  'Bielice',
  'Kozielice',
  'Przelewice',
  'Warnice',
  'Dolice',
  'Kobylanka',
  'Marianowo',
  'Stara Dąbrowa',
  'Osina',
  'Przybiernów',
  'Banie',
  'Stare Czarnowo',
  'Widuchowa',
  'Świerzno',
  'Kołbaskowo',
  ];
  
  