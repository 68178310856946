import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Pagination from './functions/Pagination';

import SingleAdvertisement from './advertisements/SingleAdvertisement';
import SingleAuction from './auctions/SingleAuction';
import SingleRent from './rent/SingleRent';

const ContainerList = styled.div`
  padding: 5vh 1vw 1vh;
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

const MainList = ({ list = [], type, setFavourite, setNote, checkedFavorite }) => {
  const scrollRef = useRef(null);
  //pagination
  const [currentPage, setCurrentPage] = useState(0);

  const [amountRows, setAmountRows] = useState(25);

  const [amountSites, setAmountSites] = useState(0);

  const [firstElement, setFirstelement] = useState(currentPage * amountRows);

  useEffect(() => {
    if(scrollRef?.current){
      executeScroll();
    }
  },[currentPage])

  const executeScroll = () => scrollRef.current.scrollIntoView() 

  const setAmountSitesFn = () => {
    const amount =
      list.length % amountRows === 0
        ? Math.floor(list.length / amountRows)
        : Math.floor(list.length / amountRows) + 1;

    setAmountSites(amount);
  };

  useEffect(() => {
    setAmountSitesFn();
    setFirstelement(currentPage * amountRows);
  });
 
  const currentList = list.slice(firstElement, firstElement + amountRows).map(el => {
  
    if (type === 'advertisements')
      return (
        <SingleAdvertisement
          key={el.advertisementId}
          data={el}
          setFavourite={setFavourite}
          setNote={setNote}
        />
      );
    else if (type === 'auctions')
      return (
        <SingleAuction key={el.auctionId} data={el} setFavourite={setFavourite} setNote={setNote} />
      );
    else if (type === 'rents')
      return (
        <SingleRent key={el.auctionId} data={el} setFavourite={setFavourite} setNote={setNote} />
      );
  });

  const handleChangeRows = e => {
    const rows = e.target.value;
    setAmountRows(+rows);
    setAmountSitesFn();
    if (currentPage < amountSites) setCurrentPage(0);
  };

  //end pagination

  return (
    <ContainerList>
      <div ref={scrollRef}/>
      <div>{currentList}</div>
      <Pagination
        amountSites={amountSites}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handleChangeRows={handleChangeRows}
        amountRows={amountRows}
      />
    </ContainerList>
  );
};

export default MainList;
