import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { theme } from '../styles/theme';
// import CsvDownload from 'react-json-to-csv'; old lib
import { useLocation } from 'react-router-dom';

import { FormSelect, FormCheckBox } from './InputFilters';
import Button from './atom/Button';

const Wrapper = styled.div`
  .Mui-selected,
  .MuiMenuItem-gutters,
  .MuiListItem-gutters,
  .MuiListItem-button,
  .Mui-selected,
  .MuiMenuItem-root,
  li.MuiListItem-button,
  .MuiList-root,
  .MuiMenu-list,
  .MuiList-padding {
    font-size: ${({ theme }) => theme.font.lg} !important;
  }
`;
const SingleData = styled(Grid)`
  font-size: ${({ theme }) => theme.font.md};
  margin-bottom: 15px;
  font-size: 2rem;
  color: rgb(81, 83, 101);

  span {
    color: ${({ theme }) => theme.textColor};
    font-weight: ${({ bold }) => (bold ? '800' : 'normal')};
  }
`;

const FormSelectWrapper = styled(Grid)`
  width: 10vw;
  min-width: 240px;
  min-height: 37px;
  text-align: center;
  margin: 5px 0 0 20px;

  div,
  span,
  li {
    font-size: ${({ theme }) => theme.font.md};
  }
`;

const OnlyDesktop = styled(Button)`
  @media (max-width: 960px) {
    display: none;
  }
`;

// const SortFromOptions = [
//   'Po dacie malejąco',
//   'Po dacie rosnąco',
//   'Po cenie rosnąco',
//   'Po cenie malejąco',
//   'Po cenie za m2 rosnąco',
//   'Po cenie za m2 malejąco',
// ];

const HeaderSection = ({
  list = [],
  handleChangeSort,
  checkedFavorite,
  handleChangeFavorite,
  handleChangeNotes,
  checkedNotes,
  sortType,
  sortOptions,
  sortPlaceholder,
}) => {
  const listCSV = list.map((el) => {
    return {
      advertisementId: el.advertisementId || '',
      area: el.area || '',
      buildingType: el.buildingType || '',
      builtYear: el.builtYear || '',
      city: el.city || '',
      dateAdded: el.dateAdded || '',
      description: el.description || '',
      floor: el.floor || '',
      keywords: el.keywords || '',
      link: el.link || '',
      numberOfRooms: el.numberOfRooms || '',
      offerFrom: el.offerFrom || '',
      ownerType: el.ownerType || '',
      phone: el.phone || '',
      price: el.price || '',
      portal: el.portal.portalName || '',
      pricePerSquareMeter: el.pricePerSquareMeter || '',
      thumbnailPath: el.thumbnailPath || '',
      title: el.title || '',
      type: el.type || '',
      typeOfMarket: el.typeOfMarket || '',
      webId: el.webId || '',
    };
  });

  // custom json to csv downloader
  const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    //current separator
    const separator = ';';
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;

    var CSV = '\ufeff';

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = '';

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and seprated
        row += index + `${separator}`;
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + '\r\n';
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = '';

      //2nd loop will extract each column and convert it in string seprated
      for (var index in arrData[i]) {
        row +=
          '"' +
          (typeof arrData[i][index] === 'string'
            ? arrData[i][index]?.replace(/;/g, ',')
            : arrData[i][index]) +
          `"${separator}`;
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + '\r\n';
    }

    if (CSV === '') {
      alert('Invalid data');
      return;
    }

    var uri = 'data:text/csv;charset=windows-1250,' + encodeURIComponent(CSV);
    var link = document.createElement('a');
    link.href = uri;
    link.style = 'visibility:hidden';
    link.download = ReportTitle + '.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  let { pathname } = useLocation();
  return (
    <Wrapper>
      <SingleData style={{ marginBottom: '40px' }}>
        Liczba ofert: <span>{list.length}</span>
      </SingleData>
      <Grid container justify="space-between">
        <Grid container item xs={12} md={6} style={{ paddingLeft: '50px' }}>
          <FormCheckBox
            name="favourites"
            label="Pokaż tylko ulubione"
            checked={checkedFavorite}
            onChange={handleChangeFavorite}
          />
          <FormCheckBox
            name="favourites"
            label="Pokaż tylko z komentarzami"
            checked={checkedNotes}
            onChange={handleChangeNotes}
          />
        </Grid>
        {(pathname === '/' ||
          pathname === '/tender' ||
          pathname === '/auctions') && (
          <Grid
            container
            item
            alignItems="center"
            xs={12}
            md={6}
            justify="flex-end"
          >
            <OnlyDesktop width={'small'}>
              <button
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  color: '#fff',
                }}
                onClick={() => JSONToCSVConvertor(listCSV, 'export', true)}
              >
                Generuj plik CSV
              </button>
              {/* Old way to download */}
              {/* <CsvDownload
                data={listCSV}
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  color: '#fff',
                }}
              >
                Generuj plik CSV
              </CsvDownload> */}
            </OnlyDesktop>
            <FormSelectWrapper item>
              <FormSelect
                font={theme.font.md}
                id="sortType"
                name="sortType"
                value={sortType}
                onChange={handleChangeSort}
                optionList={sortOptions}
                placeholder={sortPlaceholder}
              />
            </FormSelectWrapper>
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
};

export default HeaderSection;
