import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ImgLink from '../../assets/External_link_font_awesome.svg.png';

const gross = keyframes`
  0% {
    max-height: 0;
  }
  100% {
    max-height: 500px;
  }
`;

const appear = keyframes`
  0% {
    top: -35px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
`;

const ContainerDuplicates = styled.div`
  margin-top: 30px;
  animation: ${gross} 0.4s;
`;

const Wrapper = styled(Grid)`
  animation: ${appear} 0.4s;
`;

const LinkStyled = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content:center;

  span {
    display: block;
    background-image: url(${ImgLink});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 30px;
    width: 30px;
    margin: 0;
    transform: translateY(-1px);
  }
`;


const useStyles = makeStyles((theme) => ({
  displayNoneMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',

    },
  },
  displayMobileHead: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px!important',
      borderTopLeftRadius: '6px',
      borderBottomLeftRadius: '6px',
    }
  },
  displayMobileBody: {
    paddingLeft: '10px!important',
  },
  displayPriceMobile: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0!important',
    }
  }
}));

const Duplicates = ({ duplicates, component }) => {
  const classes = useStyles();


  const duplicatesList = duplicates.map((el) => (
    <tr key={el.id}>
      <td style={{ paddingLeft: '10px' }} className={classes.displayNoneMobile}>
        <div>{el.dateAdded.slice(0, 10)}</div>
        <div>{el.dateAdded.slice(11, 19)}</div>
      </td>
      <td className={classes.displayMobileBody}>
        <div>{el.portal}</div>
      </td>
      <td className={classes.displayNoneMobile}>
        <div>{el.title}</div>
      </td>
      <td className={classes.displayPriceMobile}>
        <div>
          {el.price}
        </div>
      </td>
      <td style={{ paddingLeft: '0', paddingRight: '0' }}>
        <div>
          <LinkStyled href={el.link} target='_blank'>
            Zobacz <span></span>
          </LinkStyled>
        </div>
      </td>
    </tr>
  ));
  return (
    <ContainerDuplicates>
      <Wrapper container item xs={12} sm={12} lg={12}>
        <div style={{ paddingLeft: '5px', width: '100%' }}>
          <div class='widget widget-table-two'>
            <div class='widget-heading'>
              <h5 class=''>Duplikaty</h5>
            </div>
            <div class='widget-content'>
              <div class='table-responsive'>
                <table class='table'>
                  <thead>
                    <tr>
                      <th style={{ paddingLeft: '10px' }} className={classes.displayNoneMobile}>
                        <div>Data dodania</div>
                      </th>
                      <th style={{ paddingLeft: 0 }} className={classes.displayMobileHead}>
                        <div>Portal</div>
                      </th>
                      <th style={{ paddingLeft: 0 }} className={classes.displayNoneMobile}>
                        <div>Tytuł</div>
                      </th>
                      <th style={{ paddingLeft: 0 }}>
                        <div>Cena</div>
                      </th>
                      <th style={{ paddingLeft: 0, textAlign: 'center' }}>
                        <div>Link</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{duplicatesList}</tbody>
                </table>
              </div>
            </div>
            {/* {component} */}
          </div>
        </div>
      </Wrapper>
    </ContainerDuplicates>
  );
};

export default Duplicates;
