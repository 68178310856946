import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import {
  Checkbox,
  InputBase,
  FormControl,
  Select,
  MenuItem,
  TextField,
  ListItemText,
  FormHelperText,
  FormControlLabel,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import NotificationsIcon from '@material-ui/icons/NotificationsNone';
import plLocale from 'date-fns/locale/pl';
import NumberFormatCustom from './functions/NumberFormatCustom';

export const FormButtonStyle = styled.button`
  color: #0e2e60;
  margin: 0;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  clear: both;

  &:disabled {
    color: #aaa;
    cursor: default;
  }
`;

const FilterName = styled.div`
  width: '100%';
  white-pace: 'nowrap';
  overflow: hidden;
  text-overflow: ellipsis;
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  error: {
    borderColor: 'red',
  },
}));

const StyledMenuItem = withStyles(() => ({
  root: {
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.2rem',
    '&:hover': {
      color: '#0e2e60',
      backgroundColor: 'rgba(27, 85, 226, 0.239216)',
    },
  },
  span: {
    fontSize: '2rem',
  },
}))(MenuItem);

const StyledMenuItemSlectAll = withStyles(() => ({
  root: props => ({
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: `${props.font}`,
    borderBottom: 'solid 1px rgba(0,0,0,0.2)',
    '&:hover': {
      color: '#0e2e60',
      backgroundColor: 'rgba(27, 85, 226, 0.239216)',
    },
  }),
}))(MenuItem);

const StyledTextField = withStyles({
  root: {
    width: '100%',
  },
  input: props => ({
    fontSize: '1.2rem',
    padding: '11.25px 5px 11.25px 12px',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'white',
    border: `1px solid ${props.error ? 'red' : '#ced4da'}`,
    '&:focus': {
      borderRadius: 4,
      borderColor: '#0e2e60',
      boxShadow: '5px 5px 5px 5px 2px #2196f3',
      backgroundColor: 'white',
    },
  }),
})(InputBase);

const StyledAutocomplete = withStyles(() => ({
  root: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #ced4da',
    margin: 0,
  },
  focused: {
    borderRadius: 4,
    borderColor: '#0e2e60',
    boxShadow: '5px 5px 5px 5px 2px #2196f3',
    backgroundColor: 'white',
  },
  paper: {
    minWidth: 100,
  },
  inputRoot: {
    padding: '6px 6px 6px 12px',
  },
  input: {
    fontSize: '1.2rem',
  },
  option: {
    '&:hover': {
      color: '#0e2e60',
      backgroundColor: 'rgba(27, 85, 226, 0.239216)',
    },
  },
}))(Autocomplete);

const StyledAutocompleteOptions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledDatePicker = styled(KeyboardDatePicker)`
  .MuiIconButton-root {
    padding: 0;
    margin-right: 3px;
  }
`;

const StyledFormControlLabel = withStyles(() => ({
  root: {
    transform: 'scale(1.5)',
    width: '200px',
    color: '#0e1726',
  },
}))(FormControlLabel);

const StyledFormControlLabelManyLocations = withStyles(() => ({
  root: {

    width: '100%',
    color: '#0e1726',
    marginLeft: '0',
    marginBottom: '0',


  },
  span: {
    fontSize: '1.1rem',
  },
}))(FormControlLabel);

const Title = styled.p`
  font-size: 1.4rem;
  margin-bottom:0;
`;

const Placeholder = ({ children }) => {
  return (
    <div style={{ color: '#aaa', fontSize: '1.2rem', color: 'rgba(134, 134, 134)' }}>
      {children}
    </div>
  );
};

export const FormSelect = ({ id, name, value, onChange, optionList, placeholder, font, style, disabled }) => (
  <FormControl className={useStyles().formControl}>
    <Select
      style={style}
      disabled={disabled}
      id={id}
      className='shadow-sm'
      value={value}
      displayEmpty
      onChange={e => onChange(name, e.target.value)}
      input={<StyledTextField />}
      renderValue={value => {
    
        if (!value) {
          return <Placeholder>{placeholder}</Placeholder>;
        }
        return value;
      }}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
        },
      }}
    >
      {optionList &&
        optionList.map(el => (
          <StyledMenuItem font={20} value={el}>
            {el}
          </StyledMenuItem>
        ))}
    </Select>
  </FormControl>
);

export const FormSelectCustomLabel = ({ id, name, value, onChange, optionList, labelList, placeholder, font, style, disabled }) => (
  <FormControl className={useStyles().formControl}>
    <Select
      style={style}
      disabled={disabled}
      id={id}
      className='shadow-sm'
      value={value}
      displayEmpty
      onChange={e => onChange(name, e.target.value)}
      input={<StyledTextField />}
      renderValue={value => {
        if (!value) return placeholder;
        return labelList[optionList.findIndex(el => el == value)];
      }}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
        },
      }}
    >
      {optionList &&
        optionList.map((el, idx) => (
          <StyledMenuItem font={20} value={el}>
            {labelList[idx]}
          </StyledMenuItem>
        ))}
    </Select>
  </FormControl>
);

export const FormSelectDate = ({ id, name, value, onChange, optionList, placeholder, dateOptionsLabels }) => (
  <FormControl className={useStyles().formControl}>
    <Select
      id={id}
      className='shadow-sm'
      value={value}
      displayEmpty
      onChange={e => onChange(name, e.target.value)}
      input={<StyledTextField />}
      renderValue={value => {
        if (!value) {
          return <Placeholder>{placeholder}</Placeholder>;
        }
        return value !== -1 ? `${value} ${dateOptionsLabels[value]}` : dateOptionsLabels[value];
      }}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
        },
      }}
    >
      {optionList &&
        optionList.map(el => (
          <StyledMenuItem font={20} value={el}>
            {el !== -1 ? `${el} ${dateOptionsLabels[el]}` : dateOptionsLabels[el]}
          </StyledMenuItem>
        ))}
    </Select>
  </FormControl>
);

const isSelectAllSaleMode = (value, optionList) => {
  for (let i = 0; i < optionList.length; i++) {
    if (!value.find(el => el === optionList[i].value)) {
      return false;
    }
  }
  return true;
};

export const FormSelectRoom = ({ id, name, value, onChange, optionList, placeholder, font }) => (
  <FormControl className={useStyles().formControl}>
    <Select
      id={id}
      className='shadow-sm'
      value={value}
      displayEmpty
      onChange={e => onChange(name, e.target.value)}
      input={<StyledTextField />}
      renderValue={value => {
        if (!value) {
          return <Placeholder>{placeholder}</Placeholder>;
        }
        return value;
      }}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
        },
      }}
    >
      <MenuItem value={null}>
        <Placeholder>{placeholder}</Placeholder>
      </MenuItem>
      {optionList &&
        optionList.map(el => (
          <StyledMenuItem font={font} value={el}>
            {el}
          </StyledMenuItem>
        ))}
    </Select>
  </FormControl>
);

export const FormCheckboxSaleMode = ({
  id,
  name,
  value,
  onChange,
  optionList,
  placeholder,
  selectAll,
}) => (
    <FormControl className={useStyles().formControl}>
      <Select
        id={id}
        multiple
        displayEmpty
        className='shadow-sm'
        value={value}
        onChange={e => {
          e.target.value.find(el => el === 'all')
            ? selectAll(name, optionList, isSelectAllSaleMode(value, optionList))
            : onChange(name, e.target.value);
        }}
        input={<StyledTextField />}
        renderValue={selected => {
          if (selected.length === 0) {
            return <Placeholder>{placeholder}</Placeholder>;
          }
          const values = [];
          for (let i = 0; i < selected.length; i++) {
            const field = optionList.find(el => el.value == selected[i]);
            if (field) {
              values.push(field.name);
            }
          }
          return values.join(', ');
        }}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
          },
        }}
      >
        <StyledMenuItemSlectAll value='all'>
          <Checkbox color='primary' checked={isSelectAllSaleMode(value, optionList)} />
          <ListItemText primary='Zaznacz wszystko' />
        </StyledMenuItemSlectAll>
        {optionList.map(el => (
          <StyledMenuItem value={el.value}>
            <Checkbox color='primary' checked={value.indexOf(el.value) > -1} />
            <ListItemText primary={el.name} />
          </StyledMenuItem>
        ))}
      </Select>
    </FormControl>
  );

const isSelectAll = (value, optionList) => {
  for (let i = 0; i < optionList.length; i++) {
    if (!value.find(el => el === optionList[i])) {
      return false;
    }
  }
  return true;
};

export const FormCheckboxSelect = ({
  id,
  name,
  value,
  onChange,
  optionList,
  placeholder,
  selectAll,
}) => (
    <FormControl className={useStyles().formControl}>
      <Select
        id={id}
        multiple
        displayEmpty
        className='shadow-sm'
        value={value}
        onChange={e => {
          e.target.value.find(el => el === 'all')
            ? selectAll(name, optionList, isSelectAll(value, optionList))
            : onChange(name, e.target.value);
        }}
        input={<StyledTextField />}
        renderValue={selected => {
          if (selected.length === 0) {
            return <Placeholder>{placeholder}</Placeholder>;
          }
          return selected.join(', ');
        }}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
          },
        }}
      >
        <StyledMenuItemSlectAll value='all'>
          <Checkbox color='primary' checked={isSelectAll(value, optionList)} />
          <ListItemText primary='Zaznacz wszystko' />
        </StyledMenuItemSlectAll>
        {optionList.map(name => (
          <StyledMenuItem value={name}>
            <Checkbox color='primary' checked={value.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </StyledMenuItem>
        ))}
      </Select>
    </FormControl>
  );

export const FormAutoComplete = ({ id, name, value, onChange, optionList, error }) => (
  <StyledAutocomplete
    style={error === true ? { borderColor: 'red' } : {}}
    id={id}
    disableClearable
    className='shadow-sm'
    value={value}
    options={optionList.map(option => option)}
    onChange={(value, newValue) => onChange(name, newValue)}
    renderInput={params => (
      <TextField
        {...params}
        InputProps={{ ...params.InputProps, type: 'text', disableUnderline: true, fullWidth: true }}
      />
    )}
  />
);

const getAlertsForFilters = (savedCities, city) => {
  const result = savedCities.filter(item => {
    return item.city === city;
  })

  return (
    <div>
      {result[0].alertSms ? (
        <SmsOutlinedIcon fontSize='small'/>
      ) : null}{' '}
      {result[0].alertEmail ? (
        <EmailOutlinedIcon fontSize='small' style={{ marginLeft: '5px' }} />
      ) : null}
      {result[0].pushAlert ? (
        <NotificationsIcon fontSize='small' style={{ marginLeft: '5px' }} />
      ) : null}
    </div>
  )
}

export const FormAutoCompleteFreeSolo = ({
  id,
  name,
  value,
  onChange,
  optionList,
  placeholder,
  error,
  onBlur,
  thousandSeparator,
  maxLength,
  savedCities
}) => (
    <FormControl className={useStyles().formControl}>
      <StyledAutocomplete
        id={id}
        freeSolo
        disableClearable
        style={error === true ? { borderColor: 'red' } : {}}
        className='shadow-sm'
        options={optionList.map(option =>
          thousandSeparator ? option.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') : option
        )}
        value={value}
        getOptionLabel={(option) => option}
        renderOption={(option) => (
          <StyledAutocompleteOptions>
            <div>{option}</div>
            {savedCities ? getAlertsForFilters(savedCities, option) : null}
          </StyledAutocompleteOptions>
        )}
        onChange={(value, newValue) => onChange(name, newValue)}
        onBlur={
          onBlur &&
          (e => onBlur(name, thousandSeparator ? e.target.value.replace(/\s/g, '') : e.target.value))
        }
        renderInput={params => (
          <TextField
            font='lange'
            {...params}
            inputProps={{ ...params.inputProps, maxLength }}
            InputProps={{
              ...params.InputProps,
              type: 'text',
              disableUnderline: true,
              placeholder,
              inputComponent: thousandSeparator && NumberFormatCustom,
            }}
          />
        )}
      />
      {error === true ? <FormHelperText style={{ color: 'red' }}>Błędne dane</FormHelperText> : null}
    </FormControl>
  );

export const FormDateInput = ({
  id,
  value,
  onChange,
  minDate,
  maxDate,
  onAccept,
  onBlur,
  onError,
  error,
}) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
      <StyledDatePicker
        style={{
          margin: 0,
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'white',
          border: '1px solid #ced4da',
          padding: '4px 3px 4px 6px',
          '&:focus': {
            borderRadius: 4,
            borderColor: '#0e2e60',
            boxShadow: '5px 5px 5px 5px 2px #2196f3',
            backgroundColor: 'white',
          },
        }}
        id={id}
        className='shadow-sm'
        disableToolbar
        disableFuture
        variant='inline'
        format='dd MM yyyy'
        placeholder='dd MM yyyy'
        invalidDateMessage='nieprawidłowy format daty'
        maxDateMessage='błędne dane'
        minDateMessage='błędne dane'
        helperText={error}
        onError={onError}
        error={error && true}
        autoOk
        minDate={minDate ? minDate : new Date(1900, 0, 1, 1)}
        maxDate={maxDate ? maxDate : new Date()}
        InputProps={{
          disableUnderline: true,
        }}
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        onBlur={onBlur}
        onAccept={onAccept}
      />
    </MuiPickersUtilsProvider>
  );

export const FormTextField = React.forwardRef(
  ({ id, name, placeholder, onBlur, maxLength }, ref) => {
    return (
      <StyledTextField
        type='text'
        id={id}
        name={name}
        inputProps={{ maxLength }}
        className='shadow-sm'
        placeholder={placeholder}
        onBlur={e => onBlur(name, e.target.value)}
        inputRef={ref}
      />
    );
  }
);

export const FormRadio = ({ name, value, onChange, disabled, style }) => (
  <div class='n-chk' style={{ paddingTop: '8px', paddingLeft: '5px', paddingBottom: '2.5px', ...style }}>
    <label
      style={{ color: '#888ea8' }}
      class='new-control new-radio radio-classic-primary'
      for={name + 'Yes'}
    >
      <input
        type='radio'
        id={name + 'Yes'}
        name={name}
        class='new-control-input'
        checked={value}
        onChange={e => onChange(name, true)}
        disabled={disabled}
      />
      <span class='new-control-indicator'></span>
      tak
    </label>
    <label
      style={{ color: '#888ea8' }}
      class='new-control new-radio radio-classic-primary'
      for={name + 'No'}
    >
      <input
        type='radio'
        id={name + 'No'}
        name={name}
        class='new-control-input'
        checked={!value}
        onChange={e => onChange(name, false)}
        disabled={disabled}
      />
      <span class='new-control-indicator'></span>
      nie
    </label>
  </div>
);

export const FormCheckBox = ({ name, checked, onChange, label }) => (
  <StyledFormControlLabel
    control={<Checkbox onChange={onChange} name={name} color='primary' size='medium' />}
    label={label}
  />
);

export const FormCheckBoxManyCities = ({ name, checked, onChange, label }) => (
  <StyledFormControlLabelManyLocations
    control={<Checkbox onChange={onChange} checked={checked} name={name} color='primary' size='medium' />}
    label={label}
  />
);

export const FormCheckBoxDateRange = ({ name, checked, onChange, label }) => (
  <StyledFormControlLabelManyLocations
    control={<Checkbox checked={checked} onChange={onChange} name={name} color='primary' size='medium' />}
    label={label}
  />
);

export const SavedFilter = ({
  id,
  name,
  value,
  onChange,
  options,
  placeholder,
  removeSavedFilter,
}) => {
  return (
      <Grid container spacing={0} style={{ flexGrow: 1, alignItems: 'center' }}>
        <Grid item xs={9}>
          <FormControl className={useStyles().formControl}>
            <Select
              id={id}
              className='shadow-sm'
              value={value}
              displayEmpty
              onChange={e => onChange(name, e.target.value)}
              input={<StyledTextField />}
              renderValue={value => {
                if (value) {
                  const el = options?.find(el => el.filterId == value);
                  return (
                    <div style={{ display: 'flex' }}>
                      <div>
                        {el?.alertSms && (
                          <SmsOutlinedIcon fontSize='small' style={{ marginRight: '5px' }} />
                        )}{' '}
                        {el?.alertEmail && (
                          <EmailOutlinedIcon fontSize='small' style={{ marginRight: '5px' }} />
                        )}
                        {el.pushAlert && (
                          <NotificationsIcon fontSize='small' style={{ marginLeft: '5px' }} />
                        )}
                      </div>
                      <FilterName>{el?.name}</FilterName>
                    </div>
                  );
                }
                return <Placeholder>{placeholder}</Placeholder>;
              }}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                },
              }}
            >
              {options &&
                options.map(el => (
                  <StyledMenuItem value={el.filterId}>
                    <div>{el.name}</div>
                    <div>
                      {el.alertSms && (
                        <SmsOutlinedIcon fontSize='small' style={{ marginLeft: '5px' }} />
                      )}{' '}
                      {el.alertEmail && (
                        <EmailOutlinedIcon fontSize='small' style={{ marginLeft: '5px' }} />
                      )}
                      {el.pushAlert && (
                        <NotificationsIcon fontSize='small' style={{ marginLeft: '5px' }} />
                      )}
                    </div>
                  </StyledMenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormButtonStyle
            onClick={removeSavedFilter}
            disabled={value ? false : true}
            color='primary'
            type='button'
          >
            usuń
          </FormButtonStyle>
        </Grid>
      </Grid>
  );
};
