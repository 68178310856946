import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif !important;
  }

  html {
    font-size: 62.5%; 
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #F1F2F3;
    font-family: 'Nunito', sans-serif;
    min-height: 100vh; 
  }  

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .MuiSelect-icon{
    right: 8px;
    top: 10px;
  }
`;

export default GlobalStyle;
