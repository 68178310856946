import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import gsap from 'gsap';
import { makeStyles } from '@material-ui/core';

import Duplicates from './Duplicates';
import Modyfications from './Modyfications';
import Notes from '../Reactions/Note';
import Button from '../atom/Button';

import DefaultImg from '../../assets/defPicture.jpg';
import ImgLink from '../../assets/External_link_font_awesome.svg.png';
import Adown from '@material-ui/icons/ArrowDropDown';
import Note from '@material-ui/icons/SpeakerNotesOutlined';
import Heart from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PhoneIcon from '@material-ui/icons/Phone';

const appear = keyframes`
  0% {
    top: -35px;
    opacity: 0;
  }

  30% {
    top: -35px;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
`;

const DetailsWrapper = styled(Grid)`
  position: relative;
  overflow: hidden;

  width: 100%;
  background-color: white;
  color: ${({ theme }) => theme.text};
  border-radius: 10px;
  box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
    0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
  margin: 60px auto 0;

  &:nth-child(1) {
    margin-top: 20px;
  }

  > .MuiGrid-grid-lg-9 {
    max-width: 100%;
  }
`;

const AdditionalDetails = styled(Grid)`
  animation: ${appear} 0.4s;
`;

const NotesWrapper = styled(Grid)`
  padding: 20px 3vw 0;
  width: 100%;
`;

const ImgStyled = styled.div`
  height: 100%;
  width: 100%;
  background-image: url('${({ img }) => img}');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  min-height: 130px;

  @media (max-width: 1280px) {
    width: 100%;
    margin: 0 auto;
  }

  @media (min-width: 600px) and (max-width: 960px) {
    width: 70%;
    margin: 5px 10px 0;
  }
`;

const SingleData = styled(Grid)`
  font-size: ${({ big, theme }) => (big ? theme.font.xxl : theme.font.md)};

  span,
  p {
    color: ${({ theme }) => theme.textColor};
    font-weight: 600;
  }
`;

const MainData = styled(Grid)`
  padding: 10px 10px 10px 15px;

  @media only screen and (max-width: 1279px) {
    padding-top: 15px;
  }

  @media (min-width: 960px) {
    > .MuiGrid-grid-md-2 {
      padding: 0 5px;
      max-width: 65px;
      flex-basis: 65px;
    }
  }

  @media (min-width: 960px) {
    > .MuiGrid-grid-md-6 {
      max-width: calc(65%-65px);
      flex-basis: calc(65%-65px);
    }
  }

  @media (min-width: 960px) {
    > .MuiGrid-grid-md-4 {
      max-width: 35%;
      flex-basis: 35%;
    }
  }
`;

const DetailsGrid = styled(Grid)`
  margin: 10px auto 20px;
  padding: 10px 20px 20px;
  overflow: hidden;
  box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 5%);
  background-color: white;
  border-radius: 5px;
  border: #e0e0e0 1px solid;

  .MuiGrid-spacing-xs-4 > .MuiGrid-item {
    padding: 0 16px;
  }
`;

const ButtonContainer = styled(Grid)`
  @media (min-width: 600px) and (max-width: 960px) {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 0;
  }
`;

const Circle = styled.div`
  border-radius: 50%;
  height: 50px;
  width: 50px;
  padding: 5px;
  box-shadow: 0 3px 5px 4px #b7b2b22e;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  background-color: white;
  margin: 16px 0;
  cursor: pointer;

  &:nth-child(1) {
    margin: 5px 0;
  }

  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 3px 3px 8px 3px #ccc;
  }
`;

const LinkStyled = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
  transition: 0.2s;

  &:hover {
    color: ${({ theme }) => theme.text};
  }
`;

const Arrow = styled(Adown)`
  font-size: 15px;
  margin-left: 5px;
  transform: ${({ up }) => (up ? 'rotate(180deg)' : null)};
`;

const Phone = styled.p`
  margin: 0;
  padding: 0;
  color: white;
`;

const useStyles = makeStyles((theme) => ({
  tooltipIcon: {
    margin: theme.spacing(0.5),
    color: '#888ea8',
  },
  tooltipGridBox: {
    marginBottom: '0.6rem',
  },
}));

const SingleAdvertisement = ({ data, setFavourite, setNote }) => {
  //duplikaty
  const duplications = data.advertisementDuplicates;
  const amountDuplicates = duplications.length;
  //modyfikacje
  const modifications = data.advertisementModifications;
  const amountModifications = modifications.length;

  const [modAndDupOpen, togglemodAndDupOpen] = useState(false);
  const likeStatus = data.reaction && data.reaction.likeStatus;
  const noteText =  data.reaction && data.reaction.note;

  const [detailsOpen, toggleDetailsOpen] = useState(false);
  const [addNoteOpen, toggleaddNote] = useState(false);

  let modAndDupContainer;
  let detaildContainer;
  let additionalDetailsContainer;

  //animation modyfication and duplicates
  const tl1 = gsap.timeline({ defaults: { ease: 'power3.inOut' } });
  tl1.set(modAndDupContainer, { autoAlpha: 0 });
  const toggleContainer = () => {
    if (!modAndDupOpen) {
      togglemodAndDupOpen(true);
      tl1.fromTo(
        modAndDupContainer,
        { maxHeight: 0 },
        { maxHeight: '3000px', duration: 0.4 }
      );
    } else {
      tl1
        .fromTo(
          modAndDupContainer,
          { opacity: 1 },
          { opacity: 0, duration: 0.2 }
        )
        .to(modAndDupContainer, {
          height: 0,
          duration: 0.2,
        });
      setTimeout(() => togglemodAndDupOpen(false), 400);
    }
  };

  //animation details
  const tl2 = gsap.timeline({ defaults: { ease: 'power3.inOut' } });
  tl2.set(additionalDetailsContainer, { autoAlpha: 0 });
  const toggleContainerDetails = () => {
    if (!detailsOpen) {
      toggleDetailsOpen(true);
      tl2.fromTo(
        detaildContainer,
        { maxHeight: '1200px' },
        { maxHeight: '4000px', duration: 0.3 }
      );
    } else {
      tl2
        .fromTo(
          additionalDetailsContainer,
          { opacity: 1 },
          { opacity: 0, duration: 0.2 }
        )
        .to(detaildContainer, {
          maxHeight: '800px',
          duration: 0.3,
        });
      setTimeout(() => toggleDetailsOpen(false), 400);
    }
  };
  const classes = useStyles();

  return (
    <div>
      <DetailsWrapper
        item
        container
        addNoteOpen={addNoteOpen}
        ref={(el) => (detaildContainer = el)}
      >
        <Grid item xs={12} lg={3}>
          <ImgStyled
            img={
              data.thumbnailPath
                ? data.thumbnailPath.replace(' ', '%20')
                : DefaultImg
            }
          />
        </Grid>
        <Grid container item xs={12} sm={12} lg={9}>
          <Grid container item xs={12} sm={12} md={9} direction="column">
            <MainData item container justify="space-between">
              <Grid
                item
                xs={12}
                sm={5}
                md={6}
                container
                direction="column"
                justify="space-between"
              >
                <SingleData big="true" bold="true">
                  <span style={{ color: '#2550aa' }}>
                    Cena:{' '}
                    {data.price === 0 || !data.price
                      ? '-'
                      : data.price
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    {data.price ? ' zł' : null}
                  </span>
                </SingleData>
                <SingleData bold="true">
                  <p>
                    Cena za m<sup>2</sup>:{' '}
                    {data.pricePerSquareMeter === 0 || !data.pricePerSquareMeter
                      ? '-'
                      : data.pricePerSquareMeter
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                          .replace('.', ',')}
                    {data.pricePerSquareMeter ? ' zł' : null}
                  </p>
                  <p>{data.title || '-'}</p>
                </SingleData>
                <Grid container justify={'flex-start'} direction="column">
                  <SingleData item>
                    Portal: <span>{data.portal || '-'}</span>
                  </SingleData>
                  <SingleData item>
                    <LinkStyled href={data.link} target="_blank">
                      <span style={{ color: '#2550aa' }}>
                        Przejdź do ogłoszenia{' '}
                      </span>
                      <img src={ImgLink} alt="link" />
                    </LinkStyled>
                  </SingleData>
                </Grid>
              </Grid>
              <Grid
                container
                justify={'space-between'}
                item
                xs={9}
                sm={4}
                md={4}
                direction="column"
                style={{ paddingRight: 15 }}
              >
                <SingleData item>
                  Data: <span>{data.dateAdded.replace('T', ' ')}</span>
                  {data.tooltipDate ? (
                    <Tooltip
                      classes={{ tooltip: classes.customWidth }}
                      enterTouchDelay={0}
                      title={
                        <span
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2',
                            fontFamily: 'Inter',
                          }}
                        >
                          Data dodania: {data.tooltipDate.slice(0, 10)}{' '}
                          {data.tooltipDate.slice(11, 19)}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoSharpIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </SingleData>

                <SingleData item>
                  Metraż:{' '}
                  <span>
                    {data.area || '-'}
                    {data.area ? (
                      <>
                        {' '}
                        m<sup>2</sup>
                      </>
                    ) : null}
                  </span>
                </SingleData>
                <SingleData item>
                  Liczba pokoi: <span>{data.numberOfRooms || '-'}</span>
                </SingleData>
                <SingleData item>
                  Piętro: <span>{data.floor || '-'}</span>
                </SingleData>

                <SingleData item>
                  Typ budynku: <span>{data.type || '-'}</span>
                </SingleData>
              </Grid>
              <Grid item container xs={3} sm={3} md={2}>
                <Grid item sm={6} xs={6}>
                  <Circle
                    onClick={() => {
                      setFavourite(data.advertisementId);
                    }}
                  >
                    {likeStatus ? (
                      <FavoriteIcon style={{ color: '#FF0000' }} />
                    ) : (
                      <Heart style={{ color: '#FF0000' }} />
                    )}
                  </Circle>
                </Grid>
                <Grid item md={12} sm={6} xs={12}>
                  <Circle
                    onClick={() => {
                      toggleaddNote(!addNoteOpen);
                    }}
                  >
                    <Note
                      fontSize={!noteText || noteText.length === 0 ? 'small' : 'large'}
                    />
                  </Circle>
                </Grid>
              </Grid>
            </MainData>
          </Grid>
          <ButtonContainer
            container
            item
            xs={8}
            sm={3}
            md={3}
            direction="column"
            style={{ margin: '10px auto', padding: '0 10px 0 10px' }}
          >
            <Button className=" mb-2" disabled={!data.phone ? true : false}>
              <a
                href={`${data.phone ? `tel:${data.phone}` : ''}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  width: '100%',
                  pointerEvents: `${data.phone ? 'auto' : 'none'}`,
                }}
              >
                <PhoneIcon style={{ margin: 'auto 5px auto 0' }} />
                {data.phone ? <Phone>{data.phone}</Phone> : null}
              </a>
            </Button>
            <Button
              className="mb-2"
              disabled={
                amountDuplicates !== 0 || amountModifications !== 0
                  ? false
                  : true
              }
              onClick={toggleContainer}
            >
              <Grid container>
                <Grid item xs={11}>
                  Duplikaty ({amountDuplicates}) <br /> Modyfikacje (
                  {amountModifications})
                </Grid>
                <Grid
                  item
                  xs={1}
                  container
                  justify="center"
                  alignItems="center"
                >
                  <Arrow up={modAndDupOpen ? true : false} />
                </Grid>
              </Grid>
            </Button>
            <Button onClick={() => toggleContainerDetails()}>
              Szczegóły
              <Arrow up={detailsOpen ? true : false} />
            </Button>
          </ButtonContainer>
        </Grid>

        {detailsOpen && (
          <AdditionalDetails
            container
            xs={12}
            ref={(el) => (additionalDetailsContainer = el)}
          >
            <DetailsGrid
              container
              justify={'space-between'}
              item
              md={10}
              lg={10}
              spacing={4}
            >
              <SingleData item>
                Rodzaj budynku: <span>{data.buildingType || '-'}</span>
              </SingleData>
              <SingleData item>
                Rok budowy: <span>{data.builtYear || '-'}</span>
              </SingleData>
              <SingleData item>
                Rynek: <span>{data.typeOfMarket || '-'}</span>
              </SingleData>
              <SingleData item>
                Oferta od: <span>{data.offerFrom || '-'}</span>
              </SingleData>
              <SingleData
                bold="true"
                style={{
                  marginTop: '20px',
                  width: '100%',
                  wordWrap: 'break-word',
                }}
              >
                {data.description}
              </SingleData>
              <Button
                onClick={toggleContainerDetails}
                style={{ maxWidth: '100px', margin: '10px auto' }}
              >
                Zwiń
                <Arrow up />
              </Button>
            </DetailsGrid>
          </AdditionalDetails>
        )}
        {addNoteOpen && (
          <NotesWrapper>
            <Notes
              note={noteText}
              toggleaddNote={toggleaddNote}
              id={data.advertisementId}
              setNoteText={noteText}
              type="notice"
              setNoteToState={setNote}
            />
          </NotesWrapper>
        )}
      </DetailsWrapper>
      {modAndDupOpen && (
        <Grid container ref={(el) => (modAndDupContainer = el)}>
          {amountDuplicates > 0 && (
            <Grid item xs={12} md={12} lg={12}>
              <Duplicates
                duplicates={duplications}
                component={
                  <Button
                    onClick={toggleContainer}
                    style={{ maxWidth: '100px', margin: '10px 45%' }}
                  >
                    Zwiń
                    <Arrow up />
                  </Button>
                }
              />
            </Grid>
          )}

          {amountModifications > 0 && (
            <Grid item xs={12} md={12} lg={12}>
              <Modyfications modifications={modifications} />
            </Grid>
          )}
          <Button
            onClick={toggleContainer}
            style={{ maxWidth: '100px', margin: '10px auto' }}
          >
            Zwiń
            <Arrow up />
          </Button>
        </Grid>
      )}
    </div>
  );
};

export default SingleAdvertisement;
