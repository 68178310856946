import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import {
  FormTextField,
  FormRadio,
  FormSelectCustomLabel,
} from '../InputFilters';
import { activeNotification } from '../functions/activeNotification';
import Button from '../atom/Button';
import { SectionStyle } from '../FilterSections';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import Tooltip from '@material-ui/core/Tooltip';

const sheduleValueList = [1440, 2880, 5760, 10080, 20160, 40320];
const sheduleLabelList = [
  'Codziennie',
  'Co 2 dni',
  'Co 4 dni',
  'Co tydzień',
  'Co 2 tygodnie',
  'Co miesiąc',
];

const SaveFilterAnalysis = ({
  handleChange,
  saveFilter,
  name,
  alertEmail,
  alertSms,
  pushAlert,
  filterId,
  tossedUp,
  notificationDelay,
  showOptions,
  tooltipTossedUp,
  tooltipNotificationDelay,
  classes,
}) => {
  // const [shedule, setShedule] = useState();
  // const [notifications, setNotifications] = useState();

  const save = (e) => {
    const promise = saveFilter(e);
    if (promise.length > 0) {
      Promise.all(promise)
        .then(() => {
          activeNotification('Udało się!', 'Filtr został dodany', 'success');
        })
        .catch((err) => {});
    }
    e.preventDefault();
  };


  const handleAlertEmail = async (e) => {
    e.preventDefault();
    await handleChange('alertEmail', !alertEmail);
    const promise = saveFilter(e);
    if (promise.length > 0) {
      Promise.all(promise).then(() => {
        if (!alertEmail) {
          activeNotification(
            'Udało się!',
            'Alert został zaktualizowany',
            'success'
          );
        } else {
          activeNotification('Udało się!', 'Alert został usunięty', 'warning');
        }
      });
    }
  };

  return (
    <div>
      <hr
        style={{
          backgroundColor: '#edecf2',
          color: '#edecf2',
          height: 2,
          margin: '0 -1VW 1vw -1VW',
        }}
      />
      <Grid container spacing={0} justify="space-between">
        <Grid item lg={6} md={11} xs={12}>
          {/* <p>
            Włącz powiadomienie (zapisz filtr, aby aktywować funkcję)
          </p> */}
          <Grid
            container
            spacing={1}
            style={{ flexGrow: 1 }}
            alignItems="center"
          >
            {false && (
              <Grid container spacing={1} style={{ flexGrow: 1 }}>
                
                <Grid item xs={6}>
                  <SectionStyle style={{ padding: '10px' }}>
                    <Grid
                      item
                      container
                      direction="row"
                      alignItems="center"
                      className={classes.tooltipGridBox}
                    >
                      <p style={{ fontSize: '1.4rem', margin: 0 }}>
                        Harmonogram powiadomień
                      </p>
                      <Tooltip
                        classes={{ tooltip: classes.customWidth }}
                        enterTouchDelay={0}
                        title={
                          <span
                            style={{
                              fontSize: '1.2rem',
                              lineHeight: '1.2',
                              fontFamily: 'Inter',
                            }}
                          >
                            {tooltipNotificationDelay}
                          </span>
                        }
                        arrow
                        placement="top"
                      >
                        <InfoSharpIcon className={classes.tooltipIcon} />
                      </Tooltip>
                    </Grid>
                    <FormSelectCustomLabel
                      onChange={handleChange}
                      value={notificationDelay}
                      labelList={sheduleLabelList}
                      optionList={sheduleValueList}
                      placeholder='Natychmiast'
                      name="notificationDelay"
                    />
                  </SectionStyle>
                </Grid>
                <Grid item lg={5} md={11} xs={12}>
          <p>
            Włącz powiadomienie{' '}
            {!filterId && (
              <span style={{ fontWeight: 'bold' }}>
                (zapisz filtr, aby aktywować funkcję)
              </span>
            )}
          </p>
          <Grid container spacing={1} style={{ flexGrow: 1 }}>
          
            <Grid item md={11}>
              <Button
                type="button"
                height="big"
                notActive={!alertEmail ? true : false}
                onClick={handleAlertEmail}
                disabled={filterId ? false : true}
                style={{ opacity: `${!filterId ? '0.4' : '1'}` }}
              >
                {alertEmail ? 'Alert email aktywny' : 'Aktywuj alert email'}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ flexGrow: 1 }} >
          
          </Grid>
        </Grid>
              </Grid>
            )}
            <Grid item xs={8} style={{ marginTop: 2.5 }}>
              <FormTextField
                id="name"
                name="name"
                placeholder="Wpisz nazwę filtru analizy"
                onBlur={handleChange}
                ref={name}
                maxLength={64}
              />
            </Grid>
            <Grid item xs={4}>
              <Button onClick={save} type="submit" height="big">
                Zapisz filtr
              </Button>
            </Grid>
            
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
};

export default SaveFilterAnalysis;
