import React, { Component, memo } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import TableOfBailiff from '../components/bailiff/TableOfBailiff';
import HeaderSection from '../components/HeaderSection';
import FilterButton from '../components/FilterButton';

import { API, redirect } from '../API';
import { activeNotification } from '../components/functions/activeNotification';
import { like } from '../components/Reactions/Like';
import Indicator from '../components/atom/Indicator';

import {
  comparePriceAscending,
  comparePriceDescending,
  compareDateAscending,
  compareDateDescending,
  compareBailiff,
  comparePricePerSquareMeterAscending,
  comparePricePerSquareMeterDescending,
} from '../components/Sort';

const Content = memo(
  ({
    bailiffList,
    handleChangeSort,
    handleChangeNotes,
    handleChangeFavorite,
    sortType,
    checkedFavorite,
    checkedNotes,
    setFavourite,
    setNote,
    sortFromOptions,
    sortPlaceholder,
  }) => {
    return (
      <>
        <HeaderSection
          list={bailiffList}
          handleChangeSort={handleChangeSort}
          handleChangeNotes={handleChangeNotes}
          handleChangeFavorite={handleChangeFavorite}
          sortType={sortType}
          checkedFavorite={checkedFavorite}
          checkedNotes={checkedNotes}
          sortOptions={sortFromOptions}
          sortPlaceholder={sortPlaceholder}
        />
        <TableOfBailiff
          list={bailiffList}
          setFavourite={setFavourite}
          setNote={setNote}
        />
      </>
    );
  }
);

class BailiffPage extends Component {
  state = {
    defaultBailiffList: [],
    bailiffList: [],
    sortType: '',
    checkedFavorite: false,
    checkedNotes: false,
    isLoading: false,
    filters: React.createRef(),
    tooltipDataListBaliff: [],
  };

  componentDidMount() {
    axios({
      method: 'get',
      url: `${API}bailiffNotice/directory`,
    })
      .then(resp => {
        const tooltipData = resp.data;

        this.setState({
          tooltipDataListBaliff: tooltipData,
        });
      })
  }

  handleSubmit = (e, city) => {
    this.setState({
      isLoading: true,
      defaultBailiffList: [],
      bailiffList: [],
    });

    axios({
      method: 'get',
      url: `${API}bailiffNotice/findByFilter/${city}/`,
    })
      .then(resp => {
        if (resp.data.length === 0) {
          activeNotification(
            'Nie znaleziono wyników',
            'Zmień wartości wyszukiwania',
            'warning'
          );
          this.setState({
            defaultBailiffList: [],
            bailiffList: [],
            isLoading: false,
          });
        } else {
          const bailiffList = resp.data; //.sort(compareBailiff);
          this.setState({
            defaultBailiffList: bailiffList,
            bailiffList,
            isLoading: false,
          });
        }
      })

      .catch(err => {
        if (err?.response?.status === 401) {
          window.location = redirect;
        } else {
          this.setState({ isLoading: false });
          activeNotification(
            'Coś poszło nie tak!',
            'Spróbuj ponownie',
            'danger'
          );
        }
      });
    e.preventDefault();
  };

  handleChangeSort = sortType => {
    let { bailiffList } = this.state;

    switch (sortType) {
      case 'Po cenie rosnąco':
        bailiffList.sort(comparePriceAscending);
        break;

      case 'Po cenie malejąco':
        bailiffList.sort(comparePriceDescending);
        break;

      case 'Po dacie rosnąco':
        bailiffList.sort(compareDateAscending);
        break;

      case 'Po dacie malejąco':
        bailiffList.sort(compareDateDescending);
        break;

      case 'Po cenie za m2 rosnąco':
        bailiffList.sort(comparePricePerSquareMeterAscending);
        break;

      case 'Po cenie za m2 malejąco':
        bailiffList.sort(comparePricePerSquareMeterDescending);
        break;

      default:
        break;
    }
    this.setState({ bailiffList });
  };

  handleChangeFavorite = event => {
    let bailiffList = this.state.defaultBailiffList;

    if (event.target.checked) {
      if (this.state.checkedNotes)
        bailiffList = bailiffList.filter(
          el => el.reactions[0]?.likeStatus && el.reactions[0]?.note
        );
      else bailiffList = bailiffList.filter(el => el.reactions[0]?.likeStatus);
    } else {
      if (this.state.checkedNotes)
        bailiffList = this.state.defaultBailiffList.filter(
          el => el.reactions[0]?.note
        );
      else bailiffList = this.state.defaultBailiffList;
    }

    this.setState({
      checkedFavorite: event.target.checked,
      bailiffList,
    });
  };

  handleChangeNotes = event => {
    let bailiffList = this.state.defaultBailiffList;
    if (event.target.checked) {
      if (this.state.checkedFavorite)
        bailiffList = bailiffList.filter(
          el => el.reactions[0]?.likeStatus && el.reactions[0]?.note
        );
      else bailiffList = bailiffList.filter(el => el.reactions[0]?.note);
    } else {
      if (this.state.checkedFavorite)
        bailiffList = this.state.defaultBailiffList.filter(
          el => el.reactions[0]?.likeStatus
        );
      else bailiffList = this.state.defaultBailiffList;
    }

    this.setState({
      checkedNotes: event.target.checked,
      bailiffList,
    });
  };

  setFavourite = id => {
     this.setState({ isLoading: true });

    const list = this.state.bailiffList;

    const likeAdded = () => this.setState({ isLoading: false });

    const newList = list.map(el => {
      const reactions = el.reactions[0];
      const newStatus = reactions ? !reactions.likeStatus : true
      const newReactions = { ...reactions, likeStatus: newStatus };

      if (el.bailiffNoticeId === id) {
        like(id, 'bailiffNotice', likeAdded, newStatus);
        if (el.reactions[0]) {
          el.reactions[0].likeStatus = newStatus;
        } else {
          el.reactions[0] = newReactions;
        }
      }
      return el;
    });
    this.setState({ bailiffList: newList });
  };

  setNote = (id, note) => {
    let list = this.state.bailiffList;

    list = list.map(el => {
      if (el.bailiffNoticeId === id) {
        if (el.reactions[0]) {
          el.reactions[0].note = note;
        } else {
          el.reactions.push({ note });
        }
      }
      return el;
    });
    this.setState({ bailiffList: list });
  };

  render() {
    const sortFromOptions =
      [
        'Po dacie malejąco',
        'Po dacie rosnąco',
        'Po cenie rosnąco',
        'Po cenie malejąco',
        'Po cenie za m2 rosnąco',
        'Po cenie za m2 malejąco',
      ];
    const sortPlaceholder = 'Po dacie malejąco';
    const {
      bailiffList,
      sortType,
      checkedFavorite,
      checkedNotes,
      isLoading,
      tooltipDataListBaliff,
    } = this.state;
    return (
      <div>
        <FilterButton handleSubmit={this.handleSubmit} type='bailiff' tooltipDataListBaliff={tooltipDataListBaliff} />
        <hr
          style={{ backgroundColor: '#edecf2', color: '#edecf2', height: 2 }}
        />
        {isLoading && <Indicator />}
        {(bailiffList.length > 0 || checkedFavorite || checkedNotes) && (
          <Content
            bailiffList={bailiffList}
            handleChangeSort={this.handleChangeSort}
            handleChangeNotes={this.handleChangeNotes}
            handleChangeFavorite={this.handleChangeFavorite}
            sortType={sortType}
            checkedFavorite={checkedFavorite}
            checkedNotes={checkedNotes}
            setFavourite={this.setFavourite}
            setNote={this.setNote}
            sortFromOptions={sortFromOptions}
            sortPlaceholder={sortPlaceholder}
          />
        )}
      </div>
    );
  }
}

export default BailiffPage;
